import React, { SVGProps } from "react";
export default function LockIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.6667 8.66675V7.33342C17.6667 3.65741 14.676 0.666748 11 0.666748C7.32399 0.666748 4.33333 3.65741 4.33333 7.33342V11.3334H2.99999C1.52933 11.3334 0.333328 12.5294 0.333328 14.0001V24.6667C0.333328 26.1374 1.52933 27.3334 2.99999 27.3334H19C20.4707 27.3334 21.6667 26.1374 21.6667 24.6667V14.0001C21.6667 12.5294 20.4707 11.3334 19 11.3334H6.99999V7.33342C6.99999 5.12808 8.79466 3.33341 11 3.33341C13.2053 3.33341 15 5.12808 15 7.33342V8.66675H17.6667ZM19 14.0001L19.0027 24.6667H2.99999V14.0001H19Z"
        fill="currentColor"
      />
    </svg>
  );
}
