import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes, useEffect, useRef, useState } from "react";

import { useSelector } from "../hooks/storeHooks";
import { soundCorrect } from "../pages/Games/assets/soundCorrect";
import { soundWrong } from "../pages/Games/assets/soundWrong";

import CorrectAnswerIcon from "./icons/CorrectAnswerIcon";
import WrongAnswerIcon from "./icons/WrongAnswerIcon";

type GameImageContainerProps = {
  audioId: number;
  image: string;
  rounded?: boolean;
  playing: boolean;
  imageClassName?: string;
  pageOrder?: number;
  counter?: number;
  enabledOnClick?: boolean;
  setCounter?: (counter: number) => void;
  onClick?: () => void;
  answer?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function GameImageContainer({
  image,
  className,
  onClick,
  playing,
  imageClassName,
  pageOrder,
  counter,
  enabledOnClick = true,
  setCounter,
  rounded = true,
  answer = "idle",
  ...props
}: GameImageContainerProps) {
  const { bgColor, framed, frameColor, audioOn } = useSelector((state) => state.settings.settings);

  const wrongAudioRef = useRef(new Audio(soundWrong));
  const correctAudioRef = useRef(new Audio(soundCorrect));

  const [answerStatus, setAnswerStatus] = useState(answer);
  const [onClickToggle, setOnclickToggle] = useState(true);

  useEffect(() => {
    if (answerStatus == "correct") {
      setOnclickToggle(false);
    }
  }, [answerStatus]);

  function handleOnClick(answer: string) {
    if (enabledOnClick) {
      if (pageOrder === counter) {
        onClick?.();
      }
      if (counter && setCounter && onClickToggle) {
        if (answer === "correct") {
          if (audioOn) correctAudioRef.current.play();
          setCounter(counter + 1);
          setOnclickToggle(false);
          setAnswerStatus(answer);
        } else {
          if (audioOn) wrongAudioRef.current.play();
          setAnswerStatus(answer);
          setTimeout(() => setAnswerStatus("idle"), 2 * 1000);
        }
      }
    }
  }

  const imageContainerClassName = cx(className, "overflow-hidden relative", {
    "cursor-pointer": onClick,
    [`border-4 bg-${bgColor}`]: framed,
    [`border-${frameColor} bg-${bgColor}`]: framed && !playing,
    [`border-${bgColor} bg-${bgColor}`]: !framed && !playing,
    [`hover:border-secondary`]: !playing,
    rounded: rounded,
  });

  const overlayClassName = cx(
    "absolute flex justify-center items-center t-0 bg-opacity-50 flex text-align imageContainerSmall rounded cursor-pointer",
    {
      "bg-secondary": answerStatus == "correct",
      "bg-error": answerStatus == "wrong",
      "": answerStatus == "idle",
    }
  );

  return (
    <div
      className={imageContainerClassName}
      onClick={() => (pageOrder === counter ? handleOnClick("correct") : handleOnClick("wrong"))}
      {...props}
    >
      <div className={overlayClassName}>
        {answerStatus == "correct" && <CorrectAnswerIcon width={64} color={"white"} />}
        {answerStatus == "wrong" && <WrongAnswerIcon width={64} color={"white"} />}
      </div>
      <img className={imageClassName} src={`data:image;base64, ${image}`} alt="container image" />
    </div>
  );
}
