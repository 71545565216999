import React, { SVGProps } from "react";
export default function CameraIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.5 15.875C18.9092 15.875 15.875 18.9092 15.875 22.5C15.875 26.0907 18.9092 29.125 22.5 29.125C26.0907 29.125 29.125 26.0907 29.125 22.5C29.125 18.9092 26.0907 15.875 22.5 15.875Z"
        fill="currentColor"
      />
      <path
        d="M40.1666 7.04175H34.4559L28.4779 1.06379C28.2732 0.858323 28.0299 0.695375 27.7619 0.584328C27.4939 0.473282 27.2067 0.41633 26.9166 0.41675H18.0833C17.7932 0.41633 17.506 0.473282 17.238 0.584328C16.9701 0.695375 16.7267 0.858323 16.522 1.06379L10.544 7.04175H4.83329C2.3975 7.04175 0.416626 9.02262 0.416626 11.4584V35.7501C0.416626 38.1859 2.3975 40.1668 4.83329 40.1668H40.1666C42.6024 40.1668 44.5833 38.1859 44.5833 35.7501V11.4584C44.5833 9.02262 42.6024 7.04175 40.1666 7.04175ZM22.5 33.5417C16.5154 33.5417 11.4583 28.4847 11.4583 22.5001C11.4583 16.5155 16.5154 11.4584 22.5 11.4584C28.4845 11.4584 33.5416 16.5155 33.5416 22.5001C33.5416 28.4847 28.4845 33.5417 22.5 33.5417Z"
        fill="currentColor"
      />
    </svg>
  );
}
