import React from "react";

import Modal, { ModalFooter, ModalHeader, UseModal } from "../../components/Modal";
import crozLogo from "../../components/assets/croz-logo.png";
import ictLogo from "../../components/assets/ict-logo.png";
import InfoIcon from "../../components/icons/InfoIcon";

export default function SettingsModal({ ...props }: UseModal) {
  const { isOpen, onOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose} className="font-semibold w-3/4 md:w-1/2 mx-auto">
      <ModalHeader
        onClose={onClose}
        variant="primary"
        title="Informacije"
        titleIcon={<InfoIcon width={20} height={20} />}
      />
      <div className="flex flex-col">
        <div className="flex justify-center w-full mt-4 ">ICT-AAC E-galerija v.1.0</div>
        <div className="flex justify-center w-full text-center p-6 pt-4 text-xs">
          Aplikacija ICT-AAC e-Galerija omogućuje slaganje priča pomoću niza sličica koje mogu biti slike iz galerije
          uređaja ili simboli iz triju nekomercijalnih galerija (ARAASAC, Mulberry i Sclera).
        </div>
        <div className="flex justify-center w-full text-center mb-2 text-sm">Aplikacija koristi galerije simbola:</div>
        <div className="flex justify-center w-full text-center text-xs">
          <pre>-ARAASAC: </pre>
          <a href="https://arasaac.org/"> https://arasaac.org/</a>
        </div>
        <div className="flex justify-center w-full text-center text-xs">
          <pre>-Mulberry: </pre> <a href="https://mulberrysymbols.org/"> https://mulberrysymbols.org/ </a>
        </div>
        <div className="flex justify-center w-full text-center text-xs">
          <pre>-Sclera: </pre> <a href="https://mulberrysymbols.org/"> https://www.sclera.be/en/vzw/home </a>
        </div>

        <div className="flex justify-center w-full text-center text-xs p-2 pt-4">
          Aplikaciju je razvio CROZ temeljem postojeće mobilne aplikacije.
        </div>

        <div className="flex justify-center w-full text-center p-2 text-xs">
          Razvojni tim: Dorian Smoljan, Tomislav Grbeša, Franko Papić,
          <br /> Michel Haralović, Anamarija Zegnal
        </div>
        <div className="flex flex-row">
          <div className="flex items-center justify-center w-1/2">
            <img className="w-1/2" src={crozLogo} alt="" />
          </div>
          <div className="flex items-center justify-center w-1/2">
            <img className="w-1/2" src={ictLogo} alt="" />
          </div>
        </div>
      </div>
      <ModalFooter>ICT-AAC © 2021</ModalFooter>
    </Modal>
  );
}
