import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";

type PageContProps = {
  children: React.ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function PageCont({ children, className, ...props }: PageContProps) {
  const pageContainerClassName = cx(className, "flex justify-center flex-col items-center flex-grow bg-background");
  return (
    <div className={pageContainerClassName} {...props}>
      {children}
    </div>
  );
}
