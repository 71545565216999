import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import Button from "../../../components/Button";
import LockIcon from "../../../components/icons/LockIcon";
import LockedIcon from "../../../components/icons/LockedIcon";

type LockButtonProps = {
  locked?: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export default function LockButton({ locked = false, ...props }: LockButtonProps) {
  return (
    <Button
      {...props}
      variant="primary"
      title={locked ? "Otključaj" : "Zaključaj"}
      leadingIcon={locked ? <LockedIcon /> : <LockIcon />}
      className="font-semibold h-10 mr-2"
    >
      <div className="invisible w-0 h-0 md:visible md:w-16 md:h-7 md:mt-1">{locked ? "Otključaj" : "Zaključaj"}</div>
    </Button>
  );
}
