import React, { SVGProps } from "react";
export default function ImageSingleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 46 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.8728 15.75C14.7368 15.75 16.2478 14.239 16.2478 12.375C16.2478 10.511 14.7368 9 12.8728 9C11.0088 9 9.4978 10.511 9.4978 12.375C9.4978 14.239 11.0088 15.75 12.8728 15.75Z"
        fill="currentColor"
      />
      <path d="M19.6228 22.5L16.2478 18L9.4978 27H36.4978L26.3728 13.5L19.6228 22.5Z" fill="currentColor" />
      <path
        d="M40.9978 0H4.9978C2.51605 0 0.497803 2.01825 0.497803 4.5V31.5C0.497803 33.9818 2.51605 36 4.9978 36H40.9978C43.4796 36 45.4978 33.9818 45.4978 31.5V4.5C45.4978 2.01825 43.4796 0 40.9978 0ZM4.9978 31.5V4.5H40.9978L41.0023 31.5H4.9978Z"
        fill="currentColor"
      />
    </svg>
  );
}
