import React from "react";
import { useHistory } from "react-router-dom";

import Button from "../../components/Button";
import { Header } from "../../components/Header";
import { useModal } from "../../components/Modal";
import PageCont from "../../components/PageCont";
import PageContHeader from "../../components/PageContHeader";
import ReturnButton from "../../components/componentsImpl/ReturnButton";
import ArrowRepeatIcon from "../../components/icons/ArrowRepeatIcon";
import GamesIcon from "../../components/icons/GamesIcon";
import { useSelector } from "../../hooks/storeHooks";
import SettingsButtonModal from "../Settings/components/SettingsButtonModal";
import TutorialButtonModal from "../Stories/components/TutorialButtonModal";

export default function EndGame() {
  const history = useHistory();
  const settingsModal = useModal();
  const tutorialModal = useModal();
  const storyTitle = useSelector((state) => state.singleStory.story.title);

  const onClickRedirectGames = () => {
    history.push("/games");
  };

  const onClickRepeatGame = () => {
    history.goBack();
  };

  return (
    <PageCont className="h-screen">
      <Header />

      <div className="pr-8 w-full h-full">
        <PageContHeader
          title={storyTitle}
          titleClassName="mr-28"
          leftButtons={<ReturnButton variant="secondary" text="Igre" onClick={onClickRedirectGames} />}
          rightButtons={[
            <TutorialButtonModal key={1} {...tutorialModal} />,
            <SettingsButtonModal key={2} {...settingsModal} />,
          ]}
          className="w-full items-center mt-8  pl-28"
        />

        <div className="flex flex-col justify-center items-center mt-14 ml-4">
          <div className="font-bold text-4xl  text-center">Bravo!</div>
          <div className="flex flex-row justify-center gap-x-10 mt-4">
            <div className="w-72 h-72 bg-white rounded flex flex-col justify-center items-center gap-y-10 px-6">
              <ArrowRepeatIcon className="text-primary mt-6" width={100} height={100} />
              <Button variant="primary" className="w-full mt-2 font-semibold" onClick={onClickRepeatGame}>
                Ponovi igru
              </Button>
            </div>
            <div className="w-72 h-72 bg-white rounded flex flex-col justify-center items-center gap-y-10 px-6">
              <GamesIcon className="text-secondary mt-6" width={100} height={100} />
              <Button variant="secondary" className="w-full mt-2 font-semibold" onClick={onClickRedirectGames}>
                Natrag na igre
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PageCont>
  );
}
