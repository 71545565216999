import cx from "classnames";
import React from "react";

type SoundButtonContainerProps = {
  subtitle?: string;
  subtitleAside?: boolean;
  button: React.ReactNode;
};

export default function LabeledButton({ subtitle, button, subtitleAside = false }: SoundButtonContainerProps) {
  const labeledButtonClassName = cx("flex justify-start font-normal text-sm items-center", {
    "flex-col": !subtitleAside,
    "flex-row": subtitleAside,
  });

  const subtitleClassName = cx({
    "ml-3": subtitleAside,
  });

  return (
    <div className={labeledButtonClassName}>
      {button}
      <div className={subtitleClassName}>{subtitle}</div>
    </div>
  );
}
