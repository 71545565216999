import React, { SVGProps } from "react";

export default function TickIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M34.9433 1.42242C35.5935 2.03716 35.973 2.88498 35.9984 3.77947C36.0237 4.67396 35.6927 5.54188 35.0783 6.19242L15.9533 26.4424C15.6433 26.77 15.2708 27.0322 14.8578 27.2134C14.4449 27.3946 13.9997 27.4912 13.5488 27.4975C13.0978 27.5038 12.6502 27.4197 12.2323 27.25C11.8144 27.0804 11.4348 26.8287 11.1158 26.5099L0.990774 16.3849C0.394614 15.7451 0.0700598 14.8989 0.0854867 14.0246C0.100914 13.1502 0.455118 12.316 1.07348 11.6976C1.69183 11.0793 2.52607 10.7251 3.40042 10.7096C4.27478 10.6942 5.12099 11.0188 5.76077 11.6149L13.4333 19.2829L30.1733 1.55742C30.788 0.907155 31.6358 0.527645 32.5303 0.502329C33.4248 0.477013 34.2927 0.807965 34.9433 1.42242Z"
        fill="white"
      />
    </svg>
  );
}
