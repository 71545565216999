import React, { SVGProps } from "react";
export default function GroupArrowsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30.9223 10.2972C30.4925 10.7268 29.9098 10.9682 29.3021 10.9682C28.6944 10.9682 28.1116 10.7268 27.6819 10.2972L25.2917 7.907V14.4062C25.2917 15.014 25.0502 15.5968 24.6205 16.0266C24.1907 16.4564 23.6078 16.6978 23 16.6978C22.3922 16.6978 21.8093 16.4564 21.3796 16.0266C20.9498 15.5968 20.7083 15.014 20.7083 14.4062V7.907L18.3181 10.2972C17.8859 10.7147 17.307 10.9456 16.7062 10.9404C16.1053 10.9352 15.5305 10.6942 15.1056 10.2693C14.6807 9.8444 14.4397 9.26962 14.4345 8.66875C14.4293 8.06789 14.6603 7.48901 15.0777 7.05679L21.3798 0.754709C21.8096 0.325088 22.3923 0.0837402 23 0.0837402C23.6077 0.0837402 24.1905 0.325088 24.6202 0.754709L30.9223 7.05679C31.3519 7.48654 31.5933 8.06933 31.5933 8.677C31.5933 9.28467 31.3519 9.86746 30.9223 10.2972Z"
        fill="white"
      />
      <path
        d="M10.2973 27.6818C10.5162 27.8932 10.6908 28.1461 10.8109 28.4257C10.931 28.7052 10.9942 29.006 10.9968 29.3102C10.9995 29.6145 10.9415 29.9163 10.8263 30.1979C10.711 30.4796 10.5409 30.7354 10.3257 30.9506C10.1105 31.1658 9.85467 31.3359 9.57303 31.4512C9.29139 31.5664 8.98963 31.6244 8.68534 31.6217C8.38105 31.6191 8.08034 31.5559 7.80075 31.4358C7.52116 31.3157 7.26828 31.1411 7.05688 30.9222L0.754801 24.6201C0.325179 24.1904 0.0838318 23.6076 0.0838318 22.9999C0.0838318 22.3922 0.325179 21.8095 0.754801 21.3797L7.05688 15.0776C7.4891 14.6602 8.06798 14.4292 8.66885 14.4344C9.26971 14.4396 9.84449 14.6806 10.2694 15.1055C10.6943 15.5304 10.9353 16.1052 10.9405 16.7061C10.9457 17.3069 10.7147 17.8858 10.2973 18.318L7.90709 20.7082H14.4063C15.014 20.7082 15.5969 20.9497 16.0267 21.3795C16.4565 21.8092 16.6979 22.3921 16.6979 22.9999C16.6979 23.6077 16.4565 24.1906 16.0267 24.6204C15.5969 25.0501 15.014 25.2916 14.4063 25.2916H7.90709L10.2973 27.6818Z"
        fill="white"
      />
      <path
        d="M38.9431 30.9222C38.5109 31.3397 37.932 31.5706 37.3312 31.5654C36.7303 31.5602 36.1555 31.3192 35.7306 30.8943C35.3057 30.4694 35.0647 29.8946 35.0595 29.2938C35.0543 28.6929 35.2853 28.114 35.7027 27.6818L38.0929 25.2916H31.5938C30.986 25.2916 30.4031 25.0501 29.9733 24.6204C29.5435 24.1906 29.3021 23.6077 29.3021 22.9999C29.3021 22.3921 29.5435 21.8092 29.9733 21.3795C30.4031 20.9497 30.986 20.7082 31.5938 20.7082H38.0929L35.7027 18.318C35.2853 17.8858 35.0543 17.3069 35.0595 16.7061C35.0647 16.1052 35.3057 15.5304 35.7306 15.1055C36.1555 14.6806 36.7303 14.4396 37.3312 14.4344C37.932 14.4292 38.5109 14.6602 38.9431 15.0776L45.2452 21.3797C45.6748 21.8095 45.9162 22.3922 45.9162 22.9999C45.9162 23.6076 45.6748 24.1904 45.2452 24.6201L38.9431 30.9222Z"
        fill="white"
      />
      <path
        d="M20.7084 38.0928V31.5937C20.7084 30.9859 20.9498 30.403 21.3796 29.9732C21.8093 29.5434 22.3922 29.302 23 29.302C23.6078 29.302 24.1907 29.5434 24.6205 29.9732C25.0502 30.403 25.2917 30.9859 25.2917 31.5937V38.0928L27.6819 35.7026C28.1141 35.2852 28.693 35.0542 29.2939 35.0594C29.8947 35.0646 30.4695 35.3056 30.8944 35.7305C31.3193 36.1554 31.5603 36.7302 31.5655 37.3311C31.5707 37.932 31.3398 38.5108 30.9223 38.943L24.6202 45.2451C24.1905 45.6748 23.6077 45.9161 23 45.9161C22.3924 45.9161 21.8096 45.6748 21.3798 45.2451L15.0777 38.943C14.8589 38.7316 14.6843 38.4788 14.5642 38.1992C14.4441 37.9196 14.3808 37.6189 14.3782 37.3146C14.3756 37.0103 14.4335 36.7085 14.5488 36.4269C14.664 36.1453 14.8342 35.8894 15.0493 35.6742C15.2645 35.4591 15.5204 35.2889 15.802 35.1737C16.0836 35.0584 16.3854 35.0004 16.6897 35.0031C16.994 35.0057 17.2947 35.069 17.5743 35.1891C17.8539 35.3092 18.1067 35.4837 18.3181 35.7026L20.7084 38.0928Z"
        fill="white"
      />
    </svg>
  );
}
