import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as emailSenderApi from "../../../api/emailSender";
import { SendEmailRequest } from "../../../api/types";
import { ErrorResponse } from "../../../store/errorType";

const sendEmail = createAsyncThunk("email-sender", async (sendEmailRequest: SendEmailRequest, { rejectWithValue }) => {
  try {
    return await emailSenderApi.sendEmail(sendEmailRequest);
  } catch (err) {
    return rejectWithValue(err);
  }
});

type EmailSenderState = {
  status: "idle" | "success" | "waiting" | "error";
  error?: ErrorResponse;
};

export const emailSenderInitialState: EmailSenderState = {
  status: "idle",
  error: { code: 0, message: "", validations: [] },
};

export const emailSenderSlice = createSlice({
  name: "emailSender",
  initialState: emailSenderInitialState,
  reducers: {
    resetEmailSenderState: () => emailSenderInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(sendEmail.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(sendEmail.fulfilled, (state, action) => {
      if (action.payload.status == 200) {
        state.status = "success";
      } else {
        state.status = "error";
        state.error = { code: action.payload.status, message: "" } as ErrorResponse;
      }
    });

    builder.addCase(sendEmail.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as ErrorResponse;
    });
  },
});

export const emailSender = emailSenderSlice.reducer;
export const sendEmailAction = {
  sendEmail,
  ...emailSenderSlice.actions,
};
