import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import Button from "../../../components/Button";
import IconButton from "../../../components/componentsImpl/IconButton";
import GearSettingsIcon from "../../../components/icons/GearSettingsIcon";
import HelpIcon from "../../../components/icons/HelpIcon";
import PencilIcon from "../../../components/icons/PencilIcon";
import SaveIcon from "../../../components/icons/SaveIcon";

type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export function SettingsButton({ ...props }: ButtonProps) {
  return <IconButton {...props} icon={<GearSettingsIcon width={30} height={30} />} variant="primary" />;
}

export function EditButton({ ...props }: ButtonProps) {
  return (
    <Button variant="primary" className="h-10 px-2.5 mr-2 md:px-6 md:py-2 md:w-40 font-semibold" {...props}>
      <PencilIcon width={30} height={30} className="md:mr-3 w-7 h-7" />
      <div className="invisible w-0 h-0 md:visible md:ml-5 md:mr-6 md:w-7 md:h-7 md:mt-1">Uredi</div>
    </Button>
  );
}

export function SaveButton({ ...props }: ButtonProps) {
  return (
    <Button
      variant="primary"
      className="h-10 px-2.5 mr-2 md:ml-10 lg:px-5 lg:-ml-20 lg:py-2 lg:w-40 font-semibold"
      {...props}
    >
      <PencilIcon width={25} height={25} className="lg:mx-2 w-6 h-6" />
      <div className="invisible w-0 h-0 lg:visible lg:w-40 lg:h-7 lg:mt-1">Završi uređivanje</div>
    </Button>
  );
}

export function InfoButton({ ...props }: ButtonProps) {
  return <IconButton {...props} icon={<HelpIcon width={30} height={30} />} variant="primary" />;
}
