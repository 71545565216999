import React, { SVGProps } from "react";
export default function WrongAnswerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 87 87" color="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M43.5 0.583252C35.0119 0.583252 26.7144 3.10027 19.6568 7.81601C12.5992 12.5318 7.09843 19.2344 3.85017 27.0764C0.601908 34.9184 -0.247985 43.5475 1.40796 51.8725C3.06391 60.1976 7.15133 67.8446 13.1533 73.8466C19.1553 79.8486 26.8024 83.936 35.1274 85.592C43.4524 87.2479 52.0815 86.398 59.9235 83.1497C67.7655 79.9015 74.4682 74.4007 79.1839 67.3431C83.8996 60.2855 86.4167 51.988 86.4167 43.4999C86.4167 37.864 85.3066 32.2833 83.1498 27.0764C80.9931 21.8695 77.8318 17.1384 73.8467 13.1533C69.8615 9.16807 65.1304 6.00685 59.9235 3.85009C54.7166 1.69332 49.1359 0.583252 43.5 0.583252V0.583252ZM55.1304 49.0362C55.5327 49.4351 55.8519 49.9098 56.0698 50.4328C56.2877 50.9558 56.3999 51.5167 56.3999 52.0833C56.3999 52.6498 56.2877 53.2107 56.0698 53.7337C55.8519 54.2567 55.5327 54.7314 55.1304 55.1303C54.7315 55.5326 54.2568 55.8519 53.7338 56.0697C53.2108 56.2876 52.6499 56.3998 52.0833 56.3998C51.5168 56.3998 50.9558 56.2876 50.4329 56.0697C49.9099 55.8519 49.4352 55.5326 49.0363 55.1303L43.5 49.5512L37.9638 55.1303C37.5648 55.5326 37.0901 55.8519 36.5671 56.0697C36.0442 56.2876 35.4832 56.3998 34.9167 56.3998C34.3501 56.3998 33.7892 56.2876 33.2662 56.0697C32.7432 55.8519 32.2685 55.5326 31.8696 55.1303C31.4673 54.7314 31.1481 54.2567 30.9302 53.7337C30.7123 53.2107 30.6001 52.6498 30.6001 52.0833C30.6001 51.5167 30.7123 50.9558 30.9302 50.4328C31.1481 49.9098 31.4673 49.4351 31.8696 49.0362L37.4488 43.4999L31.8696 37.9637C31.0614 37.1555 30.6074 36.0595 30.6074 34.9166C30.6074 33.7737 31.0614 32.6776 31.8696 31.8695C32.6777 31.0614 33.7738 30.6074 34.9167 30.6074C36.0595 30.6074 37.1556 31.0614 37.9638 31.8695L43.5 37.4487L49.0363 31.8695C49.8444 31.0614 50.9405 30.6074 52.0833 30.6074C53.2262 30.6074 54.3223 31.0614 55.1304 31.8695C55.9386 32.6776 56.3926 33.7737 56.3926 34.9166C56.3926 36.0595 55.9386 37.1555 55.1304 37.9637L49.5513 43.4999L55.1304 49.0362Z"
        fill="currentColor"
      />
    </svg>
  );
}
