import React, { SVGProps } from "react";
export default function TrashIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.66663 8.16667H28.3333M26.6666 8.16667L25.2216 28.4033C25.1618 29.2443 24.7854 30.0314 24.1685 30.606C23.5515 31.1806 22.7397 31.5 21.8966 31.5H8.10329C7.26019 31.5 6.4484 31.1806 5.83143 30.606C5.21446 30.0314 4.83815 29.2443 4.77829 28.4033L3.33329 8.16667H26.6666ZM11.6666 14.8333V24.8333V14.8333ZM18.3333 14.8333V24.8333V14.8333ZM20 8.16667V3.16667C20 2.72464 19.8244 2.30072 19.5118 1.98816C19.1992 1.67559 18.7753 1.5 18.3333 1.5H11.6666C11.2246 1.5 10.8007 1.67559 10.4881 1.98816C10.1756 2.30072 9.99996 2.72464 9.99996 3.16667V8.16667H20Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
