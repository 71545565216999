import React, { SVGProps } from "react";
export default function BurgerMenuIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.83337 21.75H29.1667M1.83337 1.25H29.1667H1.83337ZM1.83337 11.5H29.1667H1.83337Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
