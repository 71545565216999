import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useSelector } from "../../hooks/storeHooks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { resetSettings, updateSettingsAnonymousUser } from "../../pages/Settings/store/settingsSlice";
import { addLocalStory } from "../../pages/Stories/store/storiesSlice";
import { makeToast } from "../../util/makeToast";
import { downloadContentJson, ExportImportType } from "../DownloadContentFunction";
import FileUploadComponent from "../FileUploadComponent";
import Sidebar from "../Sidebar";
import BookIcon from "../icons/BookIcon";
import BurgerMenuIcon from "../icons/BurgerMenuIcon";
import CloudUploadIcon from "../icons/CloudUploadIcon";
import DownloadIcon from "../icons/DownloadIcon";
import GamesIcon from "../icons/GamesIcon";

export default function AppSidebar({
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const SCREEN_WIDTH_MD = 768;

  const dispatch = useDispatch();
  const history = useHistory();
  const { width } = useWindowDimensions();

  const [sidebarVisible, setSidebarVisible] = useState<boolean>(width >= SCREEN_WIDTH_MD);
  const [burgerClicked, setBurgerClicked] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>("");

  const settings = useSelector((state) => state.settings.settings);
  const localStories = useSelector((state) => state.stories.localStories);

  const exportContent = () => {
    downloadContentJson({ settings, localStories, setConfirmMessage });
  };

  const unUploadFunction = (contentString: string) => {
    try {
      const uploadObj = JSON.parse(contentString) as ExportImportType;
      dispatch(updateSettingsAnonymousUser(uploadObj.settings));

      const idArray = localStories.map((content) => content.id);

      uploadObj.localStories
        .filter((value) => !idArray.includes(value.id))
        .forEach((value) => {
          dispatch(addLocalStory(value));
        });

      makeToast("Učitavanje datoteke uspješno.");
    } catch (error) {
      makeToast("Učitavanje datoteke neuspješno. Provjerite jeste li odabrali ispravnu datoteku.");
      dispatch(resetSettings());
    }
  };

  const isMdWide = width >= SCREEN_WIDTH_MD;

  useEffect(() => {
    if (!burgerClicked && !isMdWide) {
      setSidebarVisible(false);
    } else if (isMdWide) {
      setSidebarVisible(true);
      setBurgerClicked(false);
    }
  }, [width, burgerClicked, isMdWide]);

  const showSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    setBurgerClicked(!burgerClicked);
  };

  const onClickToStories = () => {
    history.push("/stories");
  };

  const onClickToGames = () => {
    history.push("/games");
  };

  const appSidebarClassName = cx(className, "w-3/12 md:w-52 lg:w-64 z-10");

  const shadowBackgroundClassName = cx({
    "absolute h-full w-full z-10 bg-black bg-opacity-60": sidebarVisible && !isMdWide,
  });

  return (
    <div className={appSidebarClassName} {...props}>
      <div className={shadowBackgroundClassName} onClick={isMdWide ? undefined : showSidebar} />
      {sidebarVisible ? (
        <div className="md:h-full">
          <Sidebar className="w-2/5 sm:w-1/3 md:w-full z-20 md:z-0 bg-primary pt-1 lg:pt-16 absolute md:relative h-full h-full">
            <div className="sticky md:static top-5">
              <Sidebar.Item
                icon={<BookIcon width={30} height={30} />}
                link="/stories"
                text="Priče"
                onClick={onClickToStories}
              />
              <Sidebar.Item
                icon={<GamesIcon width={30} height={30} />}
                link="/games"
                text="Igre"
                onClick={onClickToGames}
              />
              <FileUploadComponent
                setContent={(content) => unUploadFunction(content)}
                readAs="text"
                acceptContentType="application/json"
                maxFileSize={20000000}
                setConfirmMessage={(string) => setConfirmMessage(string)}
              >
                <Sidebar.Item
                  icon={<CloudUploadIcon width={30} height={30} />}
                  link="fileUpload"
                  text="Uvezi sadržaj"
                />
              </FileUploadComponent>
              <div title="Ne zaboravite izvesti kreirane priče kako biste ih sačuvali!">
                <Sidebar.Item
                  icon={<DownloadIcon width={30} height={30} />}
                  text="Izvezi sadržaj"
                  link="fileDownload"
                  onClick={() => exportContent()}
                />
              </div>
              <div className="flex justify-start m-5 text-white">{confirmMessage}</div>
            </div>
          </Sidebar>
        </div>
      ) : (
        <div>
          <BurgerMenuIcon
            className="mt-3 ml-3 cursor-pointer absolute z-20"
            width={40}
            height={40}
            onClick={showSidebar}
          />
        </div>
      )}
    </div>
  );
}
