import React from "react";

import colorWheel from "../../../components/assets/colorWheel.png";

import ColorButton from "./ColorButton";

type ColorPickerProps = {
  setFieldValue: (name: string, value: string) => void;
  value: string;
};

export default function ColorPicker({ setFieldValue, value }: ColorPickerProps) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <button
        className="bg-pink-500 text-black active:bg-pink-600 font-bold uppercase text-sm rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <div className="bg-primary rounded w-6 h-6 mx-1 my-1 cursor-pointer">
          <img src={colorWheel} />
        </div>
      </button>
      {showModal ? (
        <>
          <div className="origin-top-left absolute right-0 mr-5 mt-11 p-2 rounded-large z-40 bg-white shadow-colorPicker">
            <div className="relative w-auto">
              <div className="border-0 rounded-lg relative flex flex-col w-full outline-none focus:outline-none">
                <div className="flex flex-row">
                  <ColorButton
                    name={value}
                    value="pickerDarkRed"
                    onClick={() => setFieldValue(value, "pickerDarkRed")}
                  />
                  <ColorButton
                    name={value}
                    value="pickerLightRed"
                    onClick={() => setFieldValue(value, "pickerLightRed")}
                  />
                  <ColorButton name={value} value="pickerOrange" onClick={() => setFieldValue(value, "pickerOrange")} />
                  <ColorButton
                    name={value}
                    value="pickerLightOrange"
                    onClick={() => setFieldValue(value, "pickerLightOrange")}
                  />
                </div>
                <div className="flex flex-row">
                  <ColorButton
                    name={value}
                    value="pickerLightYellow"
                    onClick={() => setFieldValue(value, "pickerLightYellow")}
                  />
                  <ColorButton
                    name={value}
                    value="pickerBrightYellow"
                    onClick={() => setFieldValue(value, "pickerBrightYellow")}
                  />
                  <ColorButton name={value} value="pickerPink" onClick={() => setFieldValue(value, "pickerPink")} />
                  <ColorButton
                    name={value}
                    value="pickerLightPink"
                    onClick={() => setFieldValue(value, "pickerLightPink")}
                  />
                </div>
                <div className="flex flex-row">
                  <ColorButton
                    name={value}
                    value="pickerDarkBlue"
                    onClick={() => setFieldValue(value, "pickerDarkBlue")}
                  />
                  <ColorButton name={value} value="pickerBlue" onClick={() => setFieldValue(value, "pickerBlue")} />

                  <ColorButton
                    name={value}
                    value="pickerLightBlue"
                    onClick={() => setFieldValue(value, "pickerLightBlue")}
                  />
                  <ColorButton name={value} value="pickerTeal" onClick={() => setFieldValue(value, "pickerTeal")} />
                </div>
                <div className="flex flex-row">
                  <ColorButton name={value} value="pickerPurple" onClick={() => setFieldValue(value, "pickerPurple")} />
                  <ColorButton
                    name={value}
                    value="pickerLightGreen"
                    onClick={() => setFieldValue(value, "pickerLightGreen")}
                  />

                  <ColorButton
                    name={value}
                    value="pickerDarkGreen"
                    onClick={() => setFieldValue(value, "pickerDarkGreen")}
                  />
                  <ColorButton name={value} value="pickerGreen" onClick={() => setFieldValue(value, "pickerGreen")} />
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0" onClick={() => setShowModal(false)} />
        </>
      ) : null}
    </>
  );
}
