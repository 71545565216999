import React, { SVGProps } from "react";

export default function BookIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="33" height="27" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.91675 4.75001C1.91675 4.75001 4.10425 1.83334 9.20841 1.83334C14.3126 1.83334 16.5001 4.75001 16.5001 4.75001V25.1667C16.5001 25.1667 14.3126 23.7083 9.20841 23.7083C4.10425 23.7083 1.91675 25.1667 1.91675 25.1667V4.75001Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 4.75001C16.5 4.75001 18.6875 1.83334 23.7917 1.83334C28.8958 1.83334 31.0833 4.75001 31.0833 4.75001V25.1667C31.0833 25.1667 28.8958 23.7083 23.7917 23.7083C18.6875 23.7083 16.5 25.1667 16.5 25.1667V4.75001Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
