import cx from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageResponseData } from "../../api/types";
import { Header } from "../../components/Header";
import ImageContainer from "../../components/ImageContainer";
import { useModal } from "../../components/Modal";
import PageCont from "../../components/PageCont";
import PageContHeader from "../../components/PageContHeader";
import ProgressButtons from "../../components/ProgressButtons";
import StoryImage from "../../components/StoryImage";
import ArrowButton from "../../components/componentsImpl/ArrowButton";
import ReturnButton from "../../components/componentsImpl/ReturnButton";
import { useSelector } from "../../hooks/storeHooks";
import { serializeQueryProps } from "../../store/storeUtils";
import { playAudio } from "../../util/playAudioFunction";
import SettingsButtonModal from "../Settings/components/SettingsButtonModal";

import LockButton from "./components/LockButton";
import TutorialButtonModal from "./components/TutorialButtonModal";
import { singleStoryAction } from "./store/singleStorySlice";

export default function SingleStory() {
  const history = useHistory();
  const dispatch = useDispatch();
  const tutorialModal = useModal();
  const story = useSelector((state) => state.singleStory.story);
  const { numberOfPages, bgColor, audioOn } = useSelector((state) => state.settings.settings);
  const [numberOfOnScreenPages, setNumberOfOnScreenPages] = useState(0);
  const [locked, setLocked] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [playingPageId, setPlayingPageId] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const windowUrl = window.location.search;
  const modal = useModal();
  const jwt = useSelector((state) => state.registrationLogin.authenticationData.jwt);

  const audioRef = useRef(new Audio());

  const onClickRedirect = () => {
    history.push("/stories");
  };

  useEffect(() => {
    setNumberOfOnScreenPages(Math.ceil(story.pages.content.length / numberOfPages) - 1);
    if (currentPage < numberOfOnScreenPages) {
      setHasNextPage(true);
    } else {
      setHasNextPage(false);
    }

    if (currentPage != 0) {
      setHasPrevPage(true);
    } else {
      setHasPrevPage(false);
    }
  }, [setHasNextPage, setHasPrevPage, currentPage, story.pages.content.length, numberOfPages, numberOfOnScreenPages]);

  useEffect(() => {
    return () => {
      if (playing) {
        audioRef.current.pause();
      }
    };
  }, [playing, audioRef]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentPageIndex(0);
  }, [numberOfPages, setCurrentPage]);

  useEffect(() => {
    if (!locked) {
      setCurrentPage(currentPageIndex);
    }
  }, [currentPageIndex, locked, setCurrentPageIndex]);

  useEffect(() => {
    const id = window.location.href.split("/").pop();
    console.log(id);

    if (story == undefined || story.id.toString() != id) {
      dispatch(singleStoryAction.resetStory());
      dispatch(
        singleStoryAction.getSingleStory({
          storyId: id ?? "-1",
          queryParams: serializeQueryProps(),
          jwt: jwt,
        })
      );
    }
  }, [dispatch, jwt]);

  const lockScreen = () => {
    setLocked(!locked);
  };

  const nextPage = useCallback(() => {
    if (hasNextPage && !locked) {
      setCurrentPage(currentPage + 1);

      if (currentPageIndex < story.pages.content.length - 1) {
        setCurrentPageIndex(currentPageIndex + 1);
      } else {
        setCurrentPageIndex(0);
      }
    }
  }, [currentPage, currentPageIndex, hasNextPage, locked, story.pages.content.length]);

  const previousPage = useCallback(() => {
    if (hasPrevPage && !locked) {
      setCurrentPage(currentPage - 1);

      if (currentPageIndex > 0) {
        setCurrentPageIndex(currentPageIndex - 1);
      } else {
        setCurrentPageIndex(story.pages.content.length - 1);
      }
    }
  }, [currentPage, currentPageIndex, hasPrevPage, locked, story.pages.content.length]);

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      switch (e.key) {
        case "ArrowLeft":
          previousPage();
          break;
        case "ArrowRight":
          nextPage();
          break;
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [nextPage, previousPage]);

  const playStoryAudio = (audio: string, id: number) => {
    return audioOn ? () => playAudio(audio, playing, setPlaying, audioRef, audioOn, id, setPlayingPageId) : undefined;
  };

  const numOfPagesIsSix = numberOfPages === 6;
  const numOfPagesIsTwo = numberOfPages === 2;
  const numOfPagesIsOne = numberOfPages === 1;

  const pagesClassName = cx("grid gap-y-4 lg:gap-x-20 sm:gap-x-10", {
    "lg:grid-cols-3 lg:grid-rows-2 md:grid-cols-2 md:grid-rows-3": numOfPagesIsSix,
    "lg:grid-cols-2 lg:grid-rows-1 md:grid-cols-1 md:grid-rows-2": numOfPagesIsTwo,
    "md:grid-cols-1 md:grid-rows-1": numOfPagesIsOne,
  });

  const storyImageClassName = cx({
    storyImageSmall: numOfPagesIsSix,
    "storyImageMedium text-lg": numOfPagesIsTwo,
    "storyImageLarge text-xl": numOfPagesIsOne,
  });

  const imageContainerClassName = cx("object-contain", {
    imageContainerSmall: numOfPagesIsSix,
    imageContainerMedium: numOfPagesIsTwo,
    imageContainerLarge: numOfPagesIsOne,
  });

  return (
    <PageCont>
      <div className={`flex flex-col w-screen h-screen overflow-x-hidden items-center bg-${bgColor}`}>
        <Header />
        <PageContHeader
          title={story.title}
          titleClassName="-mr-12"
          isGameOrStory={true}
          rightButtons={[
            <LockButton key={1} onClick={lockScreen} locked={locked} />,
            <TutorialButtonModal key={2} {...tutorialModal} />,
            <SettingsButtonModal key={3} {...modal} />,
          ]}
          leftButtons={<ReturnButton text="Priče" variant="secondary" onClick={onClickRedirect} />}
          className="w-full items-center mt-8 pr-8 pl-28"
        />
        <div className="inline-block flex justify-between items-center">
          <div className="mb-8 px-4">
            {hasPrevPage ? (
              <ArrowButton type="left" onClick={previousPage} />
            ) : (
              <ArrowButton type="left" onClick={previousPage} className="invisible" />
            )}
          </div>
          <div className={pagesClassName}>
            {story.pages.content
              .slice(currentPage * numberOfPages, (currentPage + 1) * numberOfPages)
              .map((page: PageResponseData) => (
                <StoryImage key={page.id} text={page.text} className={storyImageClassName}>
                  {
                    <ImageContainer
                      title={audioOn ? "Klikom na sliku se reproducira zvuk" : ""}
                      image={page.image}
                      imageAudio={audioOn}
                      playing={playing && page.id === playingPageId}
                      imageClassName={imageContainerClassName}
                      onClick={playStoryAudio(page.audio, page.id)}
                    />
                  }
                </StoryImage>
              ))}
          </div>
          <div className="mb-8 px-4">
            {hasNextPage ? (
              <ArrowButton type="right" onClick={nextPage} />
            ) : (
              <ArrowButton type="right" onClick={nextPage} className="invisible" />
            )}
          </div>
        </div>
        <div className={numOfPagesIsSix === true ? "-mt-4" : ""}>
          <ProgressButtons
            arrayLength={numberOfOnScreenPages + 1}
            currentIndex={currentPageIndex}
            setCurrentIndex={setCurrentPageIndex}
            activeColor={bgColor !== "primary" ? "bg-darkprimary" : "bg-darksecondary"}
            inactiveColor={bgColor !== "primary" ? "bg-primary" : "bg-secondary"}
            locked={locked}
          />
        </div>
      </div>
    </PageCont>
  );
}
