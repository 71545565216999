import { AnyAction, unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { SingleStoryResponse, StoryCard, StoryData } from "../../../api/types";
import { serializeQueryProps } from "../../../store/storeUtils";
import { makeToast } from "../../../util/makeToast";
import { singleStoryAction } from "../../Stories/store/singleStorySlice";
import { LocalStory } from "../../Stories/store/storiesSlice";
import { gamesAction } from "../store/gamesSlice";

import SelectAnotherStory from "./oddOneOutSelectAnotherStory";

export default function PlayOddOneOutGame() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectAnotherStory } = SelectAnotherStory();

  const playOddOneOut = async (id: number, stories: StoryCard[], localStories: LocalStory[], jwt: string) => {
    const tmpStory = (await dispatch(
      singleStoryAction.getSingleStory({
        storyId: id.toString(),
        queryParams: serializeQueryProps(),
        jwt: jwt,
      }) as unknown as AnyAction
    ).then(unwrapResult)) as SingleStoryResponse;

    const selectedStory: StoryData = { id: tmpStory.id, title: tmpStory.title, pages: tmpStory.pages.content };

    const anotherStory = await selectAnotherStory(id, "default", stories, localStories, jwt);

    dispatch(gamesAction.putOddOneSelectedStory(selectedStory));
    dispatch(gamesAction.putOddOneStory(anotherStory));

    if (selectedStory.pages.length < 4) {
      makeToast("S odabranom pričom nije moguće igrati igru Izbaci uljeza jer ima manje od četiri slike u priči.");
    } else {
      history.push(`/games/izbaci_uljeza/${id}`);
    }
  };

  return { playOddOneOut };
}
