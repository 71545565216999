import React, { SVGProps } from "react";

export default function CrossIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.23207 2.23256L1.23197 2.23266C0.763289 2.70148 0.5 3.33725 0.5 4.00016C0.5 4.66307 0.763289 5.29884 1.23197 5.76766L1.23202 5.76772L11.4645 16.0002L1.23202 26.2326L1.23197 26.2326L1.22593 26.2388C0.770534 26.7103 0.518548 27.3418 0.524244 27.9973C0.52994 28.6528 0.792862 29.2798 1.25638 29.7434C1.7199 30.2069 2.34693 30.4698 3.00243 30.4755C3.65792 30.4812 4.28942 30.2292 4.76093 29.7738L4.76098 29.7739L4.76713 29.7677L14.9996 19.5353L25.232 29.7677L25.232 29.7678L25.2382 29.7738C25.7097 30.2292 26.3412 30.4812 26.9967 30.4755C27.6522 30.4698 28.2792 30.2069 28.7428 29.7434C29.2063 29.2798 29.4692 28.6528 29.4749 27.9973C29.4806 27.3418 29.2286 26.7103 28.7732 26.2388L28.7733 26.2388L28.7671 26.2326L18.5347 16.0002L28.7643 5.77059C29.0015 5.54062 29.1908 5.26591 29.3212 4.96232C29.4522 4.65731 29.5212 4.32926 29.5241 3.99731C29.5269 3.66536 29.4637 3.33616 29.338 3.02892C29.2123 2.72168 29.0266 2.44255 28.7919 2.20782C28.5572 1.97309 28.2781 1.78745 27.9708 1.66175C27.6636 1.53605 27.3344 1.4728 27.0024 1.47568C26.6705 1.47856 26.3424 1.54753 26.0374 1.67855C25.7338 1.80897 25.4591 1.99827 25.2291 2.2355L14.9996 12.4651L4.76713 2.23261L4.76707 2.23256C4.29825 1.76388 3.66248 1.50059 2.99957 1.50059C2.33666 1.50059 1.70089 1.76388 1.23207 2.23256Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
}
