import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";

import { useSelector } from "../hooks/storeHooks";

type PageContHeaderProps = {
  title: string;
  leftButtons?: React.ReactNode;
  rightButtons?: React.ReactNode;
  titleClassName?: string;
  isGameOrStory?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function PageContHeader({
  leftButtons,
  rightButtons,
  title,
  titleClassName,
  className,
  isGameOrStory = false,
  ...props
}: PageContHeaderProps) {
  const { bgColor } = useSelector((state) => state.settings.settings);

  const pageContainerHeaderClassName = cx(className, "w-full my-10 flex flex-row justify-between", {
    [`text-white`]: bgColor === "black" && isGameOrStory,
  });
  const finalTitleClassName = cx(titleClassName, "font-bold text-4xl mt-1 truncate");

  return (
    <div className={pageContainerHeaderClassName} {...props}>
      <div>{leftButtons}</div>
      <div className="flex justify-center w-1/2 h-12">
        <div className={finalTitleClassName}>{title}</div>
      </div>
      <div className="flex flex-row justify-center">{rightButtons}</div>
    </div>
  );
}
