import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { store } from "../../../store/store";
import { singleStoryAction } from "../../Stories/store/singleStorySlice";
import { LocalStory } from "../../Stories/store/storiesSlice";

export default function PlayConnectGameLocalStory() {
  const dispatch = useDispatch();
  const history = useHistory();

  const playConnectLocal = async (card: LocalStory) => {
    await dispatch(singleStoryAction.putLocalStory(card));

    const selectedStory = store.getState().singleStory.story;

    if (selectedStory.pages.content.length < 2) {
      alert('Nemoguće igrati. Za igrati igru "Spajalica" potrebne su minimalno dvije slike u priči.');
      return;
    }

    history.push(`/games/spajalica/${card.id}`);
  };

  return { playConnectLocal };
}
