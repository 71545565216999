import { emailSender } from "./api-paths";
import { SendEmailRequest } from "./types";

export async function sendEmail(sendEmailRequest: SendEmailRequest): Promise<Response> {
  const formData = new FormData();
  formData.append("email", sendEmailRequest.email);
  formData.append("jsonFile", sendEmailRequest.jsonFile);

  return await fetch(emailSender.emailSender, {
    method: "POST",
    body: formData,
  });
}
