import cx from "classnames";
import React, { useEffect, useState } from "react";

import { PageRequestData } from "../../../api/types";
import Button from "../../../components/Button";
import FileUploadComponent from "../../../components/FileUploadComponent";
import FormLayout from "../../../components/FormLayout";
import ImageContainer from "../../../components/ImageContainer";
import Input from "../../../components/Input";
import Modal, { ModalFooter, ModalHeader, useModal, UseModal } from "../../../components/Modal";
import IconButton from "../../../components/componentsImpl/IconButton";
import AppleIcon from "../../../components/icons/AppleIcon";
import CameraIcon from "../../../components/icons/CameraIcon";
import CloudUploadIcon from "../../../components/icons/CloudUploadIcon";
import ImageSingleIcon from "../../../components/icons/ImageSingleIcon";
import MicrophoneIcon from "../../../components/icons/MicrophoneIcon";
import PauseIcon from "../../../components/icons/PauseIcon";
import PlayIcon from "../../../components/icons/PlayIcon";
import RecordIcon from "../../../components/icons/RecordIcon";
import SaveIcon from "../../../components/icons/SaveIcon";
import XIcon from "../../../components/icons/XIcon";
import usePlayAudio from "../../../hooks/usePlayAudio";
import useRecordAudio from "../../../hooks/useRecordAudio";

import ImageCaptureModal from "./ImageCaptureModal";
import LabeledButton from "./LabeledButton";
import LinksContainer from "./LinksContainer";
import SymbolModal from "./SymbolModal";

type PageModalProps = {
  initialState?: PageRequestData;
  pageNumber?: number;
  variant: "newPage" | "edit";
  pageList: PageRequestData[];
  setPageList: (pageList: PageRequestData[]) => void;
  modal: UseModal;
};

export default function PageModal({
  initialState,
  pageNumber = -1,
  variant,
  pageList,
  setPageList,
  modal,
}: PageModalProps) {
  const [activeButton, setActiveButton] = useState(-1);
  const [imagePreview, setImagePreview] = useState<string | undefined>();
  const [textPreview, setTextPreview] = useState<string | undefined>();
  const [audioPreview, setAudioPreview] = useState<string | undefined>();
  const [textError, setTextError] = useState("");
  const [imageError, setImageError] = useState("");
  const [soundError, setSoundError] = useState("");
  const [imageFilename, setImageFilename] = useState("");
  const [soundFilename, setSoundFilename] = useState("");

  const symbolModal = useModal();
  const imageCaptureModal = useModal();

  const { isRecording, stopwatch, onClickStartRecording, stopRecording } = useRecordAudio({
    setContent: (passedValue) => setAudioPreview(passedValue),
    setFilename: (passedValue) => setSoundFilename(passedValue),
  });

  const { playAudio, pauseAudio, paused } = usePlayAudio({
    audioPreview: audioPreview,
    setAudioPreview: (passedValue) => setAudioPreview(passedValue),
  });

  useEffect(() => {
    setImagePreview(initialState?.image);
    setTextPreview(initialState?.text);
    setAudioPreview(initialState?.audio);
  }, [initialState, setImagePreview, setTextPreview, setAudioPreview]);

  useEffect(() => {
    if (isRecording) {
      setSoundFilename("");
    }
  }, [isRecording]);

  useEffect(() => {
    return () => {
      if (!paused) pauseAudio();
    };
  }, [paused, pauseAudio]);

  const uploadImage = (contentString: string) => {
    const imageString = contentString.substr(contentString.indexOf(",") + 1);

    setImagePreview(imageString);
  };

  const deleteImage = () => {
    setImagePreview("");
    setImageFilename("");
    setActiveButton(-1);
  };

  const stopRecordingFunction = () => {
    stopRecording();
  };

  const deleteAudio = () => {
    setAudioPreview("");
    setSoundFilename("");
  };

  const pageValidation = (image?: string, text?: string) => {
    if (image && text && text.replaceAll(/\s/g, "").length > 0) {
      return true;
    } else {
      if (!image) {
        setImageError("Slika je obavezan sadržaj.");
      } else {
        setImageError("");
      }
      if (!text) {
        setTextError("Tekst je obavezan sadržaj.");
      } else {
        setTextError("");
      }
      return false;
    }
  };

  const beginSave = () => {
    const page: PageRequestData = { image: imagePreview ?? "", text: textPreview ?? "", audio: audioPreview ?? "" };

    if (pageValidation(page.image, page.text)) {
      const tmpPageList = pageList;
      tmpPageList.push(page);
      setPageList([...tmpPageList]);
      modal.onClose();
    }
  };

  const beginEdit = () => {
    const editedPage: PageRequestData = {
      image: imagePreview ?? "",
      text: textPreview ?? "",
      audio: audioPreview ?? "",
    };

    if (pageValidation(editedPage.image, editedPage.text)) {
      if (pageNumber - 1 < pageList.length) {
        const tmpPageList = pageList;
        tmpPageList[pageNumber - 1] = editedPage;
        setPageList([...tmpPageList]);
      }
      modal.onClose();
    }
  };

  const activeButtonClassName = "border rounded rounded-small cursor-pointer p-2 border-primary text-primary";
  const inactiveButtonClassName = "border rounded rounded-small cursor-pointer p-2 border-secondary text-secondary";
  const imageContainerClassName = cx("object-contain w-80 h-80 rounded", { imagePreview: "bg-gray" });

  const onCameraButtonClick = () => {
    setActiveButton(1);
    imageCaptureModal.onOpen();
  };

  const onAppleButtonClick = () => {
    setActiveButton(2);
    symbolModal.onOpen();
  };

  return (
    <Modal {...modal}>
      <ModalHeader variant="primary" onClose={modal.onClose} title="Uredi sliku" />
      <div className="flex flex-row justify-between my-6 mx-8">
        <div className="flex flex-col justify-start pr-8">
          <div className="font-semibold text-xl ">1. Slika</div>
          <div className="flex flex-row justify-between mt-4 gap-x-6">
            <FileUploadComponent
              setContent={(content) => uploadImage(content)}
              setErrorMessage={(content) => setImageError(content)}
              setFileName={(content) => setImageFilename(content)}
              maxFileSize={2000000}
              readAs="url"
              acceptContentType="image/jpeg, image/png, image/gif, image/webp, image/bmp"
            >
              <div className="flex flex-col items-center">
                <ImageSingleIcon
                  className={activeButton === 0 ? activeButtonClassName : inactiveButtonClassName}
                  width={60}
                  height={60}
                  onClick={() => setActiveButton(0)}
                />
                <div className={activeButton === 0 ? "text-primary" : "text-secondary"}>Učitaj</div>
              </div>
            </FileUploadComponent>

            <div className="flex flex-col items-center">
              <CameraIcon
                className={activeButton === 1 ? activeButtonClassName : inactiveButtonClassName}
                width={60}
                height={60}
                onClick={onCameraButtonClick}
              />
              <div className={activeButton === 1 ? "text-primary" : "text-secondary"}>Kamera</div>
              <ImageCaptureModal
                modal={imageCaptureModal}
                setImagePreview={setImagePreview}
                setImageFilename={setImageFilename}
              />
            </div>

            <div className="flex flex-col items-center">
              <AppleIcon
                className={activeButton === 2 ? activeButtonClassName : inactiveButtonClassName}
                width={60}
                height={60}
                onClick={onAppleButtonClick}
              />
              <div className={activeButton === 2 ? "text-primary" : "text-secondary"}>Simboli</div>
              {symbolModal.isOpen && (
                <SymbolModal
                  modal={symbolModal}
                  setImagePreview={setImagePreview}
                  setImageFilename={setImageFilename}
                  setTextPreview={setTextPreview}
                  setAudioPreview={setAudioPreview}
                  setSoundFilename={setSoundFilename}
                />
              )}
            </div>
          </div>
          <div className="h-8 mt-2">
            <div className="flex flex-row justify-start items-center">
              <div className="max-w-15em">
                <p className="pr-2 truncate">{imageFilename}</p>
              </div>
              {imageFilename && <XIcon className="cursor-pointer" onClick={deleteImage} />}
            </div>
            {imageError && <div className="text-error">{imageError}</div>}
          </div>
          <div className="font-semibold text-xl mt-4 -mb-4">2. Tekst</div>
          <FormLayout initialValues={{ area: textPreview }} onSubmit={() => console.log("Submit")}>
            <Input
              label=""
              name="area"
              textArea={true}
              maxLength={100}
              onInput={(e) => setTextPreview(e.currentTarget.value)}
            />
          </FormLayout>
          <div className="h-4">{textError && <div className="text-error">{textError}</div>}</div>
          <div className="font-semibold text-xl mt-4">3. Zvuk</div>
          <div className="flex flex-row justify-between mt-4">
            <div className="flex flex-row justify-between gap-x-4 ml-4">
              <LabeledButton
                subtitle="Snimaj"
                button={
                  <IconButton
                    onClick={isRecording ? stopRecordingFunction : onClickStartRecording}
                    className={`w-12 h-12 bg-${isRecording ? "error" : "primary"}`}
                    icon={
                      isRecording ? <RecordIcon width={18} height={18} /> : <MicrophoneIcon width={20} height={27} />
                    }
                  />
                }
              />
              <FileUploadComponent
                setContent={(content) => setAudioPreview(content)}
                setFileName={(content) => setSoundFilename(content)}
                setErrorMessage={(content) => setSoundError(content)}
                maxFileSize={1000000}
                readAs="url"
                acceptContentType="audio/*"
              >
                <LabeledButton
                  subtitle="Učitaj"
                  button={
                    <IconButton className="bg-secondary w-12 h-12" icon={<CloudUploadIcon width={26} height={36} />} />
                  }
                />
              </FileUploadComponent>
            </div>
            <div className="flex flex-row justify-between gap-x-4">
              <LabeledButton
                subtitle="Preslušaj"
                button={
                  <IconButton
                    className="bg-secondary w-12 h-12"
                    onClick={paused && !isRecording ? playAudio : pauseAudio}
                    icon={paused ? <PlayIcon width={20} height={30} /> : <PauseIcon width={20} height={30} />}
                  />
                }
              />
            </div>
          </div>
          {isRecording && (
            <div className="h-2">
              00:{stopwatch < 10 ? "0" : ""}
              {stopwatch}
            </div>
          )}
          <div className="h-4 mt-0.5">
            <div className="flex flex-row items-center">
              <div className="max-w-15em">
                <p className="pr-2 truncate">{soundFilename}</p>
              </div>
              {soundFilename && <XIcon className="cursor-pointer" onClick={deleteAudio} />}
            </div>
            {soundError && <div className="text-error">{soundError}</div>}
          </div>
        </div>
        <div className="flex flex-col justify-start border-l border-darkGray pl-9">
          <div className="text-center font-semibold text-lg mb-4">Prikaz</div>
          <div className="flex justify-center w-full">
            <div className="bg-gray rounded w-80 h-80">
              {imagePreview && <ImageContainer image={imagePreview ?? ""} imageClassName={imageContainerClassName} />}
            </div>
          </div>
          <div className="flex flex-row justify-between w-80 h-28 mt-4 ml-3">
            <LabeledButton
              button={
                <IconButton
                  className="bg-secondary w-12 h-12"
                  onClick={paused ? playAudio : pauseAudio}
                  icon={paused ? <PlayIcon width={20} height={30} /> : <PauseIcon width={20} height={30} />}
                />
              }
            />
            <p className="break-words text-center w-64 ml-2 mr-10">{textPreview}</p>
          </div>
          <LinksContainer
            links={[
              { title: "ARASAAC", link: "https://arasaac.org" },
              { title: "Pexels", link: "https://www.pexels.com" },
              { title: "Unsplash", link: "https://www.unsplash.com" },
              { title: "Stocksnap", link: "https://www.stocksnap.io" },
              { title: "Freeimages", link: "https://www.freeimages.com" },
            ]}
          />
        </div>
      </div>

      <ModalFooter>
        <Button
          variant="primary"
          className="font-semibold w-40"
          onClick={variant === "newPage" ? beginSave : beginEdit}
        >
          <SaveIcon width={20} height={20} className="mr-5" />
          Spremi
        </Button>
      </ModalFooter>
    </Modal>
  );
}
