import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { useLocation } from "react-router-dom";

type SidebarProps = {
  children: React.ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type SidebarItemProps = {
  icon: React.ReactNode;
  text?: string;
  link?: string;
  children?: React.ReactNode;
  id?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function SidebarItem({ icon, text, link, className = "mt-2 md:mt-5", children, ...props }: SidebarItemProps) {
  const location = useLocation();
  console.log(location.pathname);
  const selected = location.pathname.includes(link ? link : "");
  const selectedClassName = cx({
    "transform scale-110 relative md:z-20 bg-primaryLight shadow-primary": selected,
  });

  const sidebarItemClassName = cx(
    className,
    selectedClassName,
    "flex flex-row items-center justify-start hover:bg-primaryLight hover:shadow-primary cursor-pointer text-white" +
      " font-semibold pl-4 md:pl-3 pr-2 lg:pl-6 h-12 transform hover:scale-110 relative md:z-20"
  );

  return (
    <div className={sidebarItemClassName} {...props}>
      <div className="w-auto h-auto">{icon}</div>
      <p className="flex flex-row flex-grow justify-between items-center ml-3">
        {text}
        {children}
      </p>
    </div>
  );
}

function Sidebar({ children, className, ...props }: SidebarProps) {
  const sidebarClassName = cx(className, "flex flex-col");

  return (
    <div className={sidebarClassName} {...props}>
      {children}
    </div>
  );
}

Sidebar.Item = SidebarItem;
export default Sidebar;
