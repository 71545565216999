import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";

import * as pagesApi from "../../../api/singleStory";
import { PageResponseData, SingleStoryResponse } from "../../../api/types";
import { fetchWrapper } from "../../../store/storeUtils";

import { LocalStory } from "./storiesSlice";

const getSingleStory = createAsyncThunk(
  "stories/getSingleStory",
  async (
    { storyId, queryParams, jwt }: { storyId: string; queryParams: string; jwt?: string },
    { rejectWithValue }
  ) => {
    try {
      return await fetchWrapper<SingleStoryResponse>(pagesApi.getSingleStory(storyId, queryParams, jwt));
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type SingleStoryState = {
  story: SingleStoryResponse;
  oddOneOutStories: SingleStoryResponse[];
  status: "idle" | "success" | "waiting" | "error";
  error?: SerializedError;
};

export const singleStoryInitialState: SingleStoryState = {
  story: {
    id: -1,
    title: "",
    pages: { content: [] as PageResponseData[] },
    favorite: false,
  } as SingleStoryResponse,
  oddOneOutStories: [],
  status: "idle",
  error: undefined,
};

export const singleStorySlice = createSlice({
  name: "singleStory",
  initialState: singleStoryInitialState,
  reducers: {
    putLocalStory(state, action: PayloadAction<LocalStory>) {
      state.story.id = action.payload.id;
      state.story.title = action.payload.title;
      state.story.favorite = action.payload.favorite;
      state.story.pages.content = action.payload.pages;
    },

    resetStory(state) {
      state.story = {
        id: -1,
        title: "",
        pages: { content: [] as PageResponseData[] },
        favorite: false,
      } as SingleStoryResponse;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleStory.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(getSingleStory.fulfilled, (state, action) => {
      state.status = "success";
      state.story = action.payload;
      if (!state.oddOneOutStories.map((story) => story.title).includes(action.payload.title)) {
        state.oddOneOutStories.push(action.payload);
      }
    });

    builder.addCase(getSingleStory.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error;
    });
  },
});

export const singleStory = singleStorySlice.reducer;
export const singleStoryAction = { ...singleStorySlice.actions, getSingleStory };
