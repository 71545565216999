import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "../hooks/storeHooks";
import { loginAction } from "../pages/Login/store/loginSlice";
import { settingsAction } from "../pages/Settings/store/settingsSlice";
import { storiesAction } from "../pages/Stories/store/storiesSlice";

import Button from "./Button";
import Grid from "./Grid";
import logoImage from "./assets/logo.png";
import UserIcon from "./icons/UserIcon";

type HeaderProps = {
  showLogo?: boolean;
  showButton?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function Header({ showLogo = true, showButton = false, className, ...props }: HeaderProps) {
  const headerClassName = cx(className, " bg-white shadow-lightGray w-full");

  const history = useHistory();

  const dispatch = useDispatch();

  const loggedIn = useSelector((state) => state.registrationLogin.loggedIn);

  const logout = async () => {
    await dispatch(settingsAction.resetSettings());
    await dispatch(storiesAction.resetStories());
    dispatch(loginAction.resetAuthData());
    history.push("/");
  };

  const login = () => {
    dispatch(storiesAction.resetLocalStories());
    history.push("/");
  };

  return (
    <div className={headerClassName} {...props}>
      <Grid container spacing={0}>
        <Grid item size={3} />
        <Grid item size={6}>
          {showLogo && <img className="m-auto" src={logoImage} alt="logo" />}
        </Grid>
        <Grid item size={3} className="flex justify-end">
          {showButton && (
            <Button
              className="mr-8 mt-4 font-semibold h-10 w-40"
              variant="primary"
              leadingIcon={<UserIcon />}
              onClick={loggedIn ? logout : login}
              id="button-login-logout"
            >
              {loggedIn ? "Odjava" : "Prijava"}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
