import React, { useCallback, useRef } from "react";
import Webcam from "react-webcam";

import Button from "../../../components/Button";
import Modal, { ModalFooter, ModalHeader, UseModal } from "../../../components/Modal";

type ImageCaptureModalProps = {
  modal: UseModal;
  setImagePreview: (image: string) => void;
  setImageFilename: (imageName: string) => void;
};

export default function ImageCaptureModal({ modal, setImagePreview, setImageFilename }: ImageCaptureModalProps) {
  const webcamRef = useRef<Webcam>(null);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImagePreview(imageSrc ? imageSrc.substr(imageSrc.indexOf(",") + 1) : "");
      setImageFilename("SlikaKamere.jpeg");
    }
    modal.onClose();
  }, [modal, setImagePreview]);

  return (
    <Modal {...modal} className="w-590px h-670px overflow-hidden">
      <ModalHeader onClose={modal.onClose} variant="primary" title="Slikanje fotografije" />
      <div className="flex flex-col h-30rem overflow-y-auto">
        <div className="flex flex-col justify-center items-center w-full mt-28 absolute">
          <div className="spinner w-16 h-16 border-4 my-2 border-4 border-secondary border-solid rounded-full animate-spin" />
          Pričekajte...
        </div>
        <Webcam ref={webcamRef} screenshotFormat="image/jpeg" mirrored={true} />
      </div>
      <ModalFooter>
        <Button variant="primary" type="submit" className="font-semibold w-40 mt-3" onClick={capture}>
          Uslikaj
        </Button>
      </ModalFooter>
    </Modal>
  );
}
