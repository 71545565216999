export async function checkMediaPermission() {
  const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  let returnValue = false;
  await permissions
    .then(() => {
      returnValue = true;
    })
    .catch((err) => {
      console.log(`${err.name} : ${err.message}`);
    });

  return returnValue;
}
