import { AnyAction } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

import { useDispatch } from "../../../hooks/storeHooks";
import { serializeQueryProps } from "../../../store/storeUtils";
import { makeToast } from "../../../util/makeToast";
import { singleStoryAction } from "../../Stories/store/singleStorySlice";

export default function PlaySlagalicaGame() {
  const dispatch = useDispatch();
  const history = useHistory();

  const playSlagalica = async (id: number, jwt: string) => {
    const selectedStory = await dispatch(
      singleStoryAction.getSingleStory({
        storyId: id.toString(),
        queryParams: serializeQueryProps(),
        jwt: jwt,
      }) as unknown as AnyAction
    );

    if (selectedStory.payload.pages.content.length <= 1) {
      makeToast('Nemoguće igrati. Za igrati igru "Slagalica" potrebne su minimalno dvije slike u priči.');
    } else {
      history.push(`/games/slagalica/${id}`);
    }
  };

  return { playSlagalica };
}
