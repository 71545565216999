import React, { SVGProps } from "react";

export default function ArrowIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="29" height="47" viewBox="0 0 29 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.18316 44.9169C1.33017 44.0636 0.850983 42.9065 0.850983 41.7C0.850983 40.4935 1.33017 39.3364 2.18316 38.4832L17.1663 23.5L2.18316 8.51686C1.35434 7.65872 0.895726 6.50938 0.906093 5.31639C0.91646 4.12339 1.39498 2.9822 2.23859 2.13859C3.08219 1.29498 4.22339 0.816462 5.41639 0.806095C6.60938 0.795729 7.75872 1.25434 8.61686 2.08316L26.8169 20.2832C27.6699 21.1364 28.149 22.2935 28.149 23.5C28.149 24.7065 27.6699 25.8636 26.8169 26.7169L8.61686 44.9169C7.76361 45.7699 6.60651 46.249 5.40001 46.249C4.19351 46.249 3.03641 45.7699 2.18316 44.9169Z"
        fill="currentColor"
      />
    </svg>
  );
}
