import { MutableRefObject } from "react";

export const playAudio = (
  content: string,
  playing: boolean,
  setPlaying: (playing: boolean) => void,
  audioRef: MutableRefObject<HTMLAudioElement>,
  audioOn: boolean,
  id?: number,
  setPlayingPageId?: (id: number) => void
) => {
  if (content && audioOn) {
    if (playing) {
      audioRef.current.pause();
    }
    setPlaying(true);
    setPlayingPageId?.(id ?? -1);
    audioRef.current = new Audio(content);

    audioRef.current.play();

    audioRef.current.onended = () => setPlaying(false);
  }
};
