import * as paths from "./api-paths";
import { stories } from "./api-paths";
import { StoryRequestData } from "./types";

export async function getAllStories(queryParams: string, jwt?: string): Promise<Response> {
  return await fetch(`${paths.stories.getAll}${queryParams ? `?${queryParams}` : ""}`, {
    method: "GET",
    headers: {
      "X-Authorization": jwt ? "Bearer " + jwt : "",
    },
  });
}

export async function getStoriesNoPages(jwt?: string): Promise<Response> {
  return await fetch(stories.getStoriesNoPages, {
    method: "GET",
    headers: {
      "X-Authorization": jwt ? "Bearer " + jwt : "",
    },
  });
}

export async function deleteStory(storyId: string, queryParams: string, jwt?: string): Promise<Response> {
  return await fetch(`${stories.deleteStory(storyId)}${queryParams ? `?${queryParams}` : ""}`, {
    method: "DELETE",
    headers: {
      "X-Authorization": jwt ? "Bearer " + jwt : "",
    },
  });
}

export async function addStory(story: StoryRequestData, jwt?: string): Promise<Response> {
  return await fetch(`${stories.addStory}`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      "X-Authorization": jwt ? "Bearer " + jwt : "",
    },
    body: JSON.stringify(story),
  });
}

export async function updateStory(storyId: string, story: StoryRequestData, jwt?: string): Promise<Response> {
  return await fetch(stories.updateStory(storyId), {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      "X-Authorization": jwt ? "Bearer " + jwt : "",
    },
    body: JSON.stringify(story),
  });
}

export async function changeFavoriteStory(storyId: string, jwt?: string): Promise<Response> {
  return await fetch(stories.changeFavorite(storyId), {
    method: "PATCH",
    headers: {
      "X-Authorization": jwt ? "Bearer " + jwt : "",
    },
  });
}
