import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";

import * as gamesApi from "../../../api/games";
import { StoryData, YesNoGameProp } from "../../../api/types";
import { fetchWrapper } from "../../../store/storeUtils";

const getSingleYesNoGame = createAsyncThunk(
  "games/getSingleYesNoGame",
  async ({ storyId, queryParams }: { storyId: string; queryParams: string }) => {
    return await fetchWrapper<YesNoGameProp[]>(gamesApi.getSingleYesNoGame(storyId, queryParams));
  }
);

type GamesState = {
  yesNoQuestions: YesNoGameProp[];
  oddOneSelectedStory: StoryData;
  oddOneAnotherStory: StoryData;
  status: "idle" | "success" | "waiting" | "error";
  error?: SerializedError;
};

export const gamesInitialState: GamesState = {
  yesNoQuestions: [] as YesNoGameProp[],
  oddOneSelectedStory: {} as StoryData,
  oddOneAnotherStory: {} as StoryData,
  status: "idle",
  error: undefined,
};

const gamesSlice = createSlice({
  name: "games",
  initialState: gamesInitialState,
  reducers: {
    putOddOneSelectedStory(state, action: PayloadAction<StoryData>) {
      state.oddOneSelectedStory.id = action.payload.id;
      state.oddOneSelectedStory.title = action.payload.title;
      state.oddOneSelectedStory.pages = action.payload.pages;
    },

    putOddOneStory(state, action: PayloadAction<StoryData>) {
      state.oddOneAnotherStory.id = action.payload.id;
      state.oddOneAnotherStory.title = action.payload.title;
      state.oddOneAnotherStory.pages = action.payload.pages;
    },

    resetOddOneSelectedStory(state) {
      state.oddOneSelectedStory = { id: -1, title: "", pages: [] } as StoryData;
    },

    resetOddOneStory(state) {
      state.oddOneAnotherStory = { id: -1, title: "", pages: [] } as StoryData;
    },

    resetYesNoQuestions(state) {
      state.yesNoQuestions = [] as YesNoGameProp[];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleYesNoGame.fulfilled, (state, action) => {
      state.yesNoQuestions = action.payload;
      state.status = "success";
    });

    builder.addCase(getSingleYesNoGame.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(getSingleYesNoGame.rejected, (state) => {
      state.status = "error";
    });
  },
});

export const games = gamesSlice.reducer;
export const gamesAction = { ...gamesSlice.actions, getSingleYesNoGame };
