import { Settings } from "../api/types";
import { LocalStory } from "../pages/Stories/store/storiesSlice";
import { makeToast } from "../util/makeToast";

function downloadUrl(url: string, filename: string) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const downloadContentJson = ({ settings, localStories, setConfirmMessage }: ExportImportType) => {
  const data = new Blob([JSON.stringify({ settings: settings, localStories: localStories })], {
    type: "application/json",
  });

  const objectUrl = window.URL.createObjectURL(data);

  const date = new Date();

  downloadUrl(
    objectUrl,
    `egalerija-sadržaj-${date.getHours()}-${date.getMinutes()}-${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`
  );
  //setConfirmMessage("Datoteka je uspješno izvezena!");
  makeToast("Datoteka je uspješno izvezena.");
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(objectUrl);
  }, 100);
};

export type ExportImportType = {
  settings: Settings;
  localStories: LocalStory[];
  setConfirmMessage: (ExportLabel: string) => void;
};
