import cx from "classnames";
import { useField } from "formik";
import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";

type ColorButtonProps = {
  name: string;
  value: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export default function ColorButton({ name, value, className, ...props }: ColorButtonProps) {
  const [field] = useField({ name: name, type: "radio", value: value });

  const colorButtonClassName = cx(className, `bg-${value} rounded w-6 h-6 mx-1 my-1 cursor-pointer`, {
    "ring-1 ring-black ring-offset-1": field.checked,
  });

  return (
    <div className={colorButtonClassName} {...props}>
      <input {...field} value={value} className="sr-only" />
    </div>
  );
}
