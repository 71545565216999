import { FormikHelpers } from "formik";
import React from "react";
import { useDispatch } from "react-redux";

import { Settings } from "../../api/types";
import Button from "../../components/Button";
import FormLayout from "../../components/FormLayout";
import Modal, { ModalFooter, ModalHeader, useModal, UseModal } from "../../components/Modal";
import GearSettingsIcon from "../../components/icons/GearSettingsIcon";
import SaveIcon from "../../components/icons/SaveIcon";
import { useSelector } from "../../hooks/storeHooks";

import InfoButtonModal from "./components/InfoButtonModal";
import SettingsFormFields from "./components/SettingsFormFields";
import { settingsAction } from "./store/settingsSlice";

export default function SettingsModal({ ...props }: UseModal) {
  const { isOpen, onOpen, onClose } = props;
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);
  const jwt = useSelector((state) => state.registrationLogin.authenticationData.jwt);
  const infoModal = useModal();

  const onSubmitUpdateSettings = async (settings: Settings, formikHelpers: FormikHelpers<Settings>) => {
    formikHelpers.setSubmitting(true);
    if (jwt) {
      await dispatch(
        settingsAction.updateSettings({
          settingsDTO: settings,
          jwt: jwt,
        })
      );
    } else {
      dispatch(settingsAction.updateSettingsAnonymousUser(settings));
    }
    formikHelpers.setSubmitting(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose} className="font-semibold w-3/4 md:w-1/2 mx-auto">
      <FormLayout
        initialValues={settings}
        onSubmit={(values: Settings, formikHelpers) => onSubmitUpdateSettings(values, formikHelpers)}
      >
        <ModalHeader
          onClose={onClose}
          variant="primary"
          title="Postavke"
          titleIcon={<GearSettingsIcon width={20} height={20} />}
        >
          <InfoButtonModal {...infoModal} />
        </ModalHeader>
        <SettingsFormFields />
        <ModalFooter>
          <Button variant="primary" type="submit" className="font-semibold w-40">
            <SaveIcon width={20} height={20} className="mr-5" />
            Spremi
          </Button>
        </ModalFooter>
      </FormLayout>
    </Modal>
  );
}
