import cx from "classnames";
import React, { SVGProps } from "react";

import { useSelector } from "../../hooks/storeHooks";
import ArrowIcon from "../icons/ArrowIcon";

type ArrowButtonProps = {
  type: "left" | "right" | "down";
} & SVGProps<SVGSVGElement>;

export default function ArrowButton({ type, className, ...props }: ArrowButtonProps) {
  const { bgColor } = useSelector((state) => state.settings.settings);

  const arrowButtonClassName = cx(className, `filter drop-shadow-${type}Primary cursor-pointer`, {
    "text-primary": bgColor !== "primary",
    "text-secondary": bgColor === "primary",
    "hover:text-darksecondary": bgColor === "primary",
    "hover:text-darkprimary": bgColor !== "primary",
  });
  return (
    <ArrowIcon
      {...props}
      className={arrowButtonClassName}
      style={{ transform: `rotate(${type === "left" ? "180" : type === "down" ? "90" : "0"}deg)` }}
    />
  );
}
