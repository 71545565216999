import React, { SVGProps } from "react";
export default function ImageSixIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 57 75" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.74884 12.8333C9.71534 12.8333 10.4988 12.0498 10.4988 11.0833C10.4988 10.1168 9.71534 9.33325 8.74884 9.33325C7.78234 9.33325 6.99884 10.1168 6.99884 11.0833C6.99884 12.0498 7.78234 12.8333 8.74884 12.8333Z"
        fill="currentColor"
      />
      <path
        d="M12.2488 16.3334L10.4988 14.0001L6.99884 18.6668H20.9988L15.7488 11.6667L12.2488 16.3334Z"
        fill="currentColor"
      />
      <path
        d="M23.3322 4.66675H4.66555C3.37871 4.66675 2.33221 5.71325 2.33221 7.00008V21.0001C2.33221 22.2869 3.37871 23.3334 4.66555 23.3334H23.3322C24.619 23.3334 25.6656 22.2869 25.6656 21.0001V7.00008C25.6656 5.71325 24.619 4.66675 23.3322 4.66675ZM4.66555 21.0001V7.00008H23.3322L23.3345 21.0001H4.66555Z"
        fill="currentColor"
      />
      <path
        d="M37.7488 12.8333C38.7153 12.8333 39.4988 12.0498 39.4988 11.0833C39.4988 10.1168 38.7153 9.33325 37.7488 9.33325C36.7823 9.33325 35.9988 10.1168 35.9988 11.0833C35.9988 12.0498 36.7823 12.8333 37.7488 12.8333Z"
        fill="currentColor"
      />
      <path
        d="M41.2488 16.3334L39.4988 14.0001L35.9988 18.6668H49.9988L44.7488 11.6667L41.2488 16.3334Z"
        fill="currentColor"
      />
      <path
        d="M52.3322 4.66675H33.6655C32.3787 4.66675 31.3322 5.71325 31.3322 7.00008V21.0001C31.3322 22.2869 32.3787 23.3334 33.6655 23.3334H52.3322C53.619 23.3334 54.6656 22.2869 54.6656 21.0001V7.00008C54.6656 5.71325 53.619 4.66675 52.3322 4.66675ZM33.6655 21.0001V7.00008H52.3322L52.3345 21.0001H33.6655Z"
        fill="currentColor"
      />
      <path
        d="M8.74884 36.375C9.71534 36.375 10.4988 35.6195 10.4988 34.6875C10.4988 33.7555 9.71534 33 8.74884 33C7.78234 33 6.99884 33.7555 6.99884 34.6875C6.99884 35.6195 7.78234 36.375 8.74884 36.375Z"
        fill="currentColor"
      />
      <path d="M12.2488 39.75L10.4988 37.5L6.99884 42H20.9988L15.7488 35.25L12.2488 39.75Z" fill="currentColor" />
      <path
        d="M23.3322 28.5H4.66555C3.37871 28.5 2.33221 29.5091 2.33221 30.75V44.25C2.33221 45.4909 3.37871 46.5 4.66555 46.5H23.3322C24.619 46.5 25.6656 45.4909 25.6656 44.25V30.75C25.6656 29.5091 24.619 28.5 23.3322 28.5ZM4.66555 44.25V30.75H23.3322L23.3345 44.25H4.66555Z"
        fill="currentColor"
      />
      <path
        d="M37.7488 36.375C38.7153 36.375 39.4988 35.6195 39.4988 34.6875C39.4988 33.7555 38.7153 33 37.7488 33C36.7823 33 35.9988 33.7555 35.9988 34.6875C35.9988 35.6195 36.7823 36.375 37.7488 36.375Z"
        fill="currentColor"
      />
      <path d="M41.2488 39.75L39.4988 37.5L35.9988 42H49.9988L44.7488 35.25L41.2488 39.75Z" fill="currentColor" />
      <path
        d="M52.3322 28.5H33.6655C32.3787 28.5 31.3322 29.5091 31.3322 30.75V44.25C31.3322 45.4909 32.3787 46.5 33.6655 46.5H52.3322C53.619 46.5 54.6656 45.4909 54.6656 44.25V30.75C54.6656 29.5091 53.619 28.5 52.3322 28.5ZM33.6655 44.25V30.75H52.3322L52.3345 44.25H33.6655Z"
        fill="currentColor"
      />
      <path
        d="M8.74884 59.8333C9.71534 59.8333 10.4988 59.0498 10.4988 58.0833C10.4988 57.1168 9.71534 56.3333 8.74884 56.3333C7.78234 56.3333 6.99884 57.1168 6.99884 58.0833C6.99884 59.0498 7.78234 59.8333 8.74884 59.8333Z"
        fill="currentColor"
      />
      <path
        d="M12.2488 63.3334L10.4988 61.0001L6.99884 65.6668H20.9988L15.7488 58.6667L12.2488 63.3334Z"
        fill="currentColor"
      />
      <path
        d="M23.3322 51.6667H4.66555C3.37871 51.6667 2.33221 52.7132 2.33221 54.0001V68.0001C2.33221 69.2869 3.37871 70.3334 4.66555 70.3334H23.3322C24.619 70.3334 25.6656 69.2869 25.6656 68.0001V54.0001C25.6656 52.7132 24.619 51.6667 23.3322 51.6667ZM4.66555 68.0001V54.0001H23.3322L23.3345 68.0001H4.66555Z"
        fill="currentColor"
      />
      <path
        d="M37.7488 59.8333C38.7153 59.8333 39.4988 59.0498 39.4988 58.0833C39.4988 57.1168 38.7153 56.3333 37.7488 56.3333C36.7823 56.3333 35.9988 57.1168 35.9988 58.0833C35.9988 59.0498 36.7823 59.8333 37.7488 59.8333Z"
        fill="currentColor"
      />
      <path
        d="M41.2488 63.3334L39.4988 61.0001L35.9988 65.6668H49.9988L44.7488 58.6667L41.2488 63.3334Z"
        fill="currentColor"
      />
      <path
        d="M52.3322 51.6667H33.6655C32.3787 51.6667 31.3322 52.7132 31.3322 54.0001V68.0001C31.3322 69.2869 32.3787 70.3334 33.6655 70.3334H52.3322C53.619 70.3334 54.6656 69.2869 54.6656 68.0001V54.0001C54.6656 52.7132 53.619 51.6667 52.3322 51.6667ZM33.6655 68.0001V54.0001H52.3322L52.3345 68.0001H33.6655Z"
        fill="currentColor"
      />
    </svg>
  );
}
