import React, { SVGProps } from "react";
export default function GamesIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 129 142" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M112.012 57.3579V84.8331"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.3224 119.177L88.2192 112.308L100.116 105.439"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5285 119.177L40.6284 112.308L28.7316 105.439"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8386 57.3579V84.8331"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7316 36.7515L40.6318 29.8827L52.5285 23.0139"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.3224 23.0139L88.2192 29.8827L100.116 36.7515"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.4254 139.783C68.0689 139.783 71.5631 138.336 74.1394 135.76C76.7157 133.183 78.163 129.689 78.163 126.046C78.163 122.402 76.7157 118.908 74.1394 116.332C71.5631 113.755 68.0689 112.308 64.4254 112.308C60.782 112.308 57.2878 113.755 54.7115 116.332C52.1352 118.908 50.6879 122.402 50.6879 126.046C50.6879 129.689 52.1352 133.183 54.7115 135.76C57.2878 138.336 60.782 139.783 64.4254 139.783Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.4254 29.8826C68.0689 29.8826 71.5631 28.4353 74.1394 25.859C76.7157 23.2827 78.163 19.7885 78.163 16.145C78.163 12.5016 76.7157 9.00741 74.1394 6.43111C71.5631 3.85482 68.0689 2.40747 64.4254 2.40747C60.782 2.40747 57.2878 3.85482 54.7115 6.43111C52.1352 9.00741 50.6879 12.5016 50.6879 16.145C50.6879 19.7885 52.1352 23.2827 54.7115 25.859C57.2878 28.4353 60.782 29.8826 64.4254 29.8826Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.4254 84.8331C68.0689 84.8331 71.5631 83.3857 74.1394 80.8094C76.7157 78.2331 78.163 74.7389 78.163 71.0955C78.163 67.4521 76.7157 63.9578 74.1394 61.3816C71.5631 58.8053 68.0689 57.3579 64.4254 57.3579C60.782 57.3579 57.2878 58.8053 54.7115 61.3816C52.1352 63.9578 50.6879 67.4521 50.6879 71.0955C50.6879 74.7389 52.1352 78.2331 54.7115 80.8094C57.2878 83.3857 60.782 84.8331 64.4254 84.8331Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.507 57.3577C116.15 57.3577 119.645 55.9104 122.221 53.3341C124.797 50.7578 126.244 47.2636 126.244 43.6201C126.244 39.9767 124.797 36.4825 122.221 33.9062C119.645 31.3299 116.15 29.8826 112.507 29.8826C108.863 29.8826 105.369 31.3299 102.793 33.9062C100.217 36.4825 98.7693 39.9767 98.7693 43.6201C98.7693 47.2636 100.217 50.7578 102.793 53.3341C105.369 55.9104 108.863 57.3577 112.507 57.3577Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.507 112.308C116.15 112.308 119.645 110.861 122.221 108.284C124.797 105.708 126.244 102.214 126.244 98.5706C126.244 94.9271 124.797 91.4329 122.221 88.8566C119.645 86.2804 116.15 84.833 112.507 84.833C108.863 84.833 105.369 86.2804 102.793 88.8566C100.217 91.4329 98.7693 94.9271 98.7693 98.5706C98.7693 102.214 100.217 105.708 102.793 108.284C105.369 110.861 108.863 112.308 112.507 112.308Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3439 57.3577C19.9874 57.3577 23.4816 55.9104 26.0579 53.3341C28.6342 50.7578 30.0815 47.2636 30.0815 43.6201C30.0815 39.9767 28.6342 36.4825 26.0579 33.9062C23.4816 31.3299 19.9874 29.8826 16.3439 29.8826C12.7005 29.8826 9.20629 31.3299 6.62999 33.9062C4.0537 36.4825 2.60635 39.9767 2.60635 43.6201C2.60635 47.2636 4.0537 50.7578 6.62999 53.3341C9.20629 55.9104 12.7005 57.3577 16.3439 57.3577Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3439 112.308C19.9874 112.308 23.4816 110.861 26.0579 108.284C28.6342 105.708 30.0815 102.214 30.0815 98.5706C30.0815 94.9271 28.6342 91.4329 26.0579 88.8566C23.4816 86.2804 19.9874 84.833 16.3439 84.833C12.7005 84.833 9.20629 86.2804 6.62999 88.8566C4.0537 91.4329 2.60635 94.9271 2.60635 98.5706C2.60635 102.214 4.0537 105.708 6.62999 108.284C9.20629 110.861 12.7005 112.308 16.3439 112.308Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
