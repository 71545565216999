import * as paths from "./api-paths";

export async function getAllCategories(): Promise<Response> {
  return await fetch(`${paths.symbols.categories}`, {
    method: "GET",
  });
}

export async function getSymbolsByCategory(categoryId: string): Promise<Response> {
  return await fetch(`${paths.symbols.symbolsByCategory(categoryId)}`, {
    method: "GET",
  });
}

export async function getSymbolAudio(symbolId: string): Promise<Response> {
  return await fetch(`${paths.symbols.symbolAudio(symbolId)}`, {
    method: "GET",
  });
}

export async function filterSymbols(query: string): Promise<Response> {
  return await fetch(`${paths.symbols.filterSymbols(query)}`, {
    method: "GET",
  });
}
