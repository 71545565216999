import { login } from "./api-paths";
import { AuthenticationRequest, RegistrationRequest } from "./types";

export async function loginUser(loginData: AuthenticationRequest): Promise<Response> {
  return await fetch(login.login, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(loginData),
  });
}

export async function registerUser(registrationData: RegistrationRequest): Promise<Response> {
  return await fetch(login.register, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(registrationData),
  });
}
