import React, { SVGProps } from "react";
export default function SaveIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.71 5.29002L18.71 0.290017C18.6166 0.197335 18.5057 0.12401 18.3839 0.0742455C18.2621 0.0244809 18.1316 -0.000744179 18 1.67143e-05H2C1.46957 1.67143e-05 0.960859 0.21073 0.585786 0.585803C0.210714 0.960876 0 1.46958 0 2.00002V22C0 22.5305 0.210714 23.0392 0.585786 23.4142C0.960859 23.7893 1.46957 24 2 24H22C22.5304 24 23.0391 23.7893 23.4142 23.4142C23.7893 23.0392 24 22.5305 24 22V6.00002C24.0008 5.86841 23.9755 5.73795 23.9258 5.61611C23.876 5.49427 23.8027 5.38346 23.71 5.29002ZM8 2.00002H16V6.00002H8V2.00002ZM16 22H8V14H16V22ZM18 22V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12H8C7.46957 12 6.96086 12.2107 6.58579 12.5858C6.21071 12.9609 6 13.4696 6 14V22H2V2.00002H6V6.00002C6 6.53045 6.21071 7.03916 6.58579 7.41423C6.96086 7.7893 7.46957 8.00002 8 8.00002H16C16.5304 8.00002 17.0391 7.7893 17.4142 7.41423C17.7893 7.03916 18 6.53045 18 6.00002V2.41002L22 6.41002V22H18Z"
        fill="currentColor"
      />
    </svg>
  );
}
