import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as loginApi from "../../../api/login";
import { AuthenticationRequest, AuthenticationResponse, RegistrationRequest } from "../../../api/types";
import { ErrorResponse } from "../../../store/errorType";
import { fetchWrapper } from "../../../store/storeUtils";

const registerUser = createAsyncThunk(
  "login/registerUser",
  async (registrationData: RegistrationRequest, { rejectWithValue }) => {
    try {
      return await fetchWrapper<AuthenticationResponse>(loginApi.registerUser(registrationData));
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const loginUser = createAsyncThunk("login/loginUser", async (loginData: AuthenticationRequest, { rejectWithValue }) => {
  try {
    return await fetchWrapper<AuthenticationResponse>(loginApi.loginUser(loginData));
  } catch (err) {
    return rejectWithValue(err);
  }
});

type AuthenticationState = {
  authenticationData: AuthenticationResponse;
  loggedIn: boolean;
  registrationStatus: "idle" | "success" | "waiting" | "error";
  loginStatus: "idle" | "success" | "waiting" | "error";
  error?: ErrorResponse;
};

export const authenticationInitialState: AuthenticationState = {
  authenticationData: {
    id: -1,
    firstName: "",
    lastName: "",
    email: "",
    jwt: "",
  },
  loggedIn: false,
  registrationStatus: "idle",
  loginStatus: "idle",
  error: { code: 0, message: "", validations: [] },
};

export const loginSlice = createSlice({
  name: "registrationLogin",
  initialState: authenticationInitialState,
  reducers: {
    resetLoginStatus: (state) => {
      state.loginStatus = "idle";
    },

    resetRegistrationStatus: (state) => {
      state.registrationStatus = "idle";
    },

    resetAuthData: (state) => {
      state.authenticationData = authenticationInitialState.authenticationData;
      state.loggedIn = authenticationInitialState.loggedIn;
      state.registrationStatus = authenticationInitialState.registrationStatus;
      state.loginStatus = authenticationInitialState.loginStatus;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.registrationStatus = "waiting";
    });

    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.registrationStatus = "success";
      state.authenticationData = action.payload;
      state.loggedIn = !!action.payload.jwt;
    });

    builder.addCase(registerUser.rejected, (state, action) => {
      state.registrationStatus = "error";
      state.error = action.payload as ErrorResponse;
    });

    builder.addCase(loginUser.pending, (state) => {
      state.loginStatus = "waiting";
    });

    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.authenticationData = action.payload;
      state.loggedIn = !!action.payload.jwt;
    });

    builder.addCase(loginUser.rejected, (state, action) => {
      state.loginStatus = "error";
      state.error = action.payload as ErrorResponse;
    });
  },
});

export const registrationLogin = loginSlice.reducer;
export const loginAction = {
  loginUser,
  registerUser,
  ...loginSlice.actions,
};
