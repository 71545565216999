import React from "react";

import { UseModal } from "../../../components/Modal";
import { SettingsButton } from "../../Stories/components/HeaderComponents";
import SettingsModal from "../SettingsModal";

export default function SettingsButtonModal({ ...props }: UseModal) {
  return (
    <>
      <SettingsButton onClick={props.onOpen} />
      <SettingsModal {...props} />
    </>
  );
}
