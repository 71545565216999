import { combineReducers } from "@reduxjs/toolkit";

import { emailSender } from "../pages/Email/store/emailSenderSlice";
import { games } from "../pages/Games/store/gamesSlice";
import { registrationLogin } from "../pages/Login/store/loginSlice";
import { settings } from "../pages/Settings/store/settingsSlice";
import { singleStory } from "../pages/Stories/store/singleStorySlice";
import { stories } from "../pages/Stories/store/storiesSlice";
import { symbols } from "../pages/StoryModals/components/store/symbolsSlice";

export const rootReducer = combineReducers({
  stories,
  singleStory,
  registrationLogin,
  settings,
  games,
  symbols,
  emailSender,
});

export type RootState = ReturnType<typeof rootReducer>;
