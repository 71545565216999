import { applyMiddleware, createStore } from "@reduxjs/toolkit";
import * as localforage from "localforage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import { rootReducer } from "./rootReducer";

const persistConfig = {
  key: "root",
  storage: localforage,
  blacklist: ["symbols"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

/*const store = configureStore({
  reducer: rootReducer,
});*/

export type Dispatch = typeof store.dispatch;
