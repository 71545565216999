import { useRef, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { useDispatch } from "react-redux";

import { setPermission } from "../pages/Settings/store/settingsSlice";
import { checkMediaPermission } from "../pages/Settings/util/settingsUtil";

import { useSelector } from "./storeHooks";

type UseRecordAudioProps = {
  setContent: (value: string) => void;
  setFilename?: (value: string) => void;
};

export default function useRecordAudio({ setContent, setFilename }: UseRecordAudioProps) {
  const dispatch = useDispatch();
  const mediaPermission = useSelector((state) => state.settings.mediaPermission);

  const [isRecording, setIsRecording] = useState(false);
  const [stopwatch, setStopwatch] = useState(10);

  const soundRecordingTimeoutRef = useRef({} as NodeJS.Timeout);
  const stopwatchTimeoutRef = useRef({} as NodeJS.Timeout);
  const stopwatchRef = useRef(10);

  const { startRecording, stopRecording } = useReactMediaRecorder({
    audio: true,
    blobPropertyBag: { type: "audio/wav" },
    onStop: (blobUrl, blob) => onStoppedRecording(blob),
  });

  const onStoppedRecording = async (blobProp: Blob) => {
    setIsRecording(false);
    clearTimeout(soundRecordingTimeoutRef.current);
    clearInterval(stopwatchTimeoutRef.current);

    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;

      setFilename?.("Snimka.wav");
      setContent(result);
    };
    reader.readAsDataURL(blobProp);
  };

  const onClickStartRecording = async () => {
    if (!mediaPermission) {
      const mediaAllowed = await checkMediaPermission();
      dispatch(setPermission(mediaAllowed));

      if (!mediaAllowed) return;
    }

    setIsRecording(true);
    startRecording();
    soundRecordingTimeoutRef.current = setTimeout(stopRecording, 10000);
    stopwatchTimeoutRef.current = setInterval(() => {
      stopwatchRef.current = stopwatchRef.current - 1;
      setStopwatch(stopwatchRef.current);
    }, 1000);
    stopwatchRef.current = 10;
    setStopwatch(10);
  };

  return { stopwatch, isRecording, onClickStartRecording, stopRecording };
}
