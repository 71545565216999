import { DialogOverlay, DialogContent } from "@reach/dialog";
import cx from "classnames";
import React, { useState, useCallback } from "react";

import Grid from "./Grid";
import CloseIcon from "./icons/CloseIcon";

export type ModalProps = {
  children: React.ReactNode | (() => React.ReactNode);
  icon?: React.ReactNode;
  id?: string;
  className?: string;
} & UseModal;

type ModalFooterProps = {
  children?: React.ReactNode;
  className?: string;
};

type ModalHeaderProps = {
  children?: React.ReactNode;
  title?: string;
  titleIcon?: React.ReactNode;
  onClose: () => void;
  leftButton?: React.ReactNode;
  variant: "primary" | "secondary";
};

export type UseModal = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  return { isOpen, onOpen, onClose };
}

export function ModalHeader({ title, titleIcon, onClose, variant, children }: ModalHeaderProps) {
  return (
    <div className={`w-full bg-${variant} h-14 pt-4 text-white items-center`}>
      <Grid container spacing={0}>
        <Grid item size={2}>
          {children}
        </Grid>
        <Grid
          item
          size={8}
          className="flex items-center justify-center text-lg text-center leading-6 font-semibold text-white"
          id="modal-headline"
        >
          {titleIcon && <div className="mr-2">{titleIcon}</div>}
          <p className="truncate">{title}</p>
        </Grid>

        <Grid item size={2}>
          <CloseIcon onClick={onClose} className="w-full h-5 flex justify-end items-center cursor-pointer" />
        </Grid>
      </Grid>
    </div>
  );
}

export function ModalFooter({ children, className = "justify-center" }: ModalFooterProps) {
  const modalFooterClassName = cx(className, "flex flex-row m-3");

  return (
    <div className={modalFooterClassName}>
      <div>{children}</div>
    </div>
  );
}

function Modal({ isOpen, onClose, children, id, className }: ModalProps) {
  const contentClassName = cx(
    className,
    "bg-white relative rounded overflow-hidden shadow-xl transition-all max-h-screen overflow-y-visible"
  );

  return (
    <DialogOverlay isOpen={isOpen} onDismiss={onClose} id={`modal-${id}`}>
      <div className="relative z-50">
        <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 flex items-center justify-center ">
          <div className="fixed inset-0 overflow-auto transition-opacity">
            <div className="absolute inset-0 bg-black bg-opacity-60">&nbsp;</div>
          </div>
          <div className="absolute middle">
            <DialogContent aria-label="dialog" className={contentClassName} title="">
              <div className="h-full">{children}</div>
            </DialogContent>
          </div>
        </div>
      </div>
    </DialogOverlay>
  );
}

Modal.Footer = ModalFooter;
export default Modal;
