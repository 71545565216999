import cx from "classnames";
import { useField } from "formik";
import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";

type ToggleButtonProps = {
  name: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export default function ToggleButton({ name, className, ...props }: ToggleButtonProps) {
  const [field] = useField({ name: name, type: "checkbox" });

  const toggleButtonClassName = cx(className, "relative flex items-center rounded justify-end", {
    "bg-primary": field.checked,
    "bg-darkGray": !field.checked,
  });
  const dotClassName = cx("absolute left-0.5 top-0.5 rounded bg-white w-4 h-4", {
    "transform translate-x-full transition": field.checked,
    "transform translate-x-0 transition": !field.checked,
  });

  return (
    <div className="flex items-center justify-center w-full mb-12 cursor-pointer" {...props}>
      <div className={toggleButtonClassName}>
        <input {...field} type="checkbox" className="sr-only" />
        <div className="w-9 h-5">
          <div className={dotClassName} />
        </div>
      </div>
    </div>
  );
}
