import React, { SVGProps } from "react";

export default function PaletteIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M36.4758 9.25489C34.6041 7.44112 32.3801 6.01047 29.9342 5.0469C27.4884 4.08332 24.8699 3.60624 22.2326 3.64364C17.2817 3.63156 12.5287 5.53941 9.01914 8.94747C5.50959 12.3555 3.53099 16.9846 3.51861 21.8165C3.50624 26.6483 5.46109 31.287 8.95314 34.7121C12.4452 38.1373 17.1884 40.0683 22.1393 40.0803C23.2061 40.0981 24.2487 39.7695 25.1035 39.1463C25.9583 38.5231 26.5769 37.6405 26.8621 36.6371C27.0439 35.9124 27.042 35.1555 26.8566 34.4317C26.6712 33.708 26.3079 33.0392 25.798 32.4833C25.6802 32.3524 25.6033 32.1912 25.5764 32.0189C25.5496 31.8465 25.574 31.6703 25.6467 31.5111C25.7194 31.352 25.8374 31.2165 25.9867 31.1208C26.1359 31.025 26.3102 30.9731 26.4887 30.9712H29.5688C32.4678 30.9844 35.2645 29.9268 37.3975 28.0107C39.5304 26.0947 40.8417 23.462 41.0679 20.6414C41.1374 18.5383 40.7659 16.4435 39.9762 14.4855C39.1866 12.5275 37.9954 10.7476 36.4758 9.25489ZM29.6435 27.3275H26.5634C25.6639 27.3225 24.7822 27.5724 24.0253 28.0468C23.2684 28.5212 22.6688 29.1998 22.2993 30.0002C21.9297 30.8006 21.8061 31.6884 21.9434 32.556C22.0806 33.4237 22.4729 34.2338 23.0726 34.8881C23.1894 35.0031 23.2719 35.1469 23.3114 35.3042C23.3508 35.4614 23.3457 35.6262 23.2966 35.7808C23.2033 36.1634 22.7739 36.4002 22.1953 36.4367C20.0753 36.4102 17.9853 35.9434 16.0645 35.0674C14.1437 34.1914 12.436 32.9263 11.0551 31.3563C9.6741 29.7862 8.65152 27.9471 8.05535 25.9614C7.45918 23.9757 7.30307 21.8889 7.59742 19.8398C8.12975 16.3964 9.89267 13.2477 12.5744 10.9505C15.2561 8.65339 18.6838 7.35582 22.2513 7.28731H22.4006C24.5116 7.26034 26.6067 7.64623 28.5624 8.42221C30.5181 9.1982 32.2948 10.3486 33.7877 11.8055C34.9525 12.943 35.8677 14.3002 36.4775 15.7943C37.0873 17.2883 37.3789 18.888 37.3345 20.4956C37.1412 22.3595 36.2491 24.0879 34.8295 25.3489C33.4099 26.6099 31.563 27.3146 29.6435 27.3275Z"
        fill="black"
      />
      <path
        d="M22.4007 14.5749C23.9471 14.5749 25.2008 13.3514 25.2008 11.8421C25.2008 10.3329 23.9471 9.10938 22.4007 9.10938C20.8542 9.10938 19.6006 10.3329 19.6006 11.8421C19.6006 13.3514 20.8542 14.5749 22.4007 14.5749Z"
        fill="black"
      />
      <path
        d="M28.4674 13.1174C27.988 13.3877 27.6014 13.7906 27.3566 14.2753C27.1117 14.76 27.0196 15.3046 27.0919 15.8403C27.1641 16.376 27.3975 16.8789 27.7624 17.2852C28.1274 17.6915 28.6077 17.9831 29.1424 18.1231C29.6772 18.2632 30.2425 18.2453 30.7668 18.0719C31.2912 17.8984 31.7511 17.5771 32.0885 17.1486C32.4258 16.7201 32.6254 16.2036 32.662 15.6645C32.6986 15.1253 32.5706 14.5876 32.2942 14.1194C32.1105 13.8083 31.8657 13.5355 31.5739 13.3167C31.2821 13.0979 30.949 12.9374 30.5936 12.8443C30.2382 12.7513 29.8675 12.7275 29.5026 12.7743C29.1378 12.8212 28.786 12.9378 28.4674 13.1174ZM16.3336 13.1174C15.8539 12.8476 15.303 12.7227 14.7505 12.7585C14.1981 12.7942 13.6688 12.989 13.2298 13.3182C12.7907 13.6474 12.4615 14.0963 12.2838 14.608C12.1061 15.1198 12.0878 15.6715 12.2313 16.1934C12.3747 16.7153 12.6735 17.184 13.0899 17.5402C13.5062 17.8964 14.0214 18.1241 14.5703 18.1946C15.1193 18.2651 15.6773 18.1752 16.1739 17.9363C16.6705 17.6973 17.0834 17.3201 17.3603 16.8522C17.5444 16.5412 17.6639 16.1979 17.7119 15.8418C17.7599 15.4858 17.7355 15.124 17.6402 14.7771C17.5448 14.4303 17.3803 14.1051 17.1562 13.8204C16.932 13.5356 16.6525 13.2967 16.3336 13.1174ZM11.4988 20.5141C11.0395 20.8167 10.6826 21.2456 10.4732 21.7463C10.2638 22.2471 10.2114 22.7972 10.3225 23.327C10.4337 23.8569 10.7034 24.3426 11.0976 24.7228C11.4918 25.1029 11.9927 25.3603 12.5369 25.4625C13.0811 25.5646 13.6441 25.5068 14.1547 25.2965C14.6652 25.0862 15.1003 24.7327 15.4049 24.2809C15.7095 23.8291 15.8699 23.2992 15.8657 22.7584C15.8616 22.2176 15.6931 21.6901 15.3816 21.2428C15.177 20.9447 14.9138 20.6891 14.6073 20.4908C14.3008 20.2926 13.9571 20.1556 13.596 20.0878C13.2349 20.0201 12.8636 20.0228 12.5037 20.096C12.1437 20.1692 11.8021 20.3113 11.4988 20.5141Z"
        fill="black"
      />
    </svg>
  );
}
