import React, { SVGProps } from "react";
export default function PencilIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.3867 5.22006L27.28 11.1134L21.3867 5.22006ZM23.8867 2.72006C24.6682 1.93856 25.7281 1.49951 26.8333 1.49951C27.9385 1.49951 28.9985 1.93856 29.78 2.72006C30.5615 3.50157 31.0005 4.56151 31.0005 5.66673C31.0005 6.77194 30.5615 7.83189 29.78 8.61339L6.83333 31.5601H1V25.6067L23.8867 2.72006V2.72006Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
