import React, { useState } from "react";

import Button from "../../../components/Button";
import { downloadContentJson } from "../../../components/DownloadContentFunction";
import Modal, { ModalFooter, ModalHeader, UseModal } from "../../../components/Modal";
import DownloadIcon from "../../../components/icons/DownloadIcon";
import HelpIcon from "../../../components/icons/HelpIcon";
import { useSelector } from "../../../hooks/storeHooks";

type SaveStoryModalInfo = UseModal;

export default function SaveStoryModalInfo({ ...props }: SaveStoryModalInfo) {
  const settings = useSelector((state) => state.settings.settings);
  const localStories = useSelector((state) => state.stories.localStories);

  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const { isOpen, onOpen, onClose } = props;

  const text =
    "Promjene pohranjene lokalno. Kako ne biste izgubili promjene, izvezite svoje priče. Sadržaj možete izvesti kasnije u bočnom izborniku.";

  return (
    <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose} className="font-semibold w-2/3 md:3/5 lg:w-2/3 mx-auto">
      <ModalHeader
        onClose={onClose}
        variant="primary"
        title={`Info - spremanje priča`}
        titleIcon={<HelpIcon width={20} height={20} />}
      />
      <div className="flex flex-col">
        <div className="px-10">
          <div className="my-2 flex justify-center w-full text-center text-lg font-normal">{text}</div>
        </div>
      </div>
      <ModalFooter>
        <div className="flex flex-row">
          <Button variant="primary" type="button" className="font-semibold w-44 h-10 mr-6" onClick={onClose}>
            U redu
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="font-semibold w-44 h-10"
            onClick={() => {
              downloadContentJson({ settings, localStories, setConfirmMessage });
              onClose();
            }}
          >
            <DownloadIcon width={20} height={20} className="mr-2" />
            Izvezi sadržaj
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
