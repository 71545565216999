import React, { useState } from "react";

import IconButton from "../../../components/componentsImpl/IconButton";
import CloudUploadIcon from "../../../components/icons/CloudUploadIcon";
import MicrophoneIcon from "../../../components/icons/MicrophoneIcon";
import PauseIcon from "../../../components/icons/PauseIcon";
import PlayIcon from "../../../components/icons/PlayIcon";
import RecordIcon from "../../../components/icons/RecordIcon";
import LabeledButton from "../../StoryModals/components/LabeledButton";

export default function AudioButtons() {
  const [isRecording, setIsRecording] = useState(false);
  const [playAudio, setPlayAudio] = useState(false);

  return (
    <div className="flex flex-col gap-y-6 justify-between mt-4">
      <LabeledButton
        subtitle="Snimaj"
        subtitleAside={true}
        button={
          <IconButton
            onClick={() => {
              setIsRecording(!isRecording);
              setPlayAudio(false);
            }}
            className={`w-12 h-12 bg-${isRecording ? "error" : "primary"}`}
            icon={isRecording ? <RecordIcon width={18} height={18} /> : <MicrophoneIcon width={20} height={27} />}
          />
        }
      />
      <LabeledButton
        subtitle="Učitaj"
        subtitleAside={true}
        button={<IconButton className="bg-secondary w-12 h-12" icon={<CloudUploadIcon width={26} height={36} />} />}
      />
      <LabeledButton
        subtitle="Preslušaj"
        subtitleAside={true}
        button={
          <IconButton
            className="bg-secondary w-12 h-12"
            onClick={isRecording ? undefined : () => setPlayAudio(!playAudio)}
            icon={!playAudio ? <PlayIcon width={20} height={30} /> : <PauseIcon width={20} height={30} />}
          />
        }
      />
    </div>
  );
}
