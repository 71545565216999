import React from "react";

import { StorySymbol } from "../../../api/types";
import { useSelector } from "../../../hooks/storeHooks";

import SymbolCard from "./SymbolCard";

type SymbolListProps = {
  categoryId: number;
  selectedSymbol: StorySymbol;
  setSelectedSymbol: (symbol: StorySymbol) => void;
  modalClose: () => void;
};

export default function SymbolList({ categoryId, selectedSymbol, setSelectedSymbol }: SymbolListProps) {
  const category = useSelector((state) => state.symbols.categories.find((category) => category.id == categoryId));

  const symbolsStatus = useSelector((state) => state.symbols.symbolsStatus);
  return (
    <div className="divide-y divide-gray">
      {symbolsStatus == "waiting"
        ? "Učitavanje"
        : category?.symbols?.map((symbol: StorySymbol) => (
            <SymbolCard
              key={symbol.id}
              symbol={symbol}
              selected={symbol.id == selectedSymbol.id}
              setSelectedSymbol={setSelectedSymbol}
            />
          ))}
    </div>
  );
}
