import React from "react";

import { UseModal } from "../../../components/Modal";
import { InfoButton } from "../../Stories/components/HeaderComponents";

import GameRulesModal from "./GameRulesModal";

type GameRulesButtonModalProps = {
  game: "Slagalica" | "Spajalica" | "Izbaci Uljeza" | "Pitalica";
} & UseModal;

export default function GameRulesButtonModal({ game, ...props }: GameRulesButtonModalProps) {
  return (
    <>
      <InfoButton className="mr-2" onClick={props.onOpen} />
      <GameRulesModal game={game} {...props} />
    </>
  );
}
