import React from "react";

import Modal, { ModalHeader, UseModal } from "../../../components/Modal";
import HelpIcon from "../../../components/icons/HelpIcon";
import { tutorialElements, tutorialTexts, tutorialTitles } from "../assets/tutorialModalElements";

import TutorialInfoCont from "./TutorialInfoCont";

export default function TutorialModal({ ...props }: UseModal) {
  const { onClose } = props;

  return (
    <Modal {...props} className="font-semibold w-5/6 md:w-full mx-auto">
      <ModalHeader
        onClose={onClose}
        variant="primary"
        title="Upute za korištenje"
        titleIcon={<HelpIcon width={20} height={20} />}
      />
      <div className="flex flex-col">
        <div className="px-8 py-4">
          <div className="flex justify-center w-full text-start text-lg font-normal p-6 pt-4">
            <TutorialInfoCont elements={tutorialElements} texts={tutorialTexts} titles={tutorialTitles} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
