import React from "react";

import { CardComponent } from "../../../components/CardComponent";
import Sidebar from "../../../components/Sidebar";
import GamesIcon from "../../../components/icons/GamesIcon";
import AudioButtons from "../components/AudioButtons";
import { InfoButton } from "../components/HeaderComponents";
import ImageButtons from "../components/ImageButtons";
import SidebarItems from "../components/SidebarItems";

export const tutorialElements = [
  <CardComponent key={1} variant="add" className="bg-secondary ml-6 lg:ml-4" />,
  <ImageButtons key={2} />,
  <AudioButtons key={3} />,
  <div key={4} className="flex flex-col gap-y-12">
    <Sidebar.Item className="bg-primary" icon={<GamesIcon width={30} height={30} />} text="Igre" />
    <div className="flex flex-row items-center">
      <InfoButton />
      <p className="ml-3 text-sm">Upute za igranje igre</p>
    </div>
  </div>,
  <SidebarItems key={5} />,
];

export const tutorialTexts = [
  <div key={1} className="text-base">
    <p className="mb-4">
      Odabirom opcije <i>Dodaj priču</i> otvara se prozor za kreiranje nove priče.
    </p>
    Svaka stranica u priči sastoji se od slike, teksta i zvuka.
  </div>,
  <div key={2} className="text-base">
    <p className="mb-4">Sliku je moguće učitati s uređaja, snimiti kamerom ili odabrati neki od ponuđenih simbola.</p>
    Za snimanje kamerom potrebno je dozvoliti aplikaciji korištenje kamere.
  </div>,
  <div key={3} className="text-base">
    <p className="mb-4">Zvuk je moguće snimiti mikrofonom ili učitati s uređaja.</p>
    <p className="mb-4">
      Učitanu ili snimljenu datoteku, moguće je preslušati odabirom opcije <i>Preslušaj</i>.
    </p>
    <p>Za snimanje zvuka mikrofonom, potrebno je aplikaciji dozvoliti korištenje mikrofona.</p>
  </div>,
  <div key={4} className="text-base">
    <p className="mb-4">Na temelju kreiranih priča, generiraju se različite igre.</p>
    <p className="mb-4">Za igranje igre potrebno je odabrati željenu priču.</p>
    <p>Na ekranu za igranje pojedine igre nalaze se upute za igranje igre.</p>
  </div>,
  <div key={5} className="text-base">
    <p className="mb-4">Kreirane priče i korisničke postavke potrebno je izvesti na uređaj kako se ne bi izbrisale.</p>
    <p>Pri ponovnom korištenju aplikacije moguće je uvesti prethodno izvezene podatke.</p>
  </div>,
];

export const tutorialTitles = ["Kreiranje priče", "Slike", "Zvuk", "Igre", "Uvezi/Izvezi"];
