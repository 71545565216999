import React, { DetailedHTMLProps, HTMLAttributes } from "react";

import Button from "../Button";
import ReturnArrowIcon from "../icons/ReturnArrowIcon";

type ReturnButtonProps = { variant: "primary" | "secondary"; text: string } & DetailedHTMLProps<
  HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export default function ReturnButton({ text, ...props }: ReturnButtonProps) {
  return (
    <Button className="flex flex-row cursor-pointer" {...props}>
      <ReturnArrowIcon className="cursor-pointer mt-0.5" width={16} />
      <div className="ml-5  pr-1.5 font-semibold cursor-pointer">{text}</div>
    </Button>
  );
}
