import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { PageRequestData } from "../../api/types";
import PageModal from "../../pages/StoryModals/components/PageModal";
import Grid from "../Grid";
import ImageContainer from "../ImageContainer";
import { useModal } from "../Modal";
import BurgerMenuIcon from "../icons/BurgerMenuIcon";
import PencilIcon from "../icons/PencilIcon";
import TrashIcon from "../icons/TrashIcon";

type PageRowProps = {
  pageNumber: number;
  image: string;
  text: string;
  audio?: string;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  pageList: PageRequestData[];
  setPageList: (pageList: PageRequestData[]) => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function PageRow({
  pageNumber,
  image,
  text,
  audio,
  dragHandleProps,
  className,
  pageList,
  setPageList,
  ...props
}: PageRowProps) {
  const pageModal = useModal();
  const pageRowClassName = cx(className, "flex flex-row justify-between items-center font-semibold py-5 px-2");
  const itemClassName = cx("flex flex-nowrap items-center ");
  const iconClassName = cx("w-6 h-auto cursor-pointer ");
  const removePage = () => {
    if (pageNumber - 1 < pageList.length) {
      const tmpPageList = pageList;
      tmpPageList.splice(pageNumber - 1, 1);
      setPageList([...tmpPageList]);
    }
  };
  return (
    <div className={pageRowClassName} {...props}>
      <Grid container className="flex-grow flex-nowrap items-center justify-between">
        <Grid item className={itemClassName + "justify-start"}>
          <p>{pageNumber}</p>
          <div>
            <ImageContainer
              className="w-20 h-20 mx-4 rounded rounded-small"
              image={image}
              imageClassName="h-20 object-cover"
            />
          </div>
          <p className="truncate">{text}</p>
        </Grid>
        <Grid item className={itemClassName + "justify-end"}>
          <PencilIcon className="w-6 h-auto cursor-pointer" onClick={pageModal.onOpen} />
          {pageModal.isOpen && (
            <PageModal
              variant="edit"
              initialState={{ image, text, audio } as PageRequestData}
              pageNumber={pageNumber}
              modal={pageModal}
              pageList={pageList}
              setPageList={setPageList}
            />
          )}
          <TrashIcon className={iconClassName + "mx-6"} onClick={removePage} />
          <div {...dragHandleProps}>
            <BurgerMenuIcon className={iconClassName} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
