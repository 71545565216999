import { AnyAction, unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { SingleStoryResponse, StoryCard, StoryData, StoryNoPages } from "../../../api/types";
import { serializeQueryProps } from "../../../store/storeUtils";
import { makeToast } from "../../../util/makeToast";
import { singleStoryAction } from "../../Stories/store/singleStorySlice";
import { LocalStory, storiesAction } from "../../Stories/store/storiesSlice";

import { randomIntFromInterval } from "./randomNumberGenerator";

export default function SelectAnotherStory() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectAnotherStory = async (
    selectedStoryId: number,
    type: "default" | "local",
    defaultUserStories: StoryCard[],
    localStories: LocalStory[],
    jwt?: string
  ) => {
    const storiesNoPages = (await dispatch(storiesAction.getNoStoryPages() as unknown as AnyAction).then(
      unwrapResult
    )) as StoryNoPages[];

    const filteredStoriesId = storiesNoPages.filter((story) => story.noPages >= 2).map((story) => story.id);

    const defaultUserStoriesFiltered = defaultUserStories.filter((story) => filteredStoriesId.includes(story.id));
    const localStoriesFiltered = localStories.filter((story) => story.pages.length >= 2);

    if (defaultUserStoriesFiltered.length == 0 && localStoriesFiltered.length == 0) {
      makeToast("Nemoguće igrati. Ne postoji niti jedna druga priča s više od jedne stranice.");
      history.push("/games");
    }

    let randomNumber;

    if (type === "default") {
      randomNumber = randomIntFromInterval(
        0,
        defaultUserStoriesFiltered.length + localStoriesFiltered.length - 1,
        defaultUserStoriesFiltered.map((story) => story.id).indexOf(selectedStoryId)
      );
    } else {
      randomNumber = randomIntFromInterval(
        0,
        defaultUserStoriesFiltered.length + localStoriesFiltered.length - 1,
        defaultUserStoriesFiltered.length + localStoriesFiltered.map((story) => story.id).indexOf(selectedStoryId)
      );
    }
    let anotherStory: StoryData;

    if (randomNumber <= defaultUserStoriesFiltered.length - 1) {
      const story = (await dispatch(
        singleStoryAction.getSingleStory({
          storyId: defaultUserStoriesFiltered[randomNumber].id.toString(),
          queryParams: serializeQueryProps(),
          jwt: jwt,
        }) as unknown as AnyAction
      ).then(unwrapResult)) as SingleStoryResponse;
      anotherStory = { id: story.id, title: story.title, pages: story.pages.content };
    } else {
      const actualIndex = randomNumber - defaultUserStoriesFiltered.length;
      const localStory = localStoriesFiltered[actualIndex];
      anotherStory = { id: localStory.id, title: localStory.title, pages: localStory.pages };
    }

    return anotherStory;
  };

  return { selectAnotherStory };
}
