import React, { SVGProps } from "react";

export default function TextIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M24.6217 14H11.3783L11.25 18.5H12.375C12.78 16.034 13.176 15.818 16.1865 15.7212L16.8457 15.6988V27.1513C16.8457 28.2088 16.6208 28.4607 14.8253 28.625V29.75H21.1725V28.625C19.3657 28.4607 19.1407 28.211 19.1407 27.1535V15.6988L19.8113 15.7212C22.8218 15.818 23.2177 16.0362 23.6227 18.5H24.7477L24.6195 14H24.6217Z"
          fill="black"
        />
        <path
          d="M31.5 10.625V32C31.5 33.1935 31.0259 34.3381 30.182 35.182C29.3381 36.0259 28.1935 36.5 27 36.5H9C7.80653 36.5 6.66193 36.0259 5.81802 35.182C4.97411 34.3381 4.5 33.1935 4.5 32V5C4.5 3.80653 4.97411 2.66193 5.81802 1.81802C6.66193 0.974106 7.80653 0.5 9 0.5L21.375 0.5L31.5 10.625ZM24.75 10.625C23.8549 10.625 22.9964 10.2694 22.3635 9.63649C21.7306 9.00355 21.375 8.14511 21.375 7.25V2.75H9C8.40326 2.75 7.83097 2.98705 7.40901 3.40901C6.98705 3.83097 6.75 4.40326 6.75 5V32C6.75 32.5967 6.98705 33.169 7.40901 33.591C7.83097 34.0129 8.40326 34.25 9 34.25H27C27.5967 34.25 28.169 34.0129 28.591 33.591C29.0129 33.169 29.25 32.5967 29.25 32V10.625H24.75Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="36" height="36" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
