import cx from "classnames";
import { useField } from "formik";
import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";

type FramedIconButtonProps = {
  name: string;
  value: number;
  text: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export default function FramedIconButton({ name, value, text, children, className, ...props }: FramedIconButtonProps) {
  const [field] = useField({ name: name, type: "radio", value: value });

  const frameIconClassName = cx("flex flex-col items-center justify-center", {
    "text-primary border-primary": field.checked,
    "text-secondary border-secondary": !field.checked,
  });
  const framedIconButtonClassName = cx(
    className,
    "flex items-center justify-center border rounded rounded-small w-14 h-14 mx-1 cursor-pointer"
  );

  return (
    <div className={frameIconClassName}>
      <input {...field} type="radio" value={value} className="sr-only" />
      <div className={framedIconButtonClassName} {...props}>
        {children}
      </div>
      <p className="font-light">{text}</p>
    </div>
  );
}
