import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageResponseData } from "../../api/types";
import { Header } from "../../components/Header";
import ImageContainer from "../../components/ImageContainer";
import { useModal } from "../../components/Modal";
import PageCont from "../../components/PageCont";
import PageContHeader from "../../components/PageContHeader";
import StoryImage from "../../components/StoryImage";
import ReturnButton from "../../components/componentsImpl/ReturnButton";
import { useSelector } from "../../hooks/storeHooks";
import { playAudio } from "../../util/playAudioFunction";
import SettingsButtonModal from "../Settings/components/SettingsButtonModal";
import { LocalStory } from "../Stories/store/storiesSlice";

import { soundCorrect } from "./assets/soundCorrect";
import { soundWrong } from "./assets/soundWrong";
import GameRulesButtonModal from "./components/GameRulesButtonModal";
import { gamesAction } from "./store/gamesSlice";
import PlayOddOneOutGame from "./util/playOddOneOutGame";
import PlayOddOneOutGameLocalStory from "./util/playOddOneOutGameLocalStory";
import { shuffle } from "./util/shuffleFunction";

export default function OddOneOut() {
  const history = useHistory();
  const dispatch = useDispatch();

  const modal = useModal();
  const gameRulesModal = useModal();
  const audioRef = useRef(new Audio());

  const stories = useSelector((state) => state.stories.stories.content);
  const localStories = useSelector((state) => state.stories.localStories);
  const selectedStory = useSelector((state) => state.games.oddOneSelectedStory);
  const anotherStory = useSelector((state) => state.games.oddOneAnotherStory);
  const jwt = useSelector((state) => state.registrationLogin.authenticationData.jwt);
  const { bgColor, textColor, audioOn, uppercase } = useSelector((state) => state.settings.settings);

  const [pages, setPages] = useState<[]>([]);
  const [playing, setPlaying] = useState<boolean>(false);
  const [correctPages, setCorrectPages] = useState<PageResponseData[]>([]);
  const [lastWrongPage, setLastWrongPage] = useState<PageResponseData>();

  const { playOddOneOut } = PlayOddOneOutGame();
  const { playOddOneOutLocal } = PlayOddOneOutGameLocalStory();

  useEffect(() => {
    const storyPages = shuffle(Object.assign([], selectedStory.pages));
    const anotherStoryPages = shuffle(Object.assign([], anotherStory.pages));
    const pages = shuffle(storyPages.slice(0, 4).concat(anotherStoryPages.slice(0, 2)));
    setPages(pages);
  }, [setPages, selectedStory, anotherStory]);

  useEffect(() => {
    const id = window.location.href.split("/").pop() ?? "-1";

    if (selectedStory == undefined || selectedStory.id.toString() != id) {
      dispatch(gamesAction.resetOddOneStory());
      dispatch(gamesAction.resetOddOneSelectedStory());
      if (localStories.map((story) => story.id.toString()).includes(id)) {
        const localStory = localStories.find((story) => story.id.toString() == id) ?? ({} as LocalStory);
        playOddOneOutLocal(localStory, stories, localStories, jwt);
      } else {
        playOddOneOut(Number(id), stories, localStories, jwt);
      }
    }
  }, [dispatch, jwt]);

  const onClickRedirect = () => {
    history.push("/games/izbaci_uljeza");
  };

  const getAnswerStatus = (pageId: number) => {
    if (correctPages.map((page) => page.id).includes(pageId)) {
      return "correct";
    } else if (lastWrongPage?.id == pageId) {
      return "wrong";
    }
  };

  const checkAnswer = (pageId: number, page: PageResponseData) => {
    return !correctPages.map((page) => page.id).includes(pageId) && correctPages.length < 2
      ? () => {
          if (page.storyId != selectedStory.id) {
            playAudio(soundCorrect, playing, setPlaying, audioRef, audioOn, pageId);
            setCorrectPages(correctPages.concat(page));
            setLastWrongPage(undefined);
          } else {
            playAudio(soundWrong, playing, setPlaying, audioRef, audioOn, pageId);
            setLastWrongPage(page);
          }
        }
      : undefined;
  };

  const storyImageClassName = cx(`text-${textColor} storyImageSmall`, {
    uppercase: uppercase,
  });

  useEffect(() => {
    if (correctPages.length === 2) setTimeout(() => history.push("/games/endgame"), 2000);
  }, [correctPages]);

  return (
    <PageCont>
      <div className={`flex flex-col w-screen h-screen overflow-x-hidden items-center bg-${bgColor}`}>
        <Header />
        <PageContHeader
          title={`Izbaci uljeza: ${selectedStory.title}`}
          titleClassName="mr-36 text-center"
          isGameOrStory={true}
          rightButtons={[
            <GameRulesButtonModal game="Izbaci Uljeza" key={1} {...gameRulesModal} />,
            <SettingsButtonModal key={2} {...modal} />,
          ]}
          leftButtons={<ReturnButton variant="secondary" text="Odaberi priču" onClick={onClickRedirect} />}
          className="w-full items-center mt-8 pr-8 pl-28"
        />
        <div className="inline-block flex justify-between items-center">
          <div className="grid gap-y-20 lg:gap-x-20 sm:gap-x-10 lg:grid-cols-3 lg:grid-rows-2 md:grid-cols-2 md:grid-rows-3">
            {pages.map((page: PageResponseData) => (
              <StoryImage key={page.id} text={page.text} className={storyImageClassName}>
                <ImageContainer
                  image={page.image}
                  imageAudio={true}
                  imageClassName="object-contain imageContainerSmall"
                  answer={getAnswerStatus(page.id)}
                  onClick={checkAnswer(page.id, page)}
                />
              </StoryImage>
            ))}
          </div>
        </div>
      </div>
    </PageCont>
  );
}
