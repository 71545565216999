import cx from "classnames";
import React from "react";

type FieldProps = {
  label: string;
  children: React.ReactNode;
  error?: string;
  className?: string;
  labelClassName?: string;
};

export default function Field({ className, label, error, children, labelClassName }: FieldProps) {
  const fieldClassName = cx(className, "flex flex-col justify-start");
  const finalLabelClassName = cx(labelClassName, "mb-2 mt-4 text-sm");

  return (
    <div className={fieldClassName}>
      <p className={finalLabelClassName}>{label}</p>
      {children}
      {error && <p className="text-error">{error}</p>}
    </div>
  );
}
