import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { store } from "../../../store/store";
import { serializeQueryProps } from "../../../store/storeUtils";
import { makeToast } from "../../../util/makeToast";
import { singleStoryAction } from "../../Stories/store/singleStorySlice";

export default function PlayConnectGame() {
  const dispatch = useDispatch();
  const history = useHistory();

  const playConnect = async (id: number, jwt: string) => {
    await dispatch(
      singleStoryAction.getSingleStory({
        storyId: id.toString(),
        queryParams: serializeQueryProps(),
        jwt: jwt,
      })
    );

    const selectedStory = store.getState().singleStory.story;

    if (selectedStory.pages.content.length < 2) {
      makeToast('Nemoguće igrati. Za igrati igru "Spajalica" potrebne su minimalno dvije slike u priči.');
      return;
    }

    history.push(`/games/spajalica/${id}`);
  };

  return { playConnect };
}
