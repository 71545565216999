import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";

import ArrowButton from "../../../components/componentsImpl/ArrowButton";

type CategoryCollapsibleProps = {
  name: string;
  children: React.ReactNode;
  onOpen: () => void;
  open?: boolean;
};

export default function CategoryCollapsible({ name, children, onOpen, open }: CategoryCollapsibleProps) {
  const [collapsed, setCollapsed] = useState(open);
  const [openVar, setOpenVar] = useState(open);

  useEffect(() => {
    setCollapsed(open);
    setOpenVar(open);
  }, [open, setCollapsed, setOpenVar]);

  const onOpenFunction = () => {
    onOpen();
    setOpenVar(true);
    setCollapsed(true);
  };

  const onClosingFunction = () => {
    setCollapsed(false);
    setOpenVar(false);
  };

  return (
    <Collapsible
      trigger={<CategoryCard name={name} collapsed={collapsed ?? false} />}
      onOpening={onOpenFunction}
      onClosing={onClosingFunction}
      transitionTime={100}
      open={openVar}
    >
      {children}
    </Collapsible>
  );
}

type CategoryCardProps = {
  name: string;
  collapsed: boolean;
};

function CategoryCard({ name, collapsed }: CategoryCardProps) {
  return (
    <div className="flex flex-row items-center">
      <ArrowButton type={collapsed ? "down" : "right"} className="w-3 ml-2" />
      <span className="px-2">{name}</span>
    </div>
  );
}
