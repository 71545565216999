import React from "react";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import Routes from "./Routes";
import { store, persistor } from "./store/store";

// window.addEventListener("beforeunload", (ev) => {
//   ev.preventDefault();
//   return (ev.returnValue = "Test");
// });

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="e-galerija">
          <Routes />
          <Toaster position={"top-center"} />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
