import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { StoryCard, StoryData } from "../../../api/types";
import { makeToast } from "../../../util/makeToast";
import { LocalStory } from "../../Stories/store/storiesSlice";
import { gamesAction } from "../store/gamesSlice";

import SelectAnotherStory from "./oddOneOutSelectAnotherStory";

export default function PlayOddOneOutGameLocalStory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectAnotherStory } = SelectAnotherStory();

  const playOddOneOutLocal = async (
    card: LocalStory,
    stories: StoryCard[],
    localStories: LocalStory[],
    jwt: string
  ) => {
    const selectedStory: StoryData = { id: card.id, title: card.title, pages: card.pages };

    const anotherStory = await selectAnotherStory(card.id, "local", stories, localStories, jwt);

    dispatch(gamesAction.putOddOneSelectedStory(selectedStory));
    dispatch(gamesAction.putOddOneStory(anotherStory));

    if (selectedStory.pages.length < 4) {
      makeToast("S odabranom pričom nije moguće igrati igru Izbaci uljeza jer ima manje od četiri slike u priči.");
    } else {
      history.push(`/games/izbaci_uljeza/${card.id}`);
    }
  };

  return { playOddOneOutLocal };
}
