import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { serializeQueryProps } from "../../../store/storeUtils";
import { singleStoryAction } from "../../Stories/store/singleStorySlice";
import { gamesAction } from "../store/gamesSlice";

export default function PlayYesNoGame() {
  const dispatch = useDispatch();
  const history = useHistory();

  const playYesNo = async (id: number) => {
    await dispatch(
      singleStoryAction.getSingleStory({
        storyId: id.toString(),
        queryParams: serializeQueryProps(),
      })
    );

    await dispatch(
      gamesAction.getSingleYesNoGame({
        storyId: id.toString(),
        queryParams: serializeQueryProps(),
      })
    );

    history.push(`/games/pitalica/${id}`);
  };
  return { playYesNo };
}
