import { AnyAction, unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Category, StorySymbol } from "../../../api/types";
import Button from "../../../components/Button";
import FormLayout from "../../../components/FormLayout";
import Input from "../../../components/Input";
import Modal, { ModalFooter, ModalHeader, UseModal } from "../../../components/Modal";
import { useSelector } from "../../../hooks/storeHooks";

import CategoryCollapsible from "./CategoryCollapsible";
import SymbolList from "./SymbolList";
import { symbolsAction } from "./store/symbolsSlice";

type SymbolModalProps = {
  modal: UseModal;
  setImagePreview: (image: string) => void;
  setImageFilename: (imageName: string) => void;
  setTextPreview: (text: string) => void;
  setAudioPreview: (audio: string) => void;
  setSoundFilename: (soundName: string) => void;
};

type QueryFormProps = {
  query: string;
};
export default function SymbolModal({
  modal,
  setImagePreview,
  setTextPreview,
  setAudioPreview,
  setImageFilename,
  setSoundFilename,
}: SymbolModalProps) {
  const dispatch = useDispatch();

  const [categoriesOpen, setCategoriesOpen] = useState(false);

  const [selectedSymbol, setSelectedSymbol] = useState({} as StorySymbol);

  const categories = useSelector((state) => state.symbols.categories);

  useEffect(() => {
    dispatch(symbolsAction.getAllCategories());
  }, [dispatch]);

  const onOpen = async (category: Category) => {
    const length = category.symbols?.length || 0;
    if (length == 0) {
      await dispatch(symbolsAction.getSymbolsByCategory(category?.id || -1));
    }
  };

  const filterSymbols = async (query: string) => {
    setCategoriesOpen(false);
    await dispatch(symbolsAction.filterSymbols(query));
    setCategoriesOpen(true);
  };

  const selectSymbol = () => {
    if (selectedSymbol.id === undefined) {
      modal.onClose();
    } else {
      dispatch(symbolsAction.getSymbolAudio(selectedSymbol.id) as unknown as AnyAction)
        .then(unwrapResult)
        .then((result: { audio: string }) => {
          if (result) {
            setAudioPreview("data:audio/wav;base64," + result.audio);
          }
        });

      setImagePreview(selectedSymbol.image);
      setTextPreview(selectedSymbol.name);
      setImageFilename(selectedSymbol.name.replaceAll(/\s/g, "_") + ".jpg");
      setSoundFilename(selectedSymbol.name.replaceAll(/\s/g, "_") + ".wav");

      modal.onClose();
    }
  };

  return (
    <Modal {...modal} className="w-590px h-670px overflow-hidden">
      <ModalHeader onClose={modal.onClose} variant="primary" title="Odabir simbola" />
      <FormLayout initialValues={{ query: "" } as QueryFormProps} onSubmit={(values) => filterSymbols(values.query)}>
        <div className="flex flex-row items-center pb-2">
          <div className="w-7/12 ml-2">
            <Input label="" name="query" textArea={false} maxLength={40} onSubmit={() => console.log("Submit!")} />
          </div>
          <Button variant="primary" type="submit" className="h-9 mt-4 ml-2">
            Pretraži
          </Button>
          <Button
            variant="primary"
            className="h-9 mt-4 ml-2"
            type="reset"
            onClick={async () => {
              await dispatch(symbolsAction.resetSymbolCategories());
              await dispatch(symbolsAction.getAllCategories());
              setCategoriesOpen(false);
            }}
          >
            Resetiraj
          </Button>
        </div>
      </FormLayout>
      <div className="flex flex-col h-27rem overflow-y-auto">
        {categories.map((category: Category) => (
          <CategoryCollapsible
            key={category.id}
            name={category.name}
            onOpen={() => onOpen(category)}
            open={categoriesOpen}
          >
            <SymbolList
              categoryId={category.id}
              modalClose={modal.onClose}
              selectedSymbol={selectedSymbol}
              setSelectedSymbol={setSelectedSymbol}
            />
          </CategoryCollapsible>
        ))}
      </div>
      <ModalFooter>
        <Button variant="primary" type="button" className="font-semibold w-40" onClick={selectSymbol}>
          Odaberi
        </Button>
      </ModalFooter>
    </Modal>
  );
}
