import React, { SVGProps } from "react";
export default function EyeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13 12.5999C13.6896 12.5999 14.3509 12.326 14.8385 11.8384C15.3261 11.3508 15.6 10.6895 15.6 9.9999C15.6 9.31034 15.3261 8.64902 14.8385 8.16143C14.3509 7.67383 13.6896 7.3999 13 7.3999C12.3105 7.3999 11.6491 7.67383 11.1615 8.16143C10.674 8.64902 10.4 9.31034 10.4 9.9999C10.4 10.6895 10.674 11.3508 11.1615 11.8384C11.6491 12.326 12.3105 12.5999 13 12.5999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.595459 9.9999C2.25166 4.7258 7.17866 0.899902 13.0001 0.899902C18.8215 0.899902 23.7485 4.7258 25.4047 9.9999C23.7485 15.274 18.8215 19.0999 13.0001 19.0999C7.17866 19.0999 2.25166 15.274 0.595459 9.9999ZM18.2001 9.9999C18.2001 11.379 17.6522 12.7017 16.677 13.6769C15.7018 14.652 14.3792 15.1999 13.0001 15.1999C11.6209 15.1999 10.2983 14.652 9.3231 13.6769C8.34791 12.7017 7.80006 11.379 7.80006 9.9999C7.80006 8.62078 8.34791 7.29814 9.3231 6.32295C10.2983 5.34776 11.6209 4.7999 13.0001 4.7999C14.3792 4.7999 15.7018 5.34776 16.677 6.32295C17.6522 7.29814 18.2001 8.62078 18.2001 9.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}
