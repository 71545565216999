import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";

import HeartIcon from "./icons/HearIcon";

type CardComponentContProps = {
  variant: "favorite" | "rest" | "none";
  children: React.ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function CardComponentCont({ variant, children, className, ...props }: CardComponentContProps) {
  const cardsContainerClassName = cx(className, "flex flex-col");
  const divTypeClassName = cx("ml-16 sm:ml-8 md:ml-12 lg:ml-16 flex flex-row", {
    "mt-12": variant === "rest",
  });

  return (
    <div className={cardsContainerClassName} {...props}>
      <div className={divTypeClassName}>
        {variant === "favorite" && <HeartIcon width={22} height={20} className="mt-1.5 mr-2" />}
        {variant !== "none" && (
          <div className="font-semibold text-2xl">{variant === "favorite" ? "Favoriti" : "Ostale"}</div>
        )}
      </div>
      <div className="flex flex-row flex-wrap justify-start gap-y-6 mt-3">{children}</div>
    </div>
  );
}
