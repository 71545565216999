import cx from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Simulate } from "react-dom/test-utils";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageResponseData } from "../../api/types";
import GameImageContainer from "../../components/GameImageContainer";
import { Header } from "../../components/Header";
import { useModal } from "../../components/Modal";
import PageCont from "../../components/PageCont";
import PageContHeader from "../../components/PageContHeader";
import ProgressButtons from "../../components/ProgressButtons";
import StoryImage from "../../components/StoryImage";
import ArrowButton from "../../components/componentsImpl/ArrowButton";
import ReturnButton from "../../components/componentsImpl/ReturnButton";
import { useSelector } from "../../hooks/storeHooks";
import SettingsButtonModal from "../Settings/components/SettingsButtonModal";
import { singleStoryAction } from "../Stories/store/singleStorySlice";
import { LocalStory } from "../Stories/store/storiesSlice";

import GameRulesButtonModal from "./components/GameRulesButtonModal";
import { ScrollCard } from "./components/ScrollCard";
import useDrag from "./components/useDrag";
import PlaySlagalicaGame from "./util/playSlagalicaGame";
import PlaySlagalicaGameLocalStory from "./util/playSlagalicaGameLocalStory";
import { shuffle } from "./util/shuffleFunction";

import "./assets/hideScrollbar.css";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

export default function SlagalicaGame() {
  const history = useHistory();
  const dispatch = useDispatch();

  const story = useSelector((state) => state.singleStory.story);
  const localStories = useSelector((state) => state.stories.localStories);
  const jwt = useSelector((state) => state.registrationLogin.authenticationData.jwt);
  const { bgColor, textColor, uppercase } = useSelector((state) => state.settings.settings);

  const [playing] = useState<boolean>(false);
  const [playingPageId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [gamePages, setGamePages] = useState([] as PageResponseData[]);
  const [counter, setCounter] = useState(1);
  const [correctGamePages, setCorrectGamePages] = useState([] as PageResponseData[]);
  const [numberOfOnScreenPages, setNumberOfOnScreenPages] = useState(0);
  const [opacityLevel, setOpacityLevel] = useState(100);
  const [enabledButtons, setEnabledButtons] = useState(true);
  const modal = useModal();
  const gameRulesModal = useModal();

  const { playSlagalica } = PlaySlagalicaGame();
  const { playSlagalicaGameLocal } = PlaySlagalicaGameLocalStory();

  const numberOfPages = 6;

  const pagesClassName = cx(
    "grid gap-y-20 lg:gap-x-20 sm:gap-x-10 lg:grid-cols-3 lg:grid-rows-2 md:grid-cols-2 md:grid-rows-3",
    {
      "p-20 border-2 rounded": correctGamePages.length > 0,
      "border-white": bgColor === "black",
    }
  );

  const storyImageClassName = cx(`text-${textColor}`, {
    uppercase: uppercase,
    storyImageSmall: true,
  });

  const imageContainerClassName = cx(`object-contain`, {
    imageContainerSmall: true,
  });

  const onClickRedirect = () => {
    history.push(`/games/slagalica`);
  };

  const nextPage = useCallback(() => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);

      if (currentPageIndex < story.pages.content.length - 1) {
        setCurrentPageIndex(currentPageIndex + 1);
      } else {
        setCurrentPageIndex(0);
      }
    }
  }, [currentPage, currentPageIndex, hasNextPage, story.pages.content.length]);

  const previousPage = useCallback(() => {
    if (hasPrevPage) {
      setCurrentPage(currentPage - 1);

      if (currentPageIndex > 0) {
        setCurrentPageIndex(currentPageIndex - 1);
      } else {
        setCurrentPageIndex(story.pages.content.length - 1);
      }
    }
  }, [currentPage, currentPageIndex, hasPrevPage, story.pages.content.length]);

  const getAnswerStatus = (counter: number, order: number, dragging: boolean) => {
    //debugger; // eslint-disable-line no-debugger
    return enabledButtons && !dragging && order == counter - 1 ? "correct" : "idle";
  };

  const changeOrder = (index: number, pageOrder: number) => {
    if (enabledButtons) {
      setEnabledButtons(false);
      setTimeout(() => {
        setOpacityLevel(0);
        const temp = gamePages[index];
        correctGamePages[pageOrder - 1] = temp;
        setNumberOfOnScreenPages(Math.ceil(correctGamePages.length / numberOfPages) - 1);
      }, 500);

      setTimeout(() => {
        gamePages.splice(index, 1);
        const clonedArr = [...gamePages];
        setGamePages(clonedArr);
        setOpacityLevel(100);
        setEnabledButtons(true);
      }, 1500);
    }
  };

  function LeftArrow() {
    const { scrollPrev } = React.useContext(VisibilityContext);
    return <ArrowButton className="mt-24 mr-5" type="left" onClick={() => scrollPrev()} />;
  }

  function RightArrow() {
    const { scrollNext } = React.useContext(VisibilityContext);
    return <ArrowButton className="mt-24 ml-5" type="right" onClick={() => scrollNext()} />;
  }

  function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }
  const { dragStart, dragStop, dragMove, dragging } = useDrag();

  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  useEffect(() => {
    const id = window.location.href.split("/").pop() ?? "-1";
    if (story == undefined || story.id.toString() != id) {
      dispatch(singleStoryAction.resetStory());
      setGamePages([]);
      if (localStories.map((story) => story.id.toString()).includes(id)) {
        const localStory = localStories.find((story) => story.id.toString() == id) ?? ({} as LocalStory);
        playSlagalicaGameLocal(localStory);
      } else {
        playSlagalica(Number(id), jwt);
      }

      setGamePages(shuffle([...story.pages.content]));
      setCurrentPageIndex(0);
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentPage < numberOfOnScreenPages) {
      setHasNextPage(true);
    } else {
      setHasNextPage(false);
    }

    if (currentPage != 0) {
      setHasPrevPage(true);
    } else {
      setHasPrevPage(false);
    }
  }, [setHasNextPage, setHasPrevPage, currentPage, story.pages.content.length, numberOfPages, numberOfOnScreenPages]);

  useEffect(() => {
    setCurrentPage(currentPageIndex);
  }, [currentPageIndex, setCurrentPageIndex]);

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      switch (e.key) {
        case "ArrowLeft":
          previousPage();
          break;
        case "ArrowRight":
          nextPage();
          break;
      }
    }
    document.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [nextPage, previousPage]);

  useEffect(() => {
    if (story.pages.content.length == correctGamePages.length) {
      setTimeout(() => history.push("/games/endgame"), 2000);
    }
  }, [counter, gamePages.length, history]);

  useEffect(() => {
    if (story.pages.content.length > 0) {
      setGamePages(shuffle([...story.pages.content]));
    }
  }, [story]);

  return (
    <PageCont>
      {story.pages.content.length > 0 && (
        <div className={`flex flex-col w-screen h-screen overflow-x-hidden items-center bg-${bgColor}`}>
          <Header />
          <PageContHeader
            title={`Slagalica: ${story.title}`}
            titleClassName="mr-36 text-center"
            isGameOrStory={true}
            rightButtons={[
              <GameRulesButtonModal game="Slagalica" key={1} {...gameRulesModal} />,
              <SettingsButtonModal key={2} {...modal} />,
            ]}
            leftButtons={<ReturnButton variant="secondary" text="Odaberi priču" onClick={onClickRedirect} />}
            className="w-full items-center mt-8 pr-8 pl-28"
          />
          <div className="w-2/3 mb-28">
            {gamePages && (
              <ScrollMenu
                LeftArrow={LeftArrow}
                RightArrow={RightArrow}
                onWheel={onWheel}
                itemClassName="pb-32"
                // onMouseDown={() => dragStart}
                // onMouseUp={() => dragStop}
                // onMouseMove={handleDrag}
                options={{
                  ratio: 0.9,
                  rootMargin: "5px",
                  threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
                }}
              >
                {gamePages.map((page: PageResponseData, index: number) => (
                  <ScrollCard key={index} itemId={index}>
                    <div
                      className={`transition-opacity duration-1000 ease-out opacity-${
                        counter - 1 === page.pageOrder ? opacityLevel : 100
                      }`}
                    >
                      <StoryImage text={page.text} className={storyImageClassName + " mr-8"}>
                        <GameImageContainer
                          key={page.id}
                          audioId={page.id}
                          image={page.image}
                          pageOrder={page.pageOrder}
                          imageClassName={imageContainerClassName}
                          counter={counter}
                          enabledOnClick={enabledButtons}
                          playing={playing && page.id === playingPageId}
                          answer={getAnswerStatus(counter, page.pageOrder, dragging)}
                          onClick={() => enabledButtons && !dragging && changeOrder(index, page.pageOrder)}
                          setCounter={(counter: number) => enabledButtons && setCounter(counter)}
                        />
                      </StoryImage>
                    </div>
                  </ScrollCard>
                ))}
              </ScrollMenu>
            )}
          </div>
          <div className="inline-block flex justify-between items-center">
            <div className="mb-8 px-4">
              {hasPrevPage ? (
                <ArrowButton type="left" onClick={previousPage} />
              ) : (
                <ArrowButton type="left" onClick={previousPage} className="invisible " />
              )}
            </div>
            <div className={pagesClassName}>
              {correctGamePages
                .slice(currentPage * numberOfPages, (currentPage + 1) * numberOfPages)
                .map((page: PageResponseData, index: number) => (
                  <StoryImage key={page.id} text={page.text} className={storyImageClassName}>
                    <GameImageContainer
                      key={page.id}
                      audioId={page.id}
                      image={page.image}
                      pageOrder={page.pageOrder}
                      imageClassName={imageContainerClassName}
                      counter={counter}
                      playing={playing && page.id === playingPageId}
                      // answer={getAnswerStatus(counter, page.pageOrder)}
                      // onClick={() => changeOrder(index, page.pageOrder)}
                      // setCounter={(counter: number) => setCounter(counter)}
                    />
                  </StoryImage>
                ))}
            </div>
            <div className="mb-8 px-4">
              {hasNextPage ? (
                <ArrowButton type="right" onClick={nextPage} />
              ) : (
                <ArrowButton type="right" onClick={nextPage} className="invisible " />
              )}
            </div>
          </div>
          <div className="mt-20 mb-4">
            {correctGamePages.length > 0 && (
              <ProgressButtons
                arrayLength={numberOfOnScreenPages + 1}
                currentIndex={currentPageIndex}
                setCurrentIndex={setCurrentPageIndex}
              />
            )}
          </div>
        </div>
      )}
    </PageCont>
  );
}
