import React, { DetailedHTMLProps, HTMLAttributes, ReactNode, useState } from "react";

import ProgressButtons from "../../../components/ProgressButtons";
import ArrowButton from "../../../components/componentsImpl/ArrowButton";

type InfoImageContProps = {
  elements: ReactNode[];
  texts: ReactNode[];
  titles: string[];
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function TutorialInfoCont({ texts, elements, titles, ...props }: InfoImageContProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div {...props} className="flex flex-col justify-start items-center w-full">
      <div className="flex flex-row justify-center items-center w-full">
        <div className="mr-4 w-4">
          {currentIndex != 0 && (
            <ArrowButton
              type="left"
              onClick={
                currentIndex > 0 ? () => setCurrentIndex(currentIndex - 1) : () => setCurrentIndex(elements.length - 1)
              }
            />
          )}
        </div>
        <div className="flex flex-col">
          <div className="font-bold text-center text-3xl">{titles[currentIndex]}</div>
          <div className="flex flex-col lg:flex-row justify-center items-center h-72 px-16 py-2 my-12">
            <div className="w-52">{elements[currentIndex]}</div>
            <div className="w-64 lg:ml-20 mt-4 lg:mt-8 font-semibold text-start self-start">{texts[currentIndex]}</div>
          </div>
        </div>
        <div className="ml-4 w-4">
          {currentIndex != 4 && (
            <ArrowButton
              type="right"
              onClick={
                currentIndex < elements.length - 1 ? () => setCurrentIndex(currentIndex + 1) : () => setCurrentIndex(0)
              }
            />
          )}
        </div>
      </div>
      <ProgressButtons arrayLength={elements.length} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
    </div>
  );
}
