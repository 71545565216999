import React, { SVGProps } from "react";
export default function AppleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        id="apple"
        d="M40.155 14.75C38.2416 14.822 36.3468 15.1532 34.5224 15.7346C33.4201 16.06 32.298 16.3144 31.1631 16.496C30.7058 13.9414 29.3657 11.6286 27.3768 9.96148C25.3878 8.29437 22.8765 7.37892 20.2812 7.375V11.0625C21.8781 11.0679 23.43 11.5924 24.7028 12.5568C25.9755 13.5213 26.9002 14.8735 27.3373 16.4094C26.3347 16.2261 25.34 16.0016 24.3559 15.7364C22.4517 15.1638 20.4832 14.8324 18.4965 14.75C11.6341 14.75 7.375 20.4011 7.375 29.5C7.375 43.3466 14.8754 51.6232 22.125 51.625H22.1287C23.7119 51.5411 25.2671 51.1726 26.7196 50.5372C27.6012 50.1414 28.5394 49.8863 29.5 49.7812C30.4624 49.8862 31.4024 50.1413 32.2859 50.5372C33.7378 51.1727 35.2923 51.5412 36.875 51.625C44.1209 51.6232 51.625 43.3466 51.625 29.5C51.625 22.6966 48.6197 14.75 40.155 14.75ZM36.875 47.9375C35.7345 47.8405 34.6187 47.5512 33.5747 47.082C32.2861 46.5126 30.9062 46.178 29.5 46.0938C28.0962 46.1783 26.7188 46.513 25.4327 47.082C24.386 47.551 23.2678 47.8403 22.125 47.9375H22.1287C16.6896 47.9357 11.0625 41.04 11.0625 29.5C11.0625 26.1739 11.7871 18.4375 18.4965 18.4375C20.1728 18.5269 21.8321 18.8198 23.4377 19.3096C25.2668 19.8566 27.1547 20.1828 29.0612 20.2812H29.9665C31.8427 20.1918 33.6995 19.8596 35.4903 19.293C37.0012 18.8051 38.5694 18.5175 40.155 18.4375C47.5614 18.4375 47.9375 27.6526 47.9375 29.5C47.9375 41.04 42.3104 47.9357 36.875 47.9375Z"
        fill="currentColor"
      />
      <path
        id="apple"
        d="M33.1875 12.9062H31.3438V11.0625C31.3447 10.0848 31.7335 9.14745 32.4249 8.45612C33.1162 7.76479 34.0536 7.37598 35.0312 7.375H36.875V9.21875C36.874 10.1964 36.4852 11.1338 35.7939 11.8251C35.1026 12.5165 34.1652 12.9053 33.1875 12.9062Z"
        fill="currentColor"
      />
    </svg>
  );
}
