import cx from "classnames";
import React, { DetailedHTMLProps, ButtonHTMLAttributes } from "react";

type ButtonProps = {
  variant?: "primary" | "secondary" | "red";
  leadingIcon?: React.ReactNode;
  leadingIconClassName?: string;
  trailingIcon?: React.ReactNode;
  trailingIconClassName?: string;
  rounded?: boolean;
  children: React.ReactNode;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export default function Button({
  children,
  variant,
  leadingIcon,
  leadingIconClassName = "w-7 h-7",
  trailingIcon,
  trailingIconClassName = "w-7 h-7",
  className,
  rounded = true,
  ...props
}: ButtonProps) {
  const buttonClassName = cx(
    className,
    `flex flex-row text-white text px-6 py-2 min-w-min justify-center items-center shadow-${variant}`,
    {
      [`bg-${variant} hover:bg-dark${variant}`]: variant,
      rounded: rounded,
    }
  );

  const finalLeadingIconClassName = cx(leadingIconClassName, "mr-3");
  const finalTrailingIconClassName = cx(trailingIconClassName, "mr-3");

  return (
    <button className={buttonClassName} {...props}>
      {leadingIcon &&
        React.cloneElement(leadingIcon as React.ReactElement, {
          className: finalLeadingIconClassName,
        })}
      {children}
      {trailingIcon &&
        React.cloneElement(trailingIcon as React.ReactElement, {
          className: finalTrailingIconClassName,
        })}
    </button>
  );
}
