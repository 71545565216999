import React, { SVGProps } from "react";
export default function InfoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.5 25.5C17.4174 25.5 20.2153 24.3411 22.2782 22.2782C24.3411 20.2153 25.5 17.4174 25.5 14.5C25.5 11.5826 24.3411 8.78473 22.2782 6.72183C20.2153 4.65893 17.4174 3.5 14.5 3.5C11.5826 3.5 8.78473 4.65893 6.72183 6.72183C4.65893 8.78473 3.5 11.5826 3.5 14.5C3.5 17.4174 4.65893 20.2153 6.72183 22.2782C8.78473 24.3411 11.5826 25.5 14.5 25.5V25.5ZM14.5 28.25C6.90588 28.25 0.75 22.0941 0.75 14.5C0.75 6.90588 6.90588 0.75 14.5 0.75C22.0941 0.75 28.25 6.90588 28.25 14.5C28.25 22.0941 22.0941 28.25 14.5 28.25ZM13.125 13.125V21.375H15.875V13.125H13.125ZM13.125 7.625H15.875V10.375H13.125V7.625Z"
        fill="currentColor"
      />
    </svg>
  );
}
