import React from "react";

import { UseModal } from "../../../components/Modal";
import InfoIcon from "../../../components/icons/InfoIcon";
import InfoModal from "../InfoModal";

export default function InfoButtonModal({ ...props }: UseModal) {
  return (
    <div onClick={props.onOpen} className="flex flex-row items-center justify-end h-6 w-full cursor-pointer">
      <InfoIcon width="20px" className="mr-1" />
      Info
      <InfoModal {...props} />
    </div>
  );
}
