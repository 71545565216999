import cx from "classnames";
import React from "react";

type ProgressButtonsProps = {
  arrayLength: number;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  locked?: boolean;
  activeColor?: string;
  inactiveColor?: string;
};

export default function ProgressButtons({
  arrayLength,
  currentIndex,
  setCurrentIndex,
  locked = false,
  activeColor = "bg-darkprimary",
  inactiveColor = "bg-primary",
}: ProgressButtonsProps) {
  const buttonClassName = "w-3.5 h-3.5 mr-2 rounded cursor-pointer";

  const activeButtonClassName = cx(buttonClassName, activeColor);
  const inactiveButtonClassName = cx(buttonClassName, inactiveColor);

  const progressButtonFunction = (arrayLength: number) => {
    const buttons = [];
    for (let i = 0; i < arrayLength; i++) {
      buttons.push(
        <div
          key={i}
          className={currentIndex === i ? activeButtonClassName : inactiveButtonClassName}
          onClick={!locked ? () => setCurrentIndex(i) : undefined}
        />
      );
    }

    return buttons;
  };

  return <div className="flex flex-row justify-center mt-2 mb-1">{progressButtonFunction(arrayLength)}</div>;
}
