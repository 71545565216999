import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";

import { useSelector } from "../hooks/storeHooks";

import CorrectAnswerIcon from "./icons/CorrectAnswerIcon";
import WrongAnswerIcon from "./icons/WrongAnswerIcon";

type ImageContainerProps = {
  image: string;
  imageAudio?: boolean;
  rounded?: boolean;
  playing?: boolean;
  imageClassName?: string;
  containerSize?: "small" | "medium";
  answer?: "correct" | "wrong" | "wrongTransition";
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function ImageContainer({
  image,
  className,
  onClick,
  playing,
  imageAudio,
  imageClassName,
  containerSize = "small",
  rounded = true,
  answer,
  ...props
}: ImageContainerProps) {
  const { bgColor, framed, frameColor } = useSelector((state) => state.settings.settings);

  const imageContainerClassName = cx(className, "overflow-hidden relative", {
    "cursor-pointer": onClick,
    [`border-4`]: framed,
    [`border-${frameColor} bg-${bgColor}`]: framed && !playing,
    [`border-${bgColor} bg-${bgColor}`]: !framed && !playing,
    [`border-primary`]: playing && imageAudio,
    [`hover:border-secondary`]: !playing && imageAudio,
    rounded: rounded,
  });

  const isCorrect = answer == "correct";
  const isWrong = answer == "wrong";
  const isWrongTransition = answer == "wrongTransition";

  const overlayClassName = cx("absolute flex justify-center items-center t-0 bg-opacity-50 flex text-align rounded", {
    imageContainerSmall: containerSize === "small",
    imageContainerMedium: containerSize === "medium",
    "bg-secondary": isCorrect,
    "bg-error": isWrong,
    "bg-error transition-opacity delay-300 duration-1000 opacity-0": isWrongTransition,
  });

  return (
    <div className={imageContainerClassName} onClick={onClick} {...props}>
      <div className={overlayClassName}>
        {isCorrect && <CorrectAnswerIcon width={64} color={"white"} />}
        {(isWrong || isWrongTransition) && <WrongAnswerIcon width={64} color={"white"} />}
      </div>
      <img className={imageClassName} src={`data:image;base64, ${image}`} alt="container image" />
    </div>
  );
}
