import React, { SVGProps } from "react";
export default function LockedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.5 2.75C12.7091 2.75 9.625 5.83413 9.625 9.625V13.75H8.25C6.73337 13.75 5.5 14.9834 5.5 16.5V27.5C5.5 29.0166 6.73337 30.25 8.25 30.25H24.75C26.2666 30.25 27.5 29.0166 27.5 27.5V16.5C27.5 14.9834 26.2666 13.75 24.75 13.75H23.375V9.625C23.375 5.83413 20.2909 2.75 16.5 2.75ZM24.75 16.5L24.7528 27.5H8.25V16.5H24.75ZM12.375 13.75V9.625C12.375 7.35075 14.2257 5.5 16.5 5.5C18.7743 5.5 20.625 7.35075 20.625 9.625V13.75H12.375Z"
        fill="currentColor"
      />
    </svg>
  );
}
