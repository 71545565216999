import React from "react";
import { useHistory } from "react-router-dom";

import StoryCardContainer from "../../components/CardComponentCont";
import { CardGameComponent } from "../../components/CardGameComponent";
import { Header } from "../../components/Header";
import { useModal } from "../../components/Modal";
import PageCont from "../../components/PageCont";
import PageContHeader from "../../components/PageContHeader";
import izbaciUljeza from "../../components/assets/izbaciUljeza.jpg";
import pitalica from "../../components/assets/pitalica.jpg";
import slagalica from "../../components/assets/slagalica.jpg";
import spajalica from "../../components/assets/spajalica.jpg";
import AppSidebar from "../../components/componentsImpl/AppSiderbar";
import SettingsButtonModal from "../Settings/components/SettingsButtonModal";
import TutorialButtonModal from "../Stories/components/TutorialButtonModal";

export default function Games() {
  const history = useHistory();
  const settingsModal = useModal();
  const tutorialModal = useModal();

  const onClickRedirect = async (title: string) => {
    history.push(`/games/${title}`);
  };

  return (
    <div className="flex flex-col justify-start h-screen">
      <PageCont>
        <Header />

        <div className="flex flex-start flex-row justify-start w-full h-full 2xl:h-screen relative">
          <AppSidebar />
          <div className="flex flex-col w-full justify-start">
            <PageContHeader
              title="Igre"
              titleClassName="mr-28"
              className="pr-8"
              rightButtons={[
                <TutorialButtonModal key={1} {...tutorialModal} />,
                <SettingsButtonModal key={2} {...settingsModal} />,
              ]}
            />
            <StoryCardContainer variant="none">
              <CardGameComponent
                key={1}
                title={"Slagalica"}
                image={slagalica}
                variant="image"
                onClick={() => {
                  onClickRedirect("slagalica");
                }}
                className="ml-16 sm:ml-8 md:ml-12 lg:ml-16"
              />
              <CardGameComponent
                key={2}
                title={"Spajalica"}
                image={spajalica}
                variant="image"
                onClick={() => {
                  onClickRedirect("spajalica");
                }}
                className="ml-16 sm:ml-8 md:ml-12 lg:ml-16"
              />
              <CardGameComponent
                key={3}
                title={"Izbaci uljeza"}
                image={izbaciUljeza}
                variant="image"
                onClick={() => {
                  onClickRedirect("izbaci_uljeza");
                }}
                className="ml-16 sm:ml-8 md:ml-12 lg:ml-16"
              />
              <CardGameComponent
                key={4}
                title={"Pitalica"}
                image={pitalica}
                variant="image"
                onClick={() => {
                  onClickRedirect("pitalica");
                }}
                className="ml-16 sm:ml-8 md:ml-12 lg:ml-16"
              />
            </StoryCardContainer>
          </div>
        </div>
      </PageCont>
    </div>
  );
}
