import React, { SVGProps } from "react";
export default function CloseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.3186 1.55988L1.3125 21.6152M21.3186 21.6152L1.3125 1.55988L21.3186 21.6152Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
