import React from "react";

import { StorySymbol } from "../../../api/types";

type SymbolCardProps = {
  symbol: StorySymbol;
  selected: boolean;
  setSelectedSymbol: (symbol: StorySymbol) => void;
};

export default function SymbolCard({ symbol, selected, setSelectedSymbol }: SymbolCardProps) {
  const selectSymbol = () => {
    setSelectedSymbol(symbol);
  };
  return (
    <div
      className={
        "flex flex-row items-center py-1 cursor-pointer hover:bg-primaryLight" + (selected ? " bg-primaryLight" : "")
      }
      onClick={selectSymbol}
    >
      <img className="w-50px h-50px rounded ml-1" src={`data:image;base64, ${symbol.image}`} alt="symbol image" />
      <span className="px-1">{symbol.name}</span>
    </div>
  );
}
