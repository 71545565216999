import React, { useState } from "react";

import Sidebar from "../../../components/Sidebar";
import CloudUploadIcon from "../../../components/icons/CloudUploadIcon";
import DownloadIcon from "../../../components/icons/DownloadIcon";

export default function SidebarItems() {
  const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

  return (
    <div className="bg-primary h-56 ">
      <Sidebar.Item icon={<CloudUploadIcon width={30} height={30} />} text="Uvezi sadržaj" />
      <div title="Ne zaboravite izvesti kreirane priče kako biste ih sačuvali!">
        <Sidebar.Item
          icon={<DownloadIcon width={30} height={30} />}
          text="Izvezi sadržaj"
          onClick={() => setDropdownOpened(!dropdownOpened)}
        />
      </div>
    </div>
  );
}
