import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";

import { useSelector } from "../hooks/storeHooks";

type StoryImageProps = {
  text: string;
  fontSize?: string;
  fontFamily?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function StoryImage({
  text,
  children,
  className,
  fontSize = "base",
  fontFamily = "sans",
  ...props
}: StoryImageProps) {
  const { uppercase, frameColor, textColor } = useSelector((state) => state.settings.settings);

  const storyImageClassName = cx(
    className,
    `text-${fontSize} font-${fontFamily} not-italic font-normal text-center storyImage text-${frameColor}`,
    {
      uppercase: uppercase,
    }
  );

  return (
    <div className={storyImageClassName} {...props}>
      {children}
      <p className={`items-center p-1.5 break-words text-${textColor}`}>{text}</p>
    </div>
  );
}
