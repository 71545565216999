import classNames from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";

type Breakpoints<T extends number = number> = { sm?: T; md?: T; lg?: T; xl?: T; xxl?: T };
export type Columns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type ItemSize = { size?: Columns } & Breakpoints<Columns>;

type Spacing = { spacing?: number } & Breakpoints;

type ContainerProps = Spacing & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
type ItemProps = ItemSize & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type GridItemProps = { item: true } & ItemProps;
type GridContainerProps = { container: true } & ContainerProps;
type GridProps = GridItemProps | GridContainerProps;

export default function Grid({ children, ...props }: GridProps) {
  const { item, ...itemProps } = props as GridItemProps;
  const { container: _, ...containerProps } = props as GridContainerProps;
  if (item) {
    return <GridItem {...itemProps}>{children}</GridItem>;
  } else {
    return <GridContainer {...containerProps}>{children}</GridContainer>;
  }
}

type ClassNameFactory = (value: number, breakpoint?: keyof Breakpoints) => string;

function width(size: number) {
  return size === 12 ? "w-full" : `w-${size}/12`;
}

const itemClassName: ClassNameFactory = (value, breakpoint) => {
  if (!breakpoint) {
    return width(value);
  }
  return `${breakpoint}:${width(value)}`;
};

const containerClassName: ClassNameFactory = (value, breakpoint) => {
  if (!breakpoint) {
    return `-mx-${value} grid-container-${value}`;
  }
  return `${breakpoint}:-mx-${value} ${breakpoint}:grid-container-${value}`;
};

function getClasses(values: ItemSize | Spacing, factory: ClassNameFactory, defaultKey: "spacing" | "size") {
  const cssClasses: string[] = [];
  Object.keys(values).forEach((k) => {
    const key = k as keyof (ItemSize | Spacing);
    const value = values[key];
    if (value) {
      cssClasses.push(factory(value, k !== defaultKey ? key : undefined));
    }
  });
  return cssClasses;
}

function GridContainer({ spacing = 3, className, children, sm, md, lg, xl, xxl, ...props }: ContainerProps) {
  const css = classNames(
    className,
    "flex flex-wrap overflow-hidden box-border",
    getClasses({ spacing, sm, md, lg, xl, xxl }, containerClassName, "spacing")
  );

  return (
    <div className={css} {...props}>
      {children}
    </div>
  );
}

function GridItem({ size = 12, className, children, sm, md, lg, xl, xxl, ...props }: ItemProps) {
  const css = classNames(
    className,
    "overflow-hidden grid-item",
    getClasses({ size, sm, md, lg, xl, xxl }, itemClassName, "size")
  );
  return (
    <div className={css} {...props}>
      {children}
    </div>
  );
}
