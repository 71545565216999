import React, { SVGProps } from "react";
export default function CloudUploadIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M27.3525 10.2459V9.94838C27.2893 7.42947 26.2366 5.03794 24.4241 3.29587C22.6116 1.5538 20.1865 0.602673 17.6782 0.650132C15.8102 0.634822 13.9776 1.16202 12.4009 2.16828C10.8242 3.17454 9.57055 4.617 8.79091 6.32206C6.59574 6.56801 4.5668 7.61467 3.08901 9.26348C1.61122 10.9123 0.787386 13.0485 0.773788 15.267C0.776216 16.4424 1.00928 17.6059 1.45967 18.6908C1.91006 19.7757 2.56892 20.7608 3.39861 21.5898C4.2283 22.4188 5.21253 23.0755 6.29504 23.5222C7.37754 23.9689 8.5371 24.197 9.70741 24.1933H13.179V22.3336H9.70741C7.91804 22.2364 6.2319 21.4614 4.98887 20.165C3.74585 18.8685 3.03835 17.147 3.009 15.3474C2.97964 13.5478 3.63061 11.8039 4.83069 10.4673C6.03077 9.13062 7.69073 8.3006 9.47597 8.14452H10.0962L10.3092 7.55873C10.8833 6.0609 11.9015 4.77621 13.226 3.87826C14.5504 2.9803 16.1175 2.51236 17.7153 2.53768C19.7326 2.49007 21.6868 3.24524 23.1521 4.6386C24.6174 6.03196 25.475 7.95057 25.538 9.97627C25.5507 10.2241 25.5507 10.4723 25.538 10.7201L25.4639 11.3896L26.0657 11.6686C27.2295 12.1952 28.1772 13.1084 28.7494 14.2544C29.3216 15.4005 29.4833 16.7096 29.2073 17.9614C28.9313 19.2131 28.2345 20.3312 27.234 21.1275C26.2336 21.9238 24.9906 22.3498 23.7142 22.3336H18.7336V24.1933H23.7142C25.3607 24.2052 26.9641 23.6656 28.2712 22.66C29.5782 21.6543 30.5144 20.2399 30.9317 18.6402C31.3489 17.0404 31.2236 15.3467 30.5754 13.8265C29.9272 12.3063 28.7932 11.0464 27.3525 10.2459Z"
        fill="currentColor"
      />
      <path
        d="M19.9186 18.475C20.1033 18.4779 20.2846 18.4253 20.4393 18.3239C20.5939 18.2226 20.7149 18.077 20.7866 17.9061C20.8582 17.7351 20.8774 17.5465 20.8415 17.3646C20.8056 17.1826 20.7163 17.0156 20.5852 16.885L15.9563 12.2358L11.3275 16.885C11.1758 17.0628 11.0966 17.2916 11.1056 17.5257C11.1146 17.7597 11.2112 17.9817 11.3761 18.1473C11.5409 18.3129 11.7619 18.4099 11.9949 18.4189C12.2279 18.4279 12.4557 18.3483 12.6328 18.196L15.0306 15.825V27.2153C15.0306 27.4619 15.1281 27.6984 15.3017 27.8728C15.4753 28.0472 15.7108 28.1451 15.9563 28.1451C16.2019 28.1451 16.4373 28.0472 16.6109 27.8728C16.7846 27.6984 16.8821 27.4619 16.8821 27.2153V15.825L19.2613 18.2146C19.4375 18.3853 19.6739 18.4789 19.9186 18.475Z"
        fill="currentColor"
      />
    </svg>
  );
}
