import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { PageRequestData, PageResponseData } from "../../api/types";
import { UseModal } from "../../components/Modal";
import { useSelector } from "../../hooks/storeHooks";
import { serializeQueryProps } from "../../store/storeUtils";
import { addLocalStory, LocalStory, storiesAction } from "../Stories/store/storiesSlice";

import StoryModal from "./components/StoryModal";

type StoryModalProps = {
  modal: UseModal;
  onSave: () => void;
};

export default function NewStoryModal({ modal, onSave }: StoryModalProps) {
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.registrationLogin.authenticationData.jwt);

  const [pageList, setPageList] = useState<PageRequestData[]>([]);
  const loggedInUser = useSelector((state) => state.registrationLogin.loggedIn);

  const saveNewStory = async (storyName: string) => {
    if (loggedInUser) {
      await dispatch(
        storiesAction.addStory({
          story: { title: storyName, coverPage: pageList[0].image, pages: pageList },
          jwt: jwt,
        })
      );
      dispatch(storiesAction.getAllStories({ queryParams: serializeQueryProps(), jwt: jwt }));
    } else {
      const tempLocalStory = { pages: [] as PageResponseData[] } as LocalStory;
      const tempRequestPages = pageList;

      tempLocalStory.coverPage = pageList[0].image;
      tempLocalStory.title = storyName;
      tempLocalStory.favorite = false;
      tempLocalStory.id = Date.now();

      let pageIdNumber = tempLocalStory.id + 1;

      let pageOrder = 1;
      tempRequestPages.forEach((value) => {
        const tempPage = {} as PageResponseData;
        tempPage.text = value.text;
        tempPage.image = value.image;
        tempPage.audio = value.audio ? value.audio : "";
        tempPage.storyId = tempLocalStory.id;
        tempPage.id = pageIdNumber;
        tempPage.pageOrder = pageOrder;
        pageOrder++;
        pageIdNumber++;

        tempLocalStory.pages.push(tempPage);
      });

      dispatch(addLocalStory(tempLocalStory));
    }

    onSave();
    modal.onClose();
  };

  return (
    <StoryModal
      modalTitle="Dodaj novu priču"
      onSubmitFunction={saveNewStory}
      pageList={pageList}
      setPageList={setPageList}
      modal={modal}
    />
  );
}
