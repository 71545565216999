import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

import { PageRequestData } from "../../../api/types";
import Button from "../../../components/Button";
import FormLayout from "../../../components/FormLayout";
import Input from "../../../components/Input";
import Modal, { ModalFooter, ModalHeader, useModal, UseModal } from "../../../components/Modal";
import YesNoModal from "../../../components/YesNoModal";
import PageRow from "../../../components/componentsImpl/PageRow";
import PlusIcon from "../../../components/icons/PlusIcon";
import SaveIcon from "../../../components/icons/SaveIcon";

import PageModal from "./PageModal";

type StoryModalProps = {
  modalTitle: string;
  storyTitle?: string;
  onSubmitFunction: (storyName: string) => void;
  pageList: PageRequestData[];
  setPageList: (pageList: PageRequestData[]) => void;
  modal: UseModal;
};

export default function StoryModal({
  modalTitle,
  storyTitle = "",
  onSubmitFunction,
  pageList,
  setPageList,
  modal,
}: StoryModalProps) {
  const pageModal = useModal();
  const yesNoModal = useModal();
  const [titleError, setTitleError] = useState("");
  const [pagesError, setPagesError] = useState("");

  useEffect(() => {
    if (pageList.length > 0) setPagesError("");
  }, [pageList, setPagesError]);

  function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    const items = Array.from(pageList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPageList(items);
  }

  const submitStory = (storyName: string) => {
    if (storyName && storyName.replaceAll(/\s/g, "").length > 0 && pageList.length > 0) {
      onSubmitFunction(storyName);
    } else {
      if (!storyName || storyName.replaceAll(/\s/g, "").length == 0) {
        setTitleError("Naslov priče je obavezan sadržaj.");
      } else {
        setTitleError("");
      }
      if (pageList.length == 0) {
        setPagesError("Priča mora sadržavati barem jednu stranicu.");
      } else {
        setPagesError("");
      }
    }
  };

  const onCloseFunction = () => {
    yesNoModal.onOpen();
  };

  return (
    <Modal {...modal} onClose={onCloseFunction} className="w-590px h-670px overflow-y-hidden">
      {yesNoModal.isOpen && (
        <YesNoModal
          message="Želite li nastaviti bez spremanja?"
          onYes={() => modal.onClose()}
          modal={yesNoModal}
          className="w-300px"
        />
      )}
      <div className="flex flex-col flex-grow h-full">
        <ModalHeader
          onClose={() => {
            onCloseFunction();
          }}
          variant="primary"
          title={modalTitle}
        />
        <div className="px-8 h-full">
          <FormLayout
            initialValues={{ storyName: storyTitle }}
            onSubmit={(values) => submitStory(values.storyName)}
            className="h-full"
          >
            <div className="w-5/12 mt-2">
              <Input label="Naziv priče" name="storyName" maxLength={30} textArea={false} />
              <div className="h-8 mt-0.5 -mb-2 w-96">
                {titleError && <div className="text-error">{titleError}</div>}
              </div>
            </div>
            <div className="flex justify-end items-baseline border-b border-darkGray">
              {pageList.length > 0 && (
                <div className="text-left text-xs mr-14 leading-none">
                  Uredi redoslijed slika u priči povlačenjem slike po tablici
                </div>
              )}
              <Button
                type="button"
                onClick={pageModal.onOpen}
                variant="primary"
                className="font-semibold h-10 mb-2"
                leadingIcon={<PlusIcon width={26} height={26} />}
              >
                Dodaj sliku
              </Button>
              {pageModal.isOpen && (
                <PageModal
                  initialState={{ image: "", text: "", audio: "" } as PageRequestData}
                  variant="newPage"
                  modal={pageModal}
                  pageList={pageList}
                  setPageList={setPageList}
                />
              )}
            </div>

            <div className="h-80 flex-grow overflow-y-auto overflow-x-hidden">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="pages">
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {pageList.map((page: PageRequestData, index) => (
                        <Draggable key={index} draggableId={index.toString()} index={index}>
                          {(provided) => (
                            <li ref={provided.innerRef} {...provided.draggableProps}>
                              <PageRow
                                key={index + 1}
                                pageNumber={index + 1}
                                image={page.image}
                                text={page.text}
                                audio={page.audio}
                                dragHandleProps={provided.dragHandleProps}
                                className="border-t border-b border-darkGray"
                                pageList={pageList}
                                setPageList={setPageList}
                              />
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="h-6 mb-2 justify-start">{pagesError && <div className="text-error">{pagesError}</div>}</div>
            <hr className="text-darkGray" />
            <ModalFooter className="justify-center">
              <Button variant="primary" type="submit" className="font-semibold w-40">
                <SaveIcon width={20} height={20} className="mr-5" />
                Spremi
              </Button>
            </ModalFooter>
          </FormLayout>
        </div>
      </div>
    </Modal>
  );
}
