import React, { SVGProps } from "react";

export default function ConfigIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M31.125 7.5H26.625" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.625 4.5V10.5" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.625 7.5H4.125" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.125 18H4.125" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.125 15V21" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.625 18H16.125" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M31.125 28.5H26.625" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.625 25.5V31.5" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.625 28.5H4.125" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
