import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";

import * as storiesApi from "../../../api/stories";
import { StoryRequestData, StoryCard, StoryCardResponse, PageResponseData, StoryNoPages } from "../../../api/types";
import { fetchWrapper } from "../../../store/storeUtils";

export type LocalStory = {
  pages: PageResponseData[];
} & StoryCard;

const getAllStories = createAsyncThunk(
  "stories/getAllStories",
  async ({ queryParams, jwt }: { queryParams: string; jwt?: string }) => {
    return await fetchWrapper<StoryCardResponse>(storiesApi.getAllStories(queryParams, jwt));
  }
);

const getNoStoryPages = createAsyncThunk("stories/getNoPages", async (jwt?: string) => {
  return await fetchWrapper<StoryNoPages[]>(storiesApi.getStoriesNoPages(jwt));
});

const addStory = createAsyncThunk(
  "stories/addStory",
  async ({ story, jwt }: { story: StoryRequestData; jwt?: string }) => {
    return await fetchWrapper<StoryCard>(storiesApi.addStory(story, jwt));
  }
);

const updateStory = createAsyncThunk(
  "stories/updateStory",
  async ({ storyId, story, jwt }: { storyId: string; story: StoryRequestData; jwt?: string }) => {
    return await fetchWrapper<StoryCard>(storiesApi.updateStory(storyId, story, jwt));
  }
);

const deleteStory = createAsyncThunk(
  "stories/deleteStory",
  async ({ storyId, queryParams, jwt }: { storyId: string; queryParams: string; jwt?: string }) => {
    return await fetchWrapper<StoryCard>(storiesApi.deleteStory(storyId, queryParams, jwt));
  }
);

const changeFavoriteStory = createAsyncThunk(
  "stories/changeFavoriteStory",
  async ({ storyId, jwt }: { storyId: string; jwt?: string }) => {
    return await fetchWrapper<StoryCard>(storiesApi.changeFavoriteStory(storyId, jwt));
  }
);

type StoriesState = {
  stories: StoryCardResponse;
  storiesNoPages: StoryNoPages[];
  story: StoryCard;
  localStories: LocalStory[];
  status: "idle" | "success" | "waiting" | "error";
  error?: SerializedError;
};

export const storiesInitialState: StoriesState = {
  stories: { content: [] as StoryCard[] } as StoryCardResponse,
  storiesNoPages: [] as StoryNoPages[],
  story: {} as StoryCard,
  localStories: [] as LocalStory[],
  status: "idle",
  error: undefined,
};

const storiesSlice = createSlice({
  name: "stories",
  initialState: storiesInitialState,
  reducers: {
    addLocalStory(state, action: PayloadAction<LocalStory>) {
      state.localStories.push(action.payload);
    },
    updateLocalStory(state, action: PayloadAction<LocalStory>) {
      const editStoryIndex = state.localStories.map((story) => story.id).indexOf(action.payload.id);
      state.localStories[editStoryIndex] = action.payload;
    },
    removeLocalStory(state, action: PayloadAction<number>) {
      state.localStories = state.localStories.filter((story: LocalStory) => story.id != action.payload);
    },
    changeFavoriteLocalStory(state, action: PayloadAction<number>) {
      const objIndex = state.localStories.findIndex((story) => story.id == action.payload);
      state.localStories[objIndex].favorite = !state.localStories[objIndex].favorite;
    },
    resetStories: () => storiesInitialState,
    resetLocalStories: (state) => {
      state.localStories = storiesInitialState.localStories;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllStories.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(getAllStories.fulfilled, (state, action) => {
      state.status = "success";
      state.stories = action.payload;
    });

    builder.addCase(getAllStories.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error;
    });

    builder.addCase(getNoStoryPages.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(getNoStoryPages.fulfilled, (state, action) => {
      state.status = "success";
      state.storiesNoPages = action.payload;
    });

    builder.addCase(getNoStoryPages.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error;
    });

    builder.addCase(addStory.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(addStory.fulfilled, (state, action) => {
      state.status = "success";
      state.story = action.payload;
    });

    builder.addCase(addStory.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error;
    });

    builder.addCase(updateStory.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(updateStory.fulfilled, (state, action) => {
      state.status = "success";
      state.story = action.payload;
    });

    builder.addCase(updateStory.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error;
    });

    builder.addCase(deleteStory.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(deleteStory.fulfilled, (state, action) => {
      state.status = "success";
      state.story = action.payload;
    });

    builder.addCase(deleteStory.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error;
    });

    builder.addCase(changeFavoriteStory.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(changeFavoriteStory.fulfilled, (state, action) => {
      state.status = "success";
      state.story = action.payload;
    });

    builder.addCase(changeFavoriteStory.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error;
    });
  },
});

export const stories = storiesSlice.reducer;
export const { addLocalStory, updateLocalStory, removeLocalStory, changeFavoriteLocalStory } = storiesSlice.actions;
export const storiesAction = {
  getAllStories,
  getNoStoryPages,
  updateStory,
  deleteStory,
  addStory,
  changeFavoriteStory,
  ...storiesSlice.actions,
};
