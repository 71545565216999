import React, { SVGProps } from "react";
export default function PlayIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.1642 14.3731L4.06369 24.2954C2.61244 25.1366 0.75 24.118 0.75 22.4222V2.57771C0.75 0.884584 2.60975 -0.136667 4.06369 0.707208L21.1642 10.6295C21.4944 10.8179 21.7688 11.0903 21.9597 11.4191C22.1505 11.7478 22.2511 12.1212 22.2511 12.5013C22.2511 12.8814 22.1505 13.2548 21.9597 13.5835C21.7688 13.9123 21.4944 14.1847 21.1642 14.3731Z"
        fill="currentColor"
      />
    </svg>
  );
}
