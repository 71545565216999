import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeToast } from "../../../util/makeToast";
import { singleStoryAction } from "../../Stories/store/singleStorySlice";
import { LocalStory } from "../../Stories/store/storiesSlice";

export default function PlaySlagalicaGameLocalStory() {
  const dispatch = useDispatch();
  const history = useHistory();

  const playSlagalicaGameLocal = async (card: LocalStory) => {
    await dispatch(singleStoryAction.putLocalStory(card));

    if (card.pages.length <= 1) {
      makeToast('Nemoguće igrati. Za igrati igru "Slagalica" potrebne su minimalno dvije slike u priči.');
    } else {
      history.push(`/games/slagalica/${card.id}`);
    }
  };

  return { playSlagalicaGameLocal };
}
