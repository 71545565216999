export const puzzleRules =
  "Slagalica je igra čiji je cilj složiti slike kronološkim redoslijedom kao što su prikazane u odabranoj priči. \n" +
  "Igrač klikne na sliku koju smatra sljedećom u redu. Ako je odabir točan, slika se zazeleni te zauzima\n" +
  "pravilno mjesto u redoslijedu priče. Ako je odabir pogrešan, slika se zacrveni i igrač ponovno bira drugu sliku.\n" +
  "Igra se nastavlja dok sve slike priče nisu prikazane u pravilnom redoslijedu.";

export const connectRules =
  "Spajalica je igra čiji je cilj povezati tekst sa slikom tako da odgovara odabranoj priči. \n" +
  "Igrač klikne na sliku koju smatra da odgovara tekstu. Ako je odabir točan, slika se zazeleni i prikazuje \n" +
  "se sljedeći par slika. Ako je odgovor pogrešan, slika se zacrveni i igrač ponovno bira sliku.\n" +
  "Igra se nastavlja sve dok sav tekst priče nije povezan s odgovarajućom slikom.";

export const oddOneOutRules =
  "Izbaci uljeza je igra čiji je cilj izbaciti dvije slike koje ne odgovaraju odabranoj priči.\n" +
  "Igrač klikne na sliku koju smatra uljezom. Ako je odabir točan, slika se zazeleni. Ako je odgovor pogrešan,\n" +
  "slika se zacrveni i igrač ponovno bira sliku. \n" +
  "Igra se nastavlja sve dok igrač ne izbaci 2 uljeza.";

export const yesNoGameRules =
  "Pitalica je igra čiji je cilj procijeniti točnost napisanih izjava koje se temelje na odabranoj priči.\n" +
  'Igrač klikne na odgovor "Da" ako smatra da je izjava točna i "Ne" ako smatra da je izjava pogrešna.\n' +
  "Igra se nastavlja sve dok igrač pravilno ne odgovori na sva pitanja.";
