import React, { SVGProps } from "react";
export default function HeartIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.33334 1C4.65201 1 1.66667 3.95467 1.66667 7.6C1.66667 10.5427 2.83334 17.5267 14.3173 24.5867C14.523 24.7118 14.7592 24.778 15 24.778C15.2408 24.778 15.477 24.7118 15.6827 24.5867C27.1667 17.5267 28.3333 10.5427 28.3333 7.6C28.3333 3.95467 25.348 1 21.6667 1C17.9853 1 15 5 15 5C15 5 12.0147 1 8.33334 1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
