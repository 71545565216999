import React, { SVGProps } from "react";
export default function ImageDoubleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 76 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.8734 17.4167C13.1851 17.4167 14.2484 16.3534 14.2484 15.0417C14.2484 13.7301 13.1851 12.6667 11.8734 12.6667C10.5617 12.6667 9.49841 13.7301 9.49841 15.0417C9.49841 16.3534 10.5617 17.4167 11.8734 17.4167Z"
        fill="currentColor"
      />
      <path
        d="M16.6234 22.1666L14.2484 18.9999L9.49841 25.3333H28.4984L21.3734 15.8333L16.6234 22.1666Z"
        fill="currentColor"
      />
      <path
        d="M31.6651 6.33325H6.33177C4.58535 6.33325 3.1651 7.7535 3.1651 9.49992V28.4999C3.1651 30.2463 4.58535 31.6666 6.33177 31.6666H31.6651C33.4115 31.6666 34.8318 30.2463 34.8318 28.4999V9.49992C34.8318 7.7535 33.4115 6.33325 31.6651 6.33325ZM6.33177 28.4999V9.49992H31.6651L31.6683 28.4999H6.33177Z"
        fill="currentColor"
      />
      <path
        d="M49.8734 17.4167C51.1851 17.4167 52.2484 16.3534 52.2484 15.0417C52.2484 13.7301 51.1851 12.6667 49.8734 12.6667C48.5617 12.6667 47.4984 13.7301 47.4984 15.0417C47.4984 16.3534 48.5617 17.4167 49.8734 17.4167Z"
        fill="currentColor"
      />
      <path
        d="M54.6234 22.1666L52.2484 18.9999L47.4984 25.3333H66.4984L59.3734 15.8333L54.6234 22.1666Z"
        fill="currentColor"
      />
      <path
        d="M69.6651 6.33325H44.3318C42.5853 6.33325 41.1651 7.7535 41.1651 9.49992V28.4999C41.1651 30.2463 42.5853 31.6666 44.3318 31.6666H69.6651C71.4115 31.6666 72.8318 30.2463 72.8318 28.4999V9.49992C72.8318 7.7535 71.4115 6.33325 69.6651 6.33325ZM44.3318 28.4999V9.49992H69.6651L69.6683 28.4999H44.3318Z"
        fill="currentColor"
      />
    </svg>
  );
}
