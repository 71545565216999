import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { StoryCard } from "../../api/types";
import { CardComponent } from "../../components/CardComponent";
import StoryCardContainer from "../../components/CardComponentCont";
import { Header } from "../../components/Header";
import { useModal } from "../../components/Modal";
import PageCont from "../../components/PageCont";
import PageContHeader from "../../components/PageContHeader";
import AppSidebar from "../../components/componentsImpl/AppSiderbar";
import ReturnButton from "../../components/componentsImpl/ReturnButton";
import { useSelector } from "../../hooks/storeHooks";
import { serializeQueryProps } from "../../store/storeUtils";
import SettingsButtonModal from "../Settings/components/SettingsButtonModal";
import TutorialButtonModal from "../Stories/components/TutorialButtonModal";
import { LocalStory, storiesAction } from "../Stories/store/storiesSlice";

import PlayConnectGame from "./util/playConnectGame";
import PlayConnectGameLocalStory from "./util/playConnectGameLocalStory";
import PlayOddOneOutGame from "./util/playOddOneOutGame";
import PlayOddOneOutGameLocalStory from "./util/playOddOneOutGameLocalStory";
import PlaySlagalicaGame from "./util/playSlagalicaGame";
import PlaySlagalicaGameLocalStory from "./util/playSlagalicaGameLocalStory";
import PlayYesNoGame from "./util/playYesNoGame";

export default function Stories() {
  const history = useHistory();
  const dispatch = useDispatch();
  const settingsModal = useModal();
  const tutorialModal = useModal();
  const stories = useSelector((state) => state.stories.stories.content);
  const localStories = useSelector((state) => state.stories.localStories);
  const jwt = useSelector((state) => state.registrationLogin.authenticationData.jwt);
  const { playYesNo } = PlayYesNoGame();
  const { playConnectLocal } = PlayConnectGameLocalStory();
  const { playConnect } = PlayConnectGame();
  const { playOddOneOut } = PlayOddOneOutGame();
  const { playOddOneOutLocal } = PlayOddOneOutGameLocalStory();
  const { playSlagalicaGameLocal } = PlaySlagalicaGameLocalStory();
  const { playSlagalica } = PlaySlagalicaGame();

  const [gameName, setGameName] = useState("");

  useEffect(() => {
    dispatch(storiesAction.getAllStories({ queryParams: serializeQueryProps(), jwt: jwt }));
  }, [dispatch, jwt]);

  useEffect(() => {
    let pathEnding = history.location.pathname.split("/")[2];
    pathEnding = pathEnding.replace("_", " ");

    setGameName(pathEnding.charAt(0).toUpperCase() + pathEnding.slice(1));
  }, [history, setGameName]);

  const onClickPlay = async (id: number) => {
    if (history.location.pathname.endsWith("/izbaci_uljeza")) {
      await playOddOneOut(id, stories, localStories, jwt);
    }
    if (history.location.pathname.endsWith("/slagalica")) {
      await playSlagalica(id, jwt);
    }
    if (history.location.pathname.endsWith("/spajalica")) {
      await playConnect(id, jwt);
    }

    if (history.location.pathname.endsWith("/pitalica")) {
      await playYesNo(id);
    }
  };

  const onClickPlayLocalStory = async (card: LocalStory) => {
    if (history.location.pathname.endsWith("/izbaci_uljeza")) {
      await playOddOneOutLocal(card, stories, localStories, jwt);
    }

    if (history.location.pathname.endsWith("/slagalica")) {
      await playSlagalicaGameLocal(card);
    }

    if (history.location.pathname.endsWith("/spajalica")) {
      await playConnectLocal(card);
    }
  };

  const onClickRedirect = () => {
    history.push("/games");
  };

  return (
    <div className="flex flex-col justify-start h-screen">
      <PageCont>
        <Header className="pl-44 2xl:pl-24" />

        <div className="flex flex-start flex-row justify-start w-full h-full relative">
          <AppSidebar />
          <div className="flex flex-col w-full justify-start">
            <PageContHeader
              title={`${gameName} - Odaberi priču`}
              titleClassName="2xl:mr-24"
              className="pl-4 pr-8 xl:pl-12 xl:pr-20 2xl:pl-16 2xl:pr-24"
              rightButtons={[
                <TutorialButtonModal key={2} {...tutorialModal} />,
                <SettingsButtonModal key={3} {...settingsModal} />,
              ]}
              leftButtons={<ReturnButton variant="secondary" text="Igre" onClick={onClickRedirect} />}
            />
            <div className="flex w-full justify-center pr-8 xl:pr-16 2xl:pr-36">Pokrenite igru odabirom priče</div>
            {!history.location.pathname.endsWith("/pitalica") && (
              <StoryCardContainer variant="favorite">
                {stories
                  .filter((card: StoryCard) => card.favorite)
                  .map((card: StoryCard) => (
                    <CardComponent
                      key={card.id}
                      title={card.title}
                      image={card.coverPage}
                      variant="image"
                      onClick={async () => {
                        await onClickPlay(card.id);
                      }}
                      className="ml-16 sm:ml-8 md:ml-12 lg:ml-16"
                    />
                  ))}
                {!history.location.pathname.endsWith("/pitalica") &&
                  localStories
                    .filter((card: StoryCard) => card.favorite)
                    .map((card: LocalStory) => (
                      <CardComponent
                        key={card.id}
                        title={card.title}
                        image={card.coverPage}
                        variant="image"
                        onClick={async () => {
                          await onClickPlayLocalStory(card);
                        }}
                        className="ml-16 sm:ml-8 md:ml-12 lg:ml-16 "
                      />
                    ))}
              </StoryCardContainer>
            )}

            <StoryCardContainer
              className="mb-3"
              variant={history.location.pathname.endsWith("/pitalica") ? "none" : "rest"}
            >
              {stories
                .filter((card: StoryCard) => !card.favorite)
                .map((card: StoryCard) => (
                  <CardComponent
                    key={card.id}
                    title={card.title}
                    image={card.coverPage}
                    variant="image"
                    onClick={async () => {
                      await onClickPlay(card.id);
                    }}
                    className="ml-16 sm:ml-8 md:ml-12 lg:ml-16 "
                  />
                ))}
              {!history.location.pathname.endsWith("/pitalica") &&
                localStories
                  .filter((card: StoryCard) => !card.favorite)
                  .map((card: LocalStory) => (
                    <CardComponent
                      key={card.id}
                      title={card.title}
                      image={card.coverPage}
                      variant="image"
                      onClick={async () => {
                        await onClickPlayLocalStory(card);
                      }}
                      className="ml-16 sm:ml-8 md:ml-12 lg:ml-16 "
                    />
                  ))}
            </StoryCardContainer>
          </div>
        </div>
      </PageCont>
    </div>
  );
}
