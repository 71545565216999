export const stories = {
  getAll: "/api/stories",
  getStoriesNoPages: "/api/stories/no-pages",
  getSingle: (storyId: string) => `/api/stories/${storyId}`,
  updateStory: (storyId: string) => `/api/stories/${storyId}`,
  deleteStory: (storyId: string) => `/api/stories/${storyId}`,
  addStory: "/api/stories",
  changeFavorite: (storyId: string) => `/api/stories/${storyId}/favorite`,
};

export const login = {
  login: "/api/login",
  register: "/api/register",
};

export const settings = {
  settings: "/api/settings",
};

export const games = {
  getSingleYesNoGame: (storyId: string) => `/api/games/yes-no-game/${storyId}`,
};

export const symbols = {
  categories: "/api/symbols/categories",
  symbolsByCategory: (categoryId: string) => `/api/symbols/category/${categoryId}`,
  symbolAudio: (symbolId: string) => `/api/symbols/audio/${symbolId}`,
  filterSymbols: (query: string) => `/api/symbols/search?queryString=${query}`,
};

export const emailSender = {
  emailSender: "/api/email-sender",
};
