import React, { SVGProps } from "react";
export default function MicrophoneIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.56256 12.1875C1.8112 12.1875 2.04966 12.2863 2.22547 12.4621C2.40129 12.6379 2.50006 12.8764 2.50006 13.125V15C2.50006 16.9891 3.29024 18.8968 4.69676 20.3033C6.10328 21.7098 8.01094 22.5 10.0001 22.5C11.9892 22.5 13.8968 21.7098 15.3034 20.3033C16.7099 18.8968 17.5001 16.9891 17.5001 15V13.125C17.5001 12.8764 17.5988 12.6379 17.7746 12.4621C17.9505 12.2863 18.1889 12.1875 18.4376 12.1875C18.6862 12.1875 18.9247 12.2863 19.1005 12.4621C19.2763 12.6379 19.3751 12.8764 19.3751 13.125V15C19.3751 17.3241 18.5118 19.5654 16.9528 21.289C15.3937 23.0126 13.25 24.0957 10.9376 24.3281V28.125H16.5626C16.8112 28.125 17.0497 28.2238 17.2255 28.3996C17.4013 28.5754 17.5001 28.8139 17.5001 29.0625C17.5001 29.3111 17.4013 29.5496 17.2255 29.7254C17.0497 29.9012 16.8112 30 16.5626 30H3.43756C3.18892 30 2.95046 29.9012 2.77465 29.7254C2.59883 29.5496 2.50006 29.3111 2.50006 29.0625C2.50006 28.8139 2.59883 28.5754 2.77465 28.3996C2.95046 28.2238 3.18892 28.125 3.43756 28.125H9.06256V24.3281C6.75009 24.0957 4.60639 23.0126 3.04734 21.289C1.48828 19.5654 0.625032 17.3241 0.625061 15V13.125C0.625061 12.8764 0.723833 12.6379 0.899649 12.4621C1.07546 12.2863 1.31392 12.1875 1.56256 12.1875Z"
        fill="currentColor"
      />
      <path
        d="M13.7501 15C13.7501 15.9946 13.355 16.9484 12.6518 17.6516C11.9485 18.3549 10.9947 18.75 10.0001 18.75C9.00556 18.75 8.05173 18.3549 7.34847 17.6516C6.64521 16.9484 6.25012 15.9946 6.25012 15V5.625C6.25012 4.63044 6.64521 3.67661 7.34847 2.97335C8.05173 2.27009 9.00556 1.875 10.0001 1.875C10.9947 1.875 11.9485 2.27009 12.6518 2.97335C13.355 3.67661 13.7501 4.63044 13.7501 5.625V15ZM10.0001 0C8.50828 0 7.07754 0.592632 6.02265 1.64752C4.96775 2.70242 4.37512 4.13316 4.37512 5.625V15C4.37512 16.4918 4.96775 17.9226 6.02265 18.9775C7.07754 20.0324 8.50828 20.625 10.0001 20.625C11.492 20.625 12.9227 20.0324 13.9776 18.9775C15.0325 17.9226 15.6251 16.4918 15.6251 15V5.625C15.6251 4.13316 15.0325 2.70242 13.9776 1.64752C12.9227 0.592632 11.492 0 10.0001 0V0Z"
        fill="currentColor"
      />
    </svg>
  );
}
