import React, { SVGProps } from "react";

export default function ArrowIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.119 19.3806L6.32174 12.5833H21.4996V9.41667H6.32174L13.119 2.61942L10.8802 0.380585L0.260742 11L10.8802 21.6194L13.119 19.3806Z"
        fill="currentColor"
      />
    </svg>
  );
}
