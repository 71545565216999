import React from "react";

import { UseModal } from "../../../components/Modal";

import { InfoButton } from "./HeaderComponents";
import TutorialModal from "./TutorialModal";

export default function TutorialButtonModal({ ...props }: UseModal) {
  return (
    <>
      <InfoButton className="mr-2" onClick={props.onOpen} />
      <TutorialModal {...props} />
    </>
  );
}
