import React, { useEffect } from "react";

import Modal, { ModalHeader, UseModal } from "../../../components/Modal";
import HelpIcon from "../../../components/icons/HelpIcon";
import { useSelector } from "../../../hooks/storeHooks";
import { connectRules, oddOneOutRules, puzzleRules, yesNoGameRules } from "../assets/gameRules";

type GameRulesModalProps = {
  game: "Slagalica" | "Spajalica" | "Izbaci Uljeza" | "Pitalica";
} & UseModal;

export default function GameRulesModal({ game, ...props }: GameRulesModalProps) {
  const tutorial = useSelector((state) => state.settings.settings.tutorial);
  const { isOpen, onOpen, onClose } = props;

  useEffect(() => {
    if (tutorial) {
      onOpen();
    }
  }, [onOpen, tutorial]);

  let text;

  switch (game) {
    case "Slagalica":
      text = puzzleRules;
      break;
    case "Spajalica":
      text = connectRules;
      break;
    case "Izbaci Uljeza":
      text = oddOneOutRules;
      break;
    case "Pitalica":
      text = yesNoGameRules;
      break;
  }

  return (
    <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose} className="font-semibold w-3/4 md:3/5 lg:w-1/3 mx-auto">
      <ModalHeader
        onClose={onClose}
        variant="primary"
        title={`Upute - ${game}`}
        titleIcon={<HelpIcon width={20} height={20} />}
      />
      <div className="flex flex-col">
        <div className="px-8 py-4">
          <div className="flex justify-center w-full text-center text-lg font-normal p-6 pt-4">{text}</div>
        </div>
      </div>
    </Modal>
  );
}
