import React, { SVGProps } from "react";
export default function UserIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17 7.625C17 9.08369 16.4205 10.4826 15.3891 11.5141C14.3576 12.5455 12.9587 13.125 11.5 13.125C10.0413 13.125 8.64236 12.5455 7.61091 11.5141C6.57946 10.4826 6 9.08369 6 7.625C6 6.16631 6.57946 4.76736 7.61091 3.73591C8.64236 2.70446 10.0413 2.125 11.5 2.125C12.9587 2.125 14.3576 2.70446 15.3891 3.73591C16.4205 4.76736 17 6.16631 17 7.625V7.625Z"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 17.25C8.94729 17.25 6.49913 18.2641 4.6941 20.0691C2.88906 21.8741 1.875 24.3223 1.875 26.875H21.125C21.125 24.3223 20.1109 21.8741 18.3059 20.0691C16.5009 18.2641 14.0527 17.25 11.5 17.25V17.25Z"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
