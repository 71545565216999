import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageResponseData } from "../../api/types";
import { Header } from "../../components/Header";
import ImageContainer from "../../components/ImageContainer";
import { useModal } from "../../components/Modal";
import PageCont from "../../components/PageCont";
import PageContHeader from "../../components/PageContHeader";
import ReturnButton from "../../components/componentsImpl/ReturnButton";
import { useSelector } from "../../hooks/storeHooks";
import SettingsButtonModal from "../Settings/components/SettingsButtonModal";
import { singleStoryAction } from "../Stories/store/singleStorySlice";
import { LocalStory } from "../Stories/store/storiesSlice";

import { soundCorrect } from "./assets/soundCorrect";
import { soundWrong } from "./assets/soundWrong";
import GameRulesButtonModal from "./components/GameRulesButtonModal";
import PlayConnectGame from "./util/playConnectGame";
import PlayConnectGameLocalStory from "./util/playConnectGameLocalStory";
import { randomIntFromInterval } from "./util/randomNumberGenerator";
import { shuffle } from "./util/shuffleFunction";

export default function ConnectGame() {
  const modal = useModal();
  const history = useHistory();
  const dispatch = useDispatch();

  const [opacityLevel, setOpacityLevel] = useState(100);
  const [correctText, setCorrectText] = useState("");
  const [leftImage, setLeftImage] = useState(<ImageContainer image="" />);
  const [rightImage, setRightImage] = useState(<ImageContainer image="" />);

  const leftImageRef = useRef(<ImageContainer image="" />);
  const rightImageRef = useRef(<ImageContainer image="" />);
  const enabledOnClickRef = useRef(true);
  const correctCardIndexRef = useRef(0);
  const shuffledArrayRef = useRef([] as PageResponseData[]);
  const currentIndexRef = useRef(0);
  const wrongAudioRef = useRef(new Audio(soundWrong));
  const correctAudioRef = useRef(new Audio(soundCorrect));

  const gameRulesModal = useModal();
  const { bgColor, textColor, audioOn, uppercase } = useSelector((state) => state.settings.settings);
  const story = useSelector((state) => state.singleStory.story);
  const localStories = useSelector((state) => state.stories.localStories);
  const jwt = useSelector((state) => state.registrationLogin.authenticationData.jwt);

  const imageContainerClassName = cx("object-contain cursor-pointer imageContainerMedium");

  const { playConnect } = PlayConnectGame();
  const { playConnectLocal } = PlayConnectGameLocalStory();

  const textClassName = cx(`text-${textColor} text-2xl mt-10`, {
    uppercase: uppercase,
  });

  useEffect(() => {
    const id = window.location.href.split("/").pop() ?? "-1";

    if (story == undefined || story.id.toString() != id) {
      shuffledArrayRef.current = [] as PageResponseData[];
      dispatch(singleStoryAction.resetStory());
      if (localStories.map((story) => story.id.toString()).includes(id)) {
        const localStory = localStories.find((story) => story.id.toString() == id) ?? ({} as LocalStory);
        playConnectLocal(localStory);
      } else {
        playConnect(Number(id), jwt);
      }
    }
  }, [dispatch]);

  if (!audioOn) {
    correctAudioRef.current.pause();
    wrongAudioRef.current.pause();
  }

  const displayCards = () => {
    if (shuffledArrayRef.current.length > 0) {
      const correctPageImage = shuffledArrayRef.current[currentIndexRef.current].image;
      const wrongPageIndex = randomIntFromInterval(0, shuffledArrayRef.current.length - 1, currentIndexRef.current);
      const wrongPageImage = shuffledArrayRef.current[wrongPageIndex].image;

      setCorrectText(shuffledArrayRef.current[currentIndexRef.current].text);

      const correctImage = (
        <ImageContainer
          image={correctPageImage}
          imageClassName={imageContainerClassName}
          containerSize="medium"
          onClick={onClickCorrectAnswer}
          imageAudio={true}
        />
      );

      const wrongImage = (
        <ImageContainer
          image={wrongPageImage}
          imageClassName={imageContainerClassName}
          containerSize="medium"
          imageAudio={true}
          onClick={onClickWrongAnswer}
        />
      );

      correctCardIndexRef.current = randomIntFromInterval(0, 1);

      if (correctCardIndexRef.current) {
        rightImageRef.current = correctImage;
        leftImageRef.current = wrongImage;
      } else {
        rightImageRef.current = wrongImage;
        leftImageRef.current = correctImage;
      }
      setRightImage(rightImageRef.current);
      setLeftImage(leftImageRef.current);
    }
  };

  const onClickCorrectAnswer = () => {
    if (enabledOnClickRef.current) {
      enabledOnClickRef.current = false;

      setTimeout(() => setOpacityLevel(0), 750);
      correctAudioRef.current.play();

      if (correctCardIndexRef.current) {
        rightImageRef.current = React.cloneElement(rightImageRef.current, { answer: "correct" });
        setRightImage(rightImageRef.current);
      } else {
        leftImageRef.current = React.cloneElement(leftImageRef.current, { answer: "correct" });
        setLeftImage(leftImageRef.current);
      }

      setTimeout(() => {
        enabledOnClickRef.current = true;
      }, 2250);

      setTimeout(() => {
        if (currentIndexRef.current === shuffledArrayRef.current.length - 1) {
          history.push("/games/endgame");
        } else {
          currentIndexRef.current = currentIndexRef.current + 1;

          displayCards();

          setOpacityLevel(100);
        }
      }, 1750);
    }
  };

  const onClickWrongAnswer = () => {
    if (enabledOnClickRef.current) {
      enabledOnClickRef.current = false;
      wrongAudioRef.current.play();

      setTimeout(() => {
        if (!correctCardIndexRef.current) {
          rightImageRef.current = React.cloneElement(rightImageRef.current, { answer: undefined });
          setRightImage(rightImageRef.current);
        } else {
          leftImageRef.current = React.cloneElement(leftImageRef.current, { answer: undefined });
          setLeftImage(leftImageRef.current);
        }
        enabledOnClickRef.current = true;
      }, 700);

      if (!correctCardIndexRef.current) {
        rightImageRef.current = React.cloneElement(rightImageRef.current, { answer: "wrongTransition" });
        setRightImage(rightImageRef.current);
      } else {
        leftImageRef.current = React.cloneElement(leftImageRef.current, { answer: "wrongTransition" });
        setLeftImage(leftImageRef.current);
      }
    }
  };

  useEffect(() => {
    shuffledArrayRef.current = shuffle(Object.assign([], story.pages.content)) as PageResponseData[];

    displayCards();
  }, [story]);

  const onClickRedirect = () => {
    history.push("/games/spajalica");
  };

  return (
    <PageCont>
      {shuffledArrayRef.current.length > 0 && story.pages.content.length > 0 && (
        <div className={`flex flex-col w-screen h-screen overflow-x-hidden items-center bg-${bgColor}`}>
          <Header />
          <PageContHeader
            title={`Spajalica: ${story.title}`}
            titleClassName="mr-36 text-center"
            isGameOrStory={true}
            rightButtons={[
              <GameRulesButtonModal game="Spajalica" key={1} {...gameRulesModal} />,
              <SettingsButtonModal key={2} {...modal} />,
            ]}
            leftButtons={<ReturnButton variant="secondary" text="Odaberi priču" onClick={onClickRedirect} />}
            className="w-full items-center mt-8 pr-8 pl-28"
          />
          <div
            className={`flex flex-col justify-center items-center  transition-opacity duration-1000 ease-out opacity-${opacityLevel}`}
          >
            <div className="flex flex-row justify-center gap-x-16">
              {leftImage}
              {rightImage}
            </div>

            <div className={textClassName}>{correctText}</div>
          </div>
        </div>
      )}
    </PageCont>
  );
}
