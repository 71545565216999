import { useEffect, useRef, useState } from "react";

type UsePlayAudioProps = {
  audioPreview?: string;
  setAudioPreview: (value: string) => void;
};

export default function usePlayAudio({ audioPreview, setAudioPreview }: UsePlayAudioProps) {
  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(true);

  const audioRef = useRef(new Audio());

  useEffect(() => {
    audioRef.current = new Audio(audioPreview);
    setPlaying(false);
    setPaused(true);
  }, [audioPreview]);

  const playAudio = () => {
    if (audioPreview) {
      if (playing) {
        setPaused(false);
        audioRef.current.play();
        return;
      }
      setPlaying(true);
      setPaused(false);

      audioRef.current.play();
      audioRef.current.onended = () => {
        setPlaying(false);
        setPaused(true);
      };
    }
  };

  const pauseAudio = () => {
    setPaused(true);
    audioRef.current.pause();
  };

  const deleteAudio = () => {
    if (playing) {
      audioRef.current.pause();
    }
    setAudioPreview("");
  };

  return { playAudio, pauseAudio, deleteAudio, paused };
}
