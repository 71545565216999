import React, { SVGProps } from "react";
export default function ArrowRepeatIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M137 11.4167V51.375C137 52.921 136.435 54.2589 135.305 55.3887C134.176 56.5184 132.838 57.0833 131.292 57.0833H91.3333C88.8359 57.0833 87.0818 55.8941 86.071 53.5156C85.0601 51.1966 85.4763 49.1452 87.3197 47.3613L99.6283 35.0527C90.8279 26.9065 80.4518 22.8333 68.5 22.8333C62.316 22.8333 56.4144 24.0374 50.7952 26.4456C45.1761 28.8538 40.3151 32.1094 36.2122 36.2122C32.1094 40.3151 28.8538 45.1761 26.4456 50.7952C24.0374 56.4144 22.8333 62.316 22.8333 68.5C22.8333 74.684 24.0374 80.5856 26.4456 86.2048C28.8538 91.8239 32.1094 96.6849 36.2122 100.788C40.3151 104.891 45.1761 108.146 50.7952 110.554C56.4144 112.963 62.316 114.167 68.5 114.167C75.576 114.167 82.2654 112.621 88.5684 109.529C94.8713 106.437 100.193 102.066 104.534 96.4173C104.95 95.8227 105.634 95.4659 106.585 95.347C107.477 95.347 108.22 95.6146 108.815 96.1497L121.035 108.458C121.57 108.934 121.852 109.544 121.882 110.287C121.912 111.03 121.689 111.699 121.213 112.294C114.732 120.143 106.883 126.223 97.666 130.534C88.4494 134.845 78.7274 137 68.5 137C59.224 137 50.3642 135.186 41.9206 131.559C33.477 127.932 26.1929 123.056 20.0684 116.932C13.9438 110.807 9.06793 103.523 5.44076 95.0794C1.81359 86.6359 0 77.776 0 68.5C0 59.224 1.81359 50.3642 5.44076 41.9206C9.06793 33.477 13.9438 26.1929 20.0684 20.0684C26.1929 13.9438 33.477 9.06793 41.9206 5.44076C50.3642 1.81359 59.224 0 68.5 0C77.2409 0 85.6993 1.65007 93.8753 4.9502C102.051 8.25033 109.321 12.9032 115.683 18.9089L127.278 7.403C129.002 5.55968 131.084 5.14345 133.521 6.1543C135.84 7.16515 137 8.91927 137 11.4167Z"
        fill="currentColor"
      />
    </svg>
  );
}
