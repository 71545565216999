import { stories } from "./api-paths";

export async function getSingleStory(storyId: string, queryParams: string, jwt?: string): Promise<Response> {
  return await fetch(`${stories.getSingle(storyId)}${queryParams ? `?${queryParams}` : ""}`, {
    method: "GET",
    headers: {
      "X-Authorization": jwt ? "Bearer " + jwt : "",
    },
  });
}
