import { useField } from "formik";
import React, { ChangeEventHandler, DetailedHTMLProps, InputHTMLAttributes, useState } from "react";

import Field from "../components/Field";

import EyeIcon from "./icons/EyeIcon";

type InputProps = {
  label: string;
  name: string;
  textArea?: boolean;
  maxLength?: number;
  labelClassName?: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export default function Input({
  label,
  name,
  type,
  textArea = false,
  maxLength = 100,
  labelClassName,
  ...props
}: InputProps) {
  const [field, meta] = useField(name);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Field label={label} error={meta.touched && meta.error ? meta.error : ""} labelClassName={labelClassName}>
      <div className="flex flex-row">
        {textArea ? (
          <div className="flex flex-col items-end">
            <textarea
              {...field}
              maxLength={maxLength}
              onInput={props.onInput as unknown as ChangeEventHandler<HTMLTextAreaElement>}
              className="border rounded rounded-small w-72 h-32 text-xs border-darkGray outline-none m-1 p-2 resize-none overflow-hidden"
            />
            <p className="pr-1">
              Preostalo znakova: {maxLength - field.value.length}/{maxLength}
            </p>
          </div>
        ) : (
          <>
            <input
              {...field}
              {...props}
              maxLength={maxLength}
              className="w-full rounded-small border border-gray h-10 sans p-4 text-base font-normal"
              type={type !== "password" ? type : passwordShown ? "text" : "password"}
            />
            {type === "password" && (
              <EyeIcon
                onClick={togglePasswordVisiblity}
                className="mt-2.5 -ml-10 text-darkGray"
                width={23}
                height={18}
              />
            )}
          </>
        )}
      </div>
    </Field>
  );
}
