import { useFormikContext } from "formik";
import React from "react";

import { Settings } from "../../../api/types";
import Grid from "../../../components/Grid";
import ConfigIcon from "../../../components/icons/ConfigIcon";
import ImageDoubleIcon from "../../../components/icons/ImageDoubleIcon";
import ImageSingleIcon from "../../../components/icons/ImageSingleIcon";
import ImageSixIcon from "../../../components/icons/ImageSixIcon";
import PaletteIcon from "../../../components/icons/PaletteIcon";
import TextIcon from "../../../components/icons/TextIcon";

import ColorButton from "./ColorButton";
import ColorPicker from "./ColorPicker";
import FramedIconButton from "./FramedIconButton";
import ToggleButton from "./ToggleButton";
import UppercaseToggleButton from "./UppercaseToggleButton";

export default function SettingsFormFields() {
  const { setFieldValue, values } = useFormikContext<Settings>();

  return (
    <Grid container className="w-full pl-10 sm:pl-12 md:pl-14 lg:pl-20 sm:pr-4 md:pr-6 lg:pr-12 relative">
      <Grid item className="ml-5">
        <Grid container className="mt-6">
          <Grid item>
            <div className="flex flex-nowrap items-center absolute z-10">
              <PaletteIcon className="-ml-14" width={30} height={30} />
              <p className="font-bold text-2xl ml-3">Boje</p>
            </div>
          </Grid>
          <Grid item className="mt-11">
            <Grid container className="md:flex-nowrap items-center justify-between">
              <div className="w-4/12">
                <p>Pozadina</p>
              </div>
              <div className="flex flex-nowrap justify-start md:justify-end">
                <ColorButton name="bgColor" value="primary" onClick={() => setFieldValue("bgColor", "primary")} />
                <ColorButton name="bgColor" value="secondary" onClick={() => setFieldValue("bgColor", "secondary")} />
                <ColorButton name="bgColor" value="background" onClick={() => setFieldValue("bgColor", "background")} />
                <ColorButton name="bgColor" value="black" onClick={() => setFieldValue("bgColor", "black")} />
                <ColorPicker setFieldValue={setFieldValue} value="bgColor" />
              </div>
            </Grid>
            <Grid container className="md:flex-nowrap items-center justify-between">
              <div className="w-4/12">
                <p>Okvir</p>
              </div>
              <div className="flex flex-nowrap justify-start md:justify-end">
                <ColorButton name="frameColor" value="primary" onClick={() => setFieldValue("frameColor", "primary")} />
                <ColorButton
                  name="frameColor"
                  value="secondary"
                  onClick={() => setFieldValue("frameColor", "secondary")}
                />
                <ColorButton
                  name="frameColor"
                  value="background"
                  onClick={() => setFieldValue("frameColor", "background")}
                />
                <ColorButton name="frameColor" value="black" onClick={() => setFieldValue("frameColor", "black")} />
                <ColorPicker setFieldValue={setFieldValue} value="frameColor" />
              </div>
            </Grid>
            <Grid container className="md:flex-nowrap items-center justify-between">
              <div className="w-4/12">
                <p>Tekst</p>
              </div>
              <div className="flex flex-nowrap justify-start md:justify-end">
                <ColorButton name="textColor" value="primary" onClick={() => setFieldValue("textColor", "primary")} />
                <ColorButton
                  name="textColor"
                  value="secondary"
                  onClick={() => setFieldValue("textColor", "secondary")}
                />
                <ColorButton
                  name="textColor"
                  value="background"
                  onClick={() => setFieldValue("textColor", "background")}
                />
                <ColorButton name="textColor" value="black" onClick={() => setFieldValue("textColor", "black")} />
                <ColorPicker setFieldValue={setFieldValue} value="textColor" />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className="ml-5">
        <Grid container className="mt-6">
          <Grid item>
            <div className="flex flex-nowrap items-center absolute z-10">
              <TextIcon className="-ml-14" width={30} height={30} />
              <p className="font-bold text-2xl ml-3">Slova</p>
            </div>
          </Grid>
          <Grid item className="mt-11">
            <Grid container className="flex-nowrap justify-between">
              <div>
                <p>Vrsta</p>
              </div>
              <div className="w-12 h-12">
                <UppercaseToggleButton name="uppercase" onClick={() => setFieldValue("uppercase", !values.uppercase)} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className="ml-5">
        <Grid container>
          <Grid item>
            <div className="flex flex-nowrap items-center absolute z-10">
              <ConfigIcon className="-ml-14" width={30} height={30} />
              <p className="font-bold text-2xl ml-3">Ostalo</p>
            </div>
          </Grid>
          <Grid item className="mt-11">
            <Grid container className="flex-nowrap justify-between">
              <div>
                <p>Zvuk</p>
              </div>
              <div className="w-12 h-12">
                <ToggleButton name="audioOn" onClick={() => setFieldValue("audioOn", !values.audioOn)} />
              </div>
            </Grid>
            <Grid container className="flex-nowrap justify-between">
              <div>
                <p>Okvir</p>
              </div>
              <div className="w-12 h-12">
                <ToggleButton name="framed" onClick={() => setFieldValue("framed", !values.framed)} />
              </div>
            </Grid>
            <Grid container className="flex-nowrap justify-between">
              <div>
                <p>Tutorial za igre</p>
              </div>
              <div className="w-12 h-12">
                <ToggleButton name="tutorial" onClick={() => setFieldValue("tutorial", !values.tutorial)} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className="ml-2 -mr-2.5">
        <Grid container className="pl-2">
          <Grid item>
            <Grid container className="md:flex-nowrap items-center justify-between">
              <div className="lg:w-5/12 mr-2 mb-1">
                <p className="whitespace-pre">Broj slika u pričama</p>
              </div>
              <Grid
                container
                className="flex-grow sm:flex-nowrap justify-center sm:justify-end lg:justify-between -ml-1 mr-1 lg:px-10 sm:w-9/12"
              >
                <FramedIconButton
                  name="numberOfPages"
                  value={1}
                  onClick={() => setFieldValue("numberOfPages", 1)}
                  text="Jedna"
                >
                  <ImageSingleIcon width={30} height={30} />
                </FramedIconButton>
                <FramedIconButton
                  name="numberOfPages"
                  value={2}
                  onClick={() => setFieldValue("numberOfPages", 2)}
                  text="Dvije"
                >
                  <ImageDoubleIcon width={50} height={50} />
                </FramedIconButton>
                <FramedIconButton
                  name="numberOfPages"
                  value={6}
                  onClick={() => setFieldValue("numberOfPages", 6)}
                  text="Šest"
                >
                  <ImageSixIcon width={50} height={50} />
                </FramedIconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
