import React, { SVGProps } from "react";
export default function CorrectAnswerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 88 88" color="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M44 0.666748C35.4295 0.666748 27.0515 3.2082 19.9253 7.96973C12.7992 12.7313 7.24506 19.499 3.96526 27.4171C0.685463 35.3353 -0.172682 44.0482 1.49934 52.454C3.17137 60.8598 7.29847 68.5811 13.3587 74.6414C19.419 80.7017 27.1403 84.8288 35.5461 86.5008C43.952 88.1728 52.6649 87.3147 60.583 84.0349C68.5011 80.7551 75.2689 75.2009 80.0304 68.0748C84.7919 60.9487 87.3334 52.5706 87.3334 44.0001C87.3334 38.3095 86.2125 32.6746 84.0348 27.4171C81.8571 22.1597 78.6652 17.3827 74.6413 13.3588C70.6175 9.33492 65.8404 6.14301 60.583 3.9653C55.3255 1.7876 49.6907 0.666748 44 0.666748V0.666748ZM62.6334 33.6434L42.83 59.6434C42.4264 60.1678 41.9079 60.5928 41.3145 60.8857C40.7211 61.1787 40.0685 61.3318 39.4067 61.3334C38.7485 61.337 38.0981 61.1905 37.505 60.9052C36.9118 60.6199 36.3915 60.2032 35.9834 59.6868L25.41 46.2101C25.0601 45.7605 24.8021 45.2464 24.6508 44.6972C24.4995 44.1479 24.4579 43.5742 24.5283 43.0089C24.5987 42.4435 24.7798 41.8976 25.0612 41.4022C25.3426 40.9068 25.7188 40.4717 26.1684 40.1218C27.0763 39.4149 28.2278 39.0978 29.3696 39.24C29.9349 39.3104 30.4809 39.4915 30.9763 39.7729C31.4716 40.0543 31.9067 40.4305 32.2567 40.8801L39.32 49.8934L55.7 28.2268C56.0472 27.7715 56.4806 27.3891 56.9755 27.1013C57.4704 26.8136 58.0172 26.6261 58.5845 26.5497C59.1519 26.4732 59.7288 26.5093 60.2822 26.6557C60.8356 26.8022 61.3548 27.0563 61.81 27.4034C62.2653 27.7505 62.6477 28.1839 62.9355 28.6789C63.2232 29.1738 63.4107 29.7205 63.4871 30.2879C63.5636 30.8553 63.5275 31.4321 63.381 31.9856C63.2346 32.539 62.9805 33.0582 62.6334 33.5134V33.6434Z"
        fill="currentColor"
      />
    </svg>
  );
}
