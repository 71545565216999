import cx from "classnames";
import { useField } from "formik";
import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";

type UppercaseToggleButtonProps = {
  name: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export default function UppercaseToggleButton({ name, className, ...props }: UppercaseToggleButtonProps) {
  const [field] = useField({ name: name, type: "checkbox" });

  const uppercaseToggleClassName = cx(className, "flex items-center cursor-pointer mr-12");
  const uppercaseRadioClassName = cx("w-3.5 h-3.5 mr-2  rounded border border-primary", {
    "bg-primary": field.checked,
  });
  const lowerRadioClassName = cx("w-3.5 h-3.5 mr-2 ml-4  rounded border border-primary ", {
    "bg-primary": !field.checked,
  });

  return (
    <div className="flex items-center justify-center w-full mb-12" {...props}>
      <div className={uppercaseToggleClassName}>
        <input {...field} type="checkbox" className="sr-only" />
        {/*<!--span className="flex flex-row font-bold text-xl">
          &nbsp;/&nbsp;<p className={lowerLetterClassName}>a</p>
        </span-->*/}
        <div className={uppercaseRadioClassName}></div>
        <span className="mr-4">A</span>
        <div className={lowerRadioClassName}></div>
        <span className="mr-5">a</span>
      </div>
    </div>
  );
}
