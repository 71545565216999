import React from "react";

type LinkProps = {
  title: string;
  link: string;
};

type LinksContainerProps = {
  links: LinkProps[];
};

export default function LinksContainer({ links }: LinksContainerProps) {
  return (
    <div className="flex flex-col justify-start">
      <div className="font-semibold text-lg">Brzi linkovi</div>
      <div className="text-xs mb-2">Stranice na kojima možete pristupiti besplatnim fotografijama</div>
      {links.map((linkProps, index) => {
        return (
          <div key={index} className="flex text-sm underline hover:text-secondary flex-row justify-start">
            <a href={linkProps.link} target="_blank" rel="noreferrer">
              {linkProps.title}
            </a>
          </div>
        );
      })}
    </div>
  );
}
