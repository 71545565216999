import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import ConnectGame from "./pages/Games/ConnectGame";
import EndGame from "./pages/Games/EndGame";
import Games from "./pages/Games/Games";
import OddOneOut from "./pages/Games/OddOneOut";
import SlagalicaGame from "./pages/Games/SlagalicaGame";
import StorySelection from "./pages/Games/StorySelection";
import YesNoGame from "./pages/Games/YesNoGame";
import SingleStory from "./pages/Stories/SingleStory";
import Stories from "./pages/Stories/Stories";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact={true}>
        <Redirect to="/stories" />
      </Route>

      <Route path="/stories" exact={true}>
        <Stories />
      </Route>

      <Route path="/games" exact={true}>
        <Games />
      </Route>

      <Route path="/games/endgame" exact={true}>
        <EndGame />
      </Route>

      <Route path="/games/:id" exact={true}>
        <StorySelection />
      </Route>

      <Route path="/games/spajalica/:id" exact={true}>
        <ConnectGame />
      </Route>

      <Route path="/games/pitalica/:id">
        <YesNoGame />
      </Route>

      <Route path="/stories/:id" exact={true}>
        <SingleStory />
      </Route>

      <Route path="/games/izbaci_uljeza/:id" exact={true}>
        <OddOneOut />
      </Route>

      <Route path="/games/slagalica/:id" exact={true}>
        <SlagalicaGame />
      </Route>
    </Switch>
  );
}
