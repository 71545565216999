import { ErrorResponse } from "./errorType";

export async function fetchWrapper<T>(action: Promise<Response>) {
  const response = await action;
  const data = await response.json();
  if (!response.ok) {
    throw data as ErrorResponse;
  }
  return data as T;
}

export function serializeQueryProps({ ...props } = {}): string {
  let queryParams = "";
  for (const [key, value] of Object.entries(props)) {
    if (value !== undefined) {
      queryParams = queryParams.concat(`${key}=${value}&`);
    }
  }

  if (queryParams.length > 0) {
    queryParams = queryParams.substr(0, queryParams.length - 1);
  }

  return queryParams;
}
