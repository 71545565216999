import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { PageRequestData, PageResponseData } from "../../api/types";
import { useModal, UseModal } from "../../components/Modal";
import { useSelector } from "../../hooks/storeHooks";
import { serializeQueryProps } from "../../store/storeUtils";
import { makeToast } from "../../util/makeToast";
import SaveStoryModalInfo from "../Stories/components/SaveStoryModalInfo";
import { LocalStory, storiesAction, updateLocalStory } from "../Stories/store/storiesSlice";

import StoryModal from "./components/StoryModal";

type UpdateStoryModalProps = {
  modal: UseModal;
  showSaveStoryModalInfo: () => void;
};

export default function UpdateStoryModal({ modal, showSaveStoryModalInfo }: UpdateStoryModalProps) {
  const dispatch = useDispatch();
  const story = useSelector((state) => state.singleStory.story);
  const jwt = useSelector((state) => state.registrationLogin.authenticationData.jwt);
  const [pageList, setPageList] = useState<PageRequestData[]>(Object.assign([], story.pages.content));
  const loggedInUser = useSelector((state) => state.registrationLogin.loggedIn);
  const storyStatus = useSelector((state) => state.stories.status);

  const updateStory = async (storyName: string) => {
    if (loggedInUser) {
      await dispatch(
        storiesAction.updateStory({
          storyId: story.id.toString(),
          story: {
            title: storyName,
            coverPage: pageList[0].image,
            pages: pageList,
          },
          jwt: jwt,
        })
      );
      if (storyStatus == "success") {
        makeToast("Promjene pohranjene.");
      }
      dispatch(storiesAction.getAllStories({ queryParams: serializeQueryProps(), jwt: jwt }));
    } else {
      const tempLocalStory = { pages: [] as PageResponseData[] } as LocalStory;
      const tempRequestPages = pageList;

      tempLocalStory.coverPage = pageList[0].image;
      tempLocalStory.title = storyName;
      tempLocalStory.favorite = !!story.favorite;
      tempLocalStory.id = story.id;

      let pageIdNumber = tempLocalStory.id + 1;

      let pageOrder = 1;
      tempRequestPages.forEach((value) => {
        const tempPage = {} as PageResponseData;
        tempPage.text = value.text;
        tempPage.image = value.image;
        tempPage.audio = value.audio ?? "";
        tempPage.storyId = tempLocalStory.id;
        tempPage.id = pageIdNumber;
        tempPage.pageOrder = pageOrder;
        pageOrder++;
        pageIdNumber++;

        tempLocalStory.pages.push(tempPage);
      });

      dispatch(updateLocalStory(tempLocalStory));
      showSaveStoryModalInfo();
      //makeToast("Promjene pohranjene lokalno. Kako ne biste izgubili promjene, izvezite svoje priče.");
    }

    modal.onClose();
  };

  return (
    <StoryModal
      modalTitle={`Uredi priču: ${story.title}`}
      storyTitle={story.title}
      onSubmitFunction={updateStory}
      pageList={pageList}
      setPageList={setPageList}
      modal={modal}
    />
  );
}
