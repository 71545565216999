import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";

import { useModal } from "./Modal";
import YesNoModal from "./YesNoModal";
import HeartIcon from "./icons/HearIcon";
import PencilIcon from "./icons/PencilIcon";
import PlusIcon from "./icons/PlusIcon";
import TrashIcon from "./icons/TrashIcon";

type CardComponentProps = {
  variant: "image" | "add";
  title?: string;
  image?: string; //base64 encoded
  rounded?: boolean;
  activeEdit?: boolean;
  deleteFunction?: () => void;
  editFunction?: () => void;
  favorite?: boolean;
  favourable?: boolean;
  changeFavoriteFunction?: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function CardComponent({
  title,
  image,
  className,
  variant,
  onClick,
  activeEdit = false,
  deleteFunction,
  editFunction,
  favorite = false,
  favourable = false,
  changeFavoriteFunction,
  ...props
}: CardComponentProps) {
  const yesNoModal = useModal();

  const cardClassName = cx(
    className,
    "relative w-40 h-48 inline-block  rounded cursor-pointer transform transition duration-500 ease-in-out hover:-translate-y-1 hover:shadow-primary"
  );

  const activeEditCardClassName = cx(className, "bg-lightBlack opacity-70 w-40 h-48 inline-block rounded transform");

  const titleClassName = cx("absolute text-white font-semibold p-2 w-full truncate", {
    "bottom-2.5": variant === "image",
    "top-24 left-6": variant === "add",
  });

  const heartIconClassName = cx("text-white cursor-pointer", {
    "fill-current": favorite,
  });

  return (
    <div
      onClick={!activeEdit && variant === "add" ? onClick : undefined}
      className={activeEdit && variant === "image" ? activeEditCardClassName : cardClassName}
      {...props}
    >
      {variant === "image" ? (
        activeEdit ? (
          <div className="flex flex-col justify-start items-center">
            <div className="flex flex-row justify-between m-3 gap-x-6 relative z-10">
              <div className="flex flex-col items-center justify-center">
                {yesNoModal.isOpen && (
                  <YesNoModal
                    message="Jeste li sigurni da želite obrisati ovu priču? Jednom obrisanu priču nije moguće vratiti."
                    onYes={() => deleteFunction?.()}
                    modal={yesNoModal}
                    className="w-400px"
                  />
                )}
                <TrashIcon
                  width={20}
                  height={22.5}
                  className="text-white cursor-pointer"
                  onClick={() => {
                    yesNoModal.onOpen();
                  }}
                />
                <p className="text-white text-center text-xs">Izbriši</p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <PencilIcon
                  width={20}
                  height={22.5}
                  className="text-white cursor-pointer ml-0.5"
                  onClick={editFunction}
                />
                <p className="text-white text-center text-xs">Uredi</p>
              </div>
            </div>
            <img
              className="absolute object-cover h-full w-full rounded filter brightness-25 contrast-125"
              src={`data:image/png;base64, ${image}`}
              alt="Card image"
            />
          </div>
        ) : (
          <>
            <img
              className="absolute object-cover h-full w-full rounded"
              src={`data:image/png;base64, ${image}`}
              alt="Card image"
            />

            <div className="absolute gradientDiv rounded">
              <div className="group relative left-12 top-2 flex flex-col items-center justify-center">
                {favourable == true && (
                  <div className="flex flex-col items-center ml-4">
                    <div
                      className="w-8 h-8 rounded bg-lightBlack opacity-80 flex flex-col items-center justify-center"
                      onClick={() => changeFavoriteFunction?.()}
                    >
                      <HeartIcon width={15} height={16} className={heartIconClassName} />
                    </div>
                    <div className="opacity-0 group-hover:opacity-80 bg-lightBlack rounded mt-1 w-12">
                      <p className="text-white text-center text-xs">Favorit</p>
                    </div>
                  </div>
                )}
              </div>
              <div
                onClick={activeEdit && variant === "image" ? undefined : onClick}
                className="absolute w-full h-4/5"
              />
            </div>
          </>
        )
      ) : (
        <PlusIcon className="text-white absolute top-12 right-14" width={48} height={48} />
      )}

      <div className={titleClassName}>{title ? title : "Dodaj priču"}</div>
    </div>
  );
}
