import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/Button";
import { Header } from "../../components/Header";
import { useModal } from "../../components/Modal";
import PageCont from "../../components/PageCont";
import PageContHeader from "../../components/PageContHeader";
import ReturnButton from "../../components/componentsImpl/ReturnButton";
import CorrectAnswerIcon from "../../components/icons/CorrectAnswerIcon";
import CrossIcon from "../../components/icons/CrossIcon";
import TickIcon from "../../components/icons/TickIcon";
import WrongAnswerIcon from "../../components/icons/WrongAnswerIcon";
import { useSelector } from "../../hooks/storeHooks";
import SettingsButtonModal from "../Settings/components/SettingsButtonModal";

import { soundCorrect } from "./assets/soundCorrect";
import { soundWrong } from "./assets/soundWrong";
import GameRulesButtonModal from "./components/GameRulesButtonModal";
import { gamesAction } from "./store/gamesSlice";
import PlayYesNoGame from "./util/playYesNoGame";
import { shuffle } from "./util/shuffleFunction";

export default function YesNoGame() {
  const modal = useModal();
  const gameRulesModal = useModal();
  const history = useHistory();
  const dispatch = useDispatch();

  const { bgColor, textColor, audioOn, uppercase } = useSelector((state) => state.settings.settings);
  const story = useSelector((state) => state.singleStory.story);
  const questions = useSelector((state) => state.games.yesNoQuestions);

  const wrongAudioRef = useRef(new Audio(soundWrong));
  const correctAudioRef = useRef(new Audio(soundCorrect));

  if (!audioOn) {
    correctAudioRef.current.pause();
    wrongAudioRef.current.pause();
  }

  const { playYesNo } = PlayYesNoGame();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [tmpQuestions, setTmpQuestions] = useState(shuffle([...questions]));
  const [opacityLevel, setOpacityLevel] = useState(100);
  const [showCorrectOverlay, setShowCorrectOverlay] = useState(false);
  const [showWrongOverlay, setShowWrongOverlay] = useState(false);
  const [wrongOverlayOpacity, setWrongOverlayOpacity] = useState(70);
  const [enabledButtons, setEnabledButtons] = useState(true);

  useEffect(() => {
    const id = window.location.href.split("/").pop() ?? "-1";

    if (story == undefined || story.id.toString() != id) {
      dispatch(gamesAction.resetYesNoQuestions());
      playYesNo(Number(id));
    }
  }, [dispatch]);

  useEffect(() => {
    setTmpQuestions(shuffle([...questions]));
  }, [questions]);

  const onClickRedirect = () => {
    history.push("/games/pitalica");
  };

  const onClickCheckAnswer = (value: boolean) => {
    if (enabledButtons) {
      setEnabledButtons(false);
      setTimeout(() => setEnabledButtons(true), 2000);
      const correctAnswer = tmpQuestions[currentQuestion].answer === "true";

      if (correctAnswer === value) {
        correctAudioRef.current.play();
        setShowCorrectOverlay(true);

        setTimeout(() => {
          setOpacityLevel(0);
        }, 1000);

        setTimeout(() => {
          setShowCorrectOverlay(false);

          setOpacityLevel(100);
          const tmpNumber = currentQuestion + 1;
          if (tmpNumber === tmpQuestions.length) {
            history.push("/games/endgame");
          }
          setCurrentQuestion(tmpNumber);
        }, 2000);

        return;
      }

      wrongAudioRef.current.play();

      setShowWrongOverlay(true);
      setTimeout(() => setWrongOverlayOpacity(0), 700);
      setTimeout(() => {
        setShowWrongOverlay(false);
        setWrongOverlayOpacity(70);
      }, 1500);
    }
  };

  return (
    <PageCont>
      {tmpQuestions.length > 0 && (
        <div className={`flex flex-col w-screen h-screen overflow-x-hidden items-center bg-${bgColor}`}>
          <Header />
          <PageContHeader
            title={`Pitalica: ${story.title}`}
            titleClassName="mr-44 text-center"
            isGameOrStory={true}
            rightButtons={[
              <GameRulesButtonModal game="Pitalica" key={1} {...gameRulesModal} />,
              <SettingsButtonModal key={2} {...modal} />,
            ]}
            leftButtons={<ReturnButton variant="secondary" text="Odaberi priču" onClick={onClickRedirect} />}
            className="w-full items-center mt-8 pr-8 pl-28"
          />

          <div
            className={`relative flex flex-col justify-start mt-10 items-center w-full transition-opacity duration-1000 ease-out opacity-${opacityLevel}`}
          >
            <div className="font-semibold text-xl">{`Pitanje ${currentQuestion + 1}/${tmpQuestions.length}`}</div>
            <div
              className={`w-5/12  h-48 mt-4 bg-white rounded shadow-darkGray font-semibold text-2xl text-center flex flex-col justify-center text-${textColor} ${
                uppercase ? "uppercase" : ""
              }`}
            >
              {showCorrectOverlay && (
                <div
                  className={`absolute flex justify-center items-center t-0 bg-secondary bg-opacity-50 flex text-align w-5/12 h-48 rounded bg-opacity`}
                >
                  <CorrectAnswerIcon width={64} color={"white"} />
                </div>
              )}

              {showWrongOverlay && (
                <div
                  className={`absolute flex justify-center items-center t-0 bg-error opacity-${wrongOverlayOpacity} transition-opacity delay-300 duration-1000  text-align w-5/12 h-48 rounded`}
                >
                  <WrongAnswerIcon width={64} color={"white"} />
                </div>
              )}

              {tmpQuestions[currentQuestion].question}
            </div>

            <div className="flex flex-row justify-between w-5/12 h-10 mt-5">
              <Button
                variant="red"
                className="w-5/12 h-14 text-2xl font-bold"
                onClick={() => onClickCheckAnswer(false)}
              >
                NE <CrossIcon className="font-bold ml-2 mt-0.5" width={18} />
              </Button>

              <Button
                variant="secondary"
                className="w-5/12 h-14 text-2xl font-bold"
                onClick={() => onClickCheckAnswer(true)}
              >
                DA <TickIcon className="font-bold ml-2 mt-0.5" width={22} />
              </Button>
            </div>
          </div>
        </div>
      )}
    </PageCont>
  );
}
