import { settings } from "./api-paths";
import { Settings } from "./types";

export async function getSettings(jwt: string): Promise<Response> {
  return await fetch(settings.settings, {
    method: "GET",
    headers: {
      "X-Authorization": "Bearer " + jwt,
    },
  });
}

export async function updateSettings(settingsDTO: Settings, jwt?: string): Promise<Response> {
  return await fetch(settings.settings, {
    method: "PUT",
    headers: {
      "X-Authorization": jwt ? "Bearer " + jwt : "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(settingsDTO),
  });
}
