import React, { useState } from "react";

import AppleIcon from "../../../components/icons/AppleIcon";
import CameraIcon from "../../../components/icons/CameraIcon";
import ImageSingleIcon from "../../../components/icons/ImageSingleIcon";

export default function ImageButtons() {
  const [activeButton, setActiveButton] = useState(-1);

  const activeButtonClassName = "border rounded rounded-small cursor-pointer p-2 border-primary text-primary mr-3";
  const inactiveButtonClassName =
    "border rounded rounded-small cursor-pointer p-2 border-secondary text-secondary mr-3";
  const buttonDivClassName = "flex flex-row items-center";

  return (
    <div className="flex flex-col justify-between mt-4 gap-y-6">
      <div className={buttonDivClassName}>
        <ImageSingleIcon
          className={activeButton === 0 ? activeButtonClassName : inactiveButtonClassName}
          width={60}
          height={60}
          onClick={() => setActiveButton(0)}
        />
        <div className={activeButton === 0 ? "text-primary" : "text-secondary"}>Učitaj</div>
      </div>

      <div className={buttonDivClassName}>
        <CameraIcon
          className={activeButton === 1 ? activeButtonClassName : inactiveButtonClassName}
          width={60}
          height={60}
          onClick={() => setActiveButton(1)}
        />
        <div className={activeButton === 1 ? "text-primary" : "text-secondary"}>Kamera</div>
      </div>

      <div className={buttonDivClassName}>
        <AppleIcon
          className={activeButton === 2 ? activeButtonClassName : inactiveButtonClassName}
          width={60}
          height={60}
          onClick={() => setActiveButton(2)}
        />
        <div className={activeButton === 2 ? "text-primary" : "text-secondary"}>Simboli</div>
      </div>
    </div>
  );
}
