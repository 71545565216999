import React, { SVGProps } from "react";
export default function PauseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.09375 0.96875C5.11318 0.96875 6.09085 1.37372 6.81169 2.09456C7.53254 2.8154 7.9375 3.79307 7.9375 4.8125V20.1875C7.9375 21.2069 7.53254 22.1846 6.81169 22.9054C6.09085 23.6263 5.11318 24.0312 4.09375 24.0312C3.07432 24.0312 2.09665 23.6263 1.37581 22.9054C0.654965 22.1846 0.25 21.2069 0.25 20.1875V4.8125C0.25 3.79307 0.654965 2.8154 1.37581 2.09456C2.09665 1.37372 3.07432 0.96875 4.09375 0.96875ZM16.9062 0.96875C17.9257 0.96875 18.9033 1.37372 19.6242 2.09456C20.345 2.8154 20.75 3.79307 20.75 4.8125V20.1875C20.75 21.2069 20.345 22.1846 19.6242 22.9054C18.9033 23.6263 17.9257 24.0312 16.9062 24.0312C15.8868 24.0312 14.9092 23.6263 14.1883 22.9054C13.4675 22.1846 13.0625 21.2069 13.0625 20.1875V4.8125C13.0625 3.79307 13.4675 2.8154 14.1883 2.09456C14.9092 1.37372 15.8868 0.96875 16.9062 0.96875Z"
        fill="white"
      />
    </svg>
  );
}
