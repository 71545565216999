import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { StoryCard } from "../../api/types";
import { CardComponent } from "../../components/CardComponent";
import StoryCardContainer from "../../components/CardComponentCont";
import { Header } from "../../components/Header";
import { useModal } from "../../components/Modal";
import PageCont from "../../components/PageCont";
import PageContHeader from "../../components/PageContHeader";
import AppSidebar from "../../components/componentsImpl/AppSiderbar";
import { useSelector } from "../../hooks/storeHooks";
import { serializeQueryProps } from "../../store/storeUtils";
import { makeToast } from "../../util/makeToast";
import SettingsButtonModal from "../Settings/components/SettingsButtonModal";
import NewStoryModal from "../StoryModals/NewStoryModal";
import UpdateStoryModal from "../StoryModals/UpdateStoryModal";

import { EditButton, SaveButton } from "./components/HeaderComponents";
import SaveStoryModalInfo from "./components/SaveStoryModalInfo";
import TutorialButtonModal from "./components/TutorialButtonModal";
import { singleStoryAction } from "./store/singleStorySlice";
import { storiesAction, removeLocalStory, changeFavoriteLocalStory, LocalStory } from "./store/storiesSlice";

export default function Stories() {
  const history = useHistory();
  const dispatch = useDispatch();
  const storyModal = useModal();
  const updateStoryModal = useModal();
  const tutorialModal = useModal();
  const settingsModal = useModal();
  const saveStoryModalInfo = useModal();
  const stories = useSelector((state) => state.stories.stories.content);
  const localStories = useSelector((state) => state.stories.localStories);
  const loggedIn = useSelector((state) => state.registrationLogin.loggedIn);
  const jwt = useSelector((state) => state.registrationLogin.authenticationData.jwt);
  const [editState, setEditState] = useState(false);
  const storiesStatus = useSelector((state) => state.stories.status);

  useEffect(() => {
    dispatch(storiesAction.getAllStories({ queryParams: serializeQueryProps(), jwt: jwt }));
  }, [dispatch, jwt]);

  const onClickRedirect = async (id: number) => {
    await dispatch(
      singleStoryAction.getSingleStory({
        storyId: id.toString(),
        queryParams: serializeQueryProps(),
        jwt: jwt,
      })
    );
    history.push(`/stories/${id}`);
  };

  const onClickRedirectLocalStory = async (card: LocalStory) => {
    await dispatch(singleStoryAction.putLocalStory(card));

    history.push(`/stories/${card.id}`);
  };

  const editStoryFunction = async (card: LocalStory) => {
    if (loggedIn) {
      await dispatch(
        singleStoryAction.getSingleStory({
          storyId: card.id.toString(),
          queryParams: serializeQueryProps(),
          jwt: jwt,
        })
      );
    } else {
      await dispatch(singleStoryAction.putLocalStory(card));
    }
    updateStoryModal.onOpen();
  };

  const deleteStoryFunction = async (card: StoryCard) => {
    if (loggedIn) {
      await dispatch(
        storiesAction.deleteStory({
          storyId: card.id.toString(),
          queryParams: serializeQueryProps(),
          jwt: jwt,
        })
      );
      if (storiesStatus == "success") {
        makeToast("Priča obrisana.");
      }
      dispatch(storiesAction.getAllStories({ queryParams: serializeQueryProps(), jwt: jwt }));
    } else {
      dispatch(removeLocalStory(card.id));
      makeToast("Priča obrisana.");
    }
  };

  const changeFavoriteStoryFunction = async (card: StoryCard) => {
    if (loggedIn) {
      await dispatch(
        storiesAction.changeFavoriteStory({
          storyId: card.id.toString(),
          jwt: jwt,
        })
      );
      dispatch(storiesAction.getAllStories({ queryParams: serializeQueryProps(), jwt: jwt }));
    } else {
      dispatch(changeFavoriteLocalStory(card.id));
    }
  };

  const turnEditStateOn = () => {
    if (localStories.length == 0 && stories.filter((story) => story.userId).length == 0) {
      makeToast("Nemate priča koje možete urediti.");
    } else {
      setEditState(true);
    }
  };

  return (
    <div className="flex flex-col justify-start h-screen">
      <PageCont>
        <SaveStoryModalInfo {...saveStoryModalInfo} />
        <Header />
        <div className="flex flex-start flex-row justify-start w-full h-full relative">
          <AppSidebar />
          <div className="flex flex-col w-full justify-start">
            <PageContHeader
              title="Priče"
              className="pr-8"
              titleClassName="ml-16"
              rightButtons={[
                editState ? (
                  <SaveButton key={1} onClick={() => setEditState(false)} />
                ) : (
                  <EditButton key={1} onClick={turnEditStateOn} />
                ),
                <TutorialButtonModal key={2} {...tutorialModal} />,
                <SettingsButtonModal key={3} {...settingsModal} />,
              ]}
            />
            {/* <div className="flex flex-row justify-end">
              <Select placeholder="Filteri" />
            </div>*/}
            <StoryCardContainer variant="favorite">
              {stories
                .filter((card: StoryCard) => card.favorite)
                .map((card: StoryCard) => (
                  <CardComponent
                    key={card.id}
                    title={card.title}
                    image={card.coverPage}
                    variant="image"
                    onClick={() => {
                      onClickRedirect(card.id);
                    }}
                    activeEdit={editState && !!card.userId}
                    favorite={card.favorite}
                    editFunction={() => editStoryFunction(card as LocalStory)}
                    deleteFunction={() => deleteStoryFunction(card)}
                    changeFavoriteFunction={() => changeFavoriteStoryFunction(card)}
                    className="ml-16 sm:ml-8 md:ml-12 lg:ml-16"
                  />
                ))}
              {localStories
                .filter((card: StoryCard) => card.favorite)
                .map((card: LocalStory) => (
                  <CardComponent
                    key={card.id}
                    title={card.title}
                    image={card.coverPage}
                    variant="image"
                    onClick={() => {
                      onClickRedirectLocalStory(card);
                    }}
                    activeEdit={editState}
                    favorite={card.favorite}
                    editFunction={() => editStoryFunction(card)}
                    deleteFunction={() => deleteStoryFunction(card)}
                    changeFavoriteFunction={() => changeFavoriteStoryFunction(card)}
                    className="ml-16 sm:ml-8 md:ml-12 lg:ml-16 "
                    favourable={true}
                  />
                ))}
            </StoryCardContainer>
            <StoryCardContainer className="mb-3" variant="rest">
              <CardComponent
                variant="add"
                className="ml-16 sm:ml-8 md:ml-12 lg:ml-16 bg-secondary"
                onClick={storyModal.onOpen}
              />
              {storyModal.isOpen && <NewStoryModal modal={storyModal} onSave={saveStoryModalInfo.onOpen} />}
              {updateStoryModal.isOpen && (
                <UpdateStoryModal modal={updateStoryModal} showSaveStoryModalInfo={saveStoryModalInfo.onOpen} />
              )}
              {stories
                .filter((card: StoryCard) => !card.favorite)
                .map((card: StoryCard) => (
                  <CardComponent
                    key={card.id}
                    title={card.title}
                    image={card.coverPage}
                    variant="image"
                    onClick={() => {
                      onClickRedirect(card.id);
                    }}
                    activeEdit={editState && !!card.userId}
                    editFunction={() => editStoryFunction(card as LocalStory)}
                    deleteFunction={() => deleteStoryFunction(card)}
                    changeFavoriteFunction={() => changeFavoriteStoryFunction(card)}
                    className="ml-16 sm:ml-8 md:ml-12 lg:ml-16 "
                  />
                ))}
              {localStories
                .filter((card: StoryCard) => !card.favorite)
                .map((card: LocalStory) => (
                  <CardComponent
                    key={card.id}
                    title={card.title}
                    image={card.coverPage}
                    variant="image"
                    onClick={() => {
                      onClickRedirectLocalStory(card);
                    }}
                    activeEdit={editState}
                    favorite={card.favorite}
                    editFunction={() => editStoryFunction(card)}
                    deleteFunction={() => deleteStoryFunction(card)}
                    changeFavoriteFunction={() => changeFavoriteStoryFunction(card)}
                    className="ml-16 sm:ml-8 md:ml-12 lg:ml-16 "
                    favourable={true}
                  />
                ))}
            </StoryCardContainer>
          </div>
        </div>
      </PageCont>
    </div>
  );
}
