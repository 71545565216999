import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";

import * as settingsApi from "../../../api/settings";
import { Settings } from "../../../api/types";
import { fetchWrapper } from "../../../store/storeUtils";

const getSettings = createAsyncThunk("settings/getSettings", async (jwt: string) => {
  return await fetchWrapper<Settings>(settingsApi.getSettings(jwt));
});

const updateSettings = createAsyncThunk(
  "settings/updateSettings",
  async ({ settingsDTO, jwt }: { settingsDTO: Settings; jwt?: string }) => {
    return await fetchWrapper<Settings>(settingsApi.updateSettings(settingsDTO, jwt));
  }
);

type SettingsState = {
  settings: Settings;
  mediaPermission: boolean;
  status: "idle" | "success" | "waiting" | "error";
  error?: SerializedError;
};

export const settingsInitialState: SettingsState = {
  settings: {
    numberOfPages: 6,
    uppercase: false,
    audioOn: true,
    bgColor: "background",
    frameColor: "black",
    textColor: "black",
    tutorial: true,
    framed: false,
  },
  mediaPermission: false,
  status: "idle",
  error: undefined,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: settingsInitialState,
  reducers: {
    updateSettingsAnonymousUser: (state, action) => {
      state.settings = action.payload;
    },
    setPermission: (state, action) => {
      state.mediaPermission = action.payload;
    },
    resetSettings: () => settingsInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSettings.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.status = "success";
      state.settings = action.payload;
    });

    builder.addCase(getSettings.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error;
    });

    builder.addCase(updateSettings.pending, (state) => {
      state.status = "waiting";
    });

    builder.addCase(updateSettings.fulfilled, (state, action) => {
      state.status = "success";
      state.settings = action.payload;
    });

    builder.addCase(updateSettings.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error;
    });
  },
});

export const settings = settingsSlice.reducer;
export const { updateSettingsAnonymousUser, setPermission, resetSettings } = settingsSlice.actions;
export const settingsAction = { getSettings, updateSettings, ...settingsSlice.actions };
