import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as symbolsApi from "../../../../api/symbols";
import { AudioResponse, Category, CategoryResponse, StorySymbol } from "../../../../api/types";
import { ErrorResponse } from "../../../../store/errorType";
import { fetchWrapper } from "../../../../store/storeUtils";

const getAllCategories = createAsyncThunk("symbols/getAllCategories", async (_, { rejectWithValue }) => {
  try {
    return await fetchWrapper<CategoryResponse[]>(symbolsApi.getAllCategories());
  } catch (err) {
    return rejectWithValue(err);
  }
});

const getSymbolsByCategory = createAsyncThunk(
  "symbols/getSymbolsByCategory",
  async (categoryId: number, { rejectWithValue }) => {
    try {
      return await fetchWrapper<StorySymbol[]>(symbolsApi.getSymbolsByCategory(categoryId.toString()));
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const getSymbolAudio = createAsyncThunk("symbols/getSymbolAudio", async (symbolId: number, { rejectWithValue }) => {
  try {
    return await fetchWrapper<AudioResponse>(symbolsApi.getSymbolAudio(symbolId.toString()));
  } catch (err) {
    return rejectWithValue(err);
  }
});

const filterSymbols = createAsyncThunk("symbols/filterSymbols", async (query: string, { rejectWithValue }) => {
  try {
    return await fetchWrapper<Category[]>(symbolsApi.filterSymbols(query));
  } catch (err) {
    return rejectWithValue(err);
  }
});

type SymbolsState = {
  categories: Category[];
  symbols: StorySymbol[];
  selectedSymbol: StorySymbol;
  audio: string;
  symbolsStatus: "idle" | "success" | "waiting" | "error";
  audioStatus: "idle" | "success" | "waiting" | "error";
  error?: ErrorResponse;
};

export const symbolsInitialState: SymbolsState = {
  categories: [],
  symbols: [],
  selectedSymbol: {} as StorySymbol,
  audio: "",
  symbolsStatus: "idle",
  audioStatus: "idle",
  error: undefined,
};

const symbolsSlice = createSlice({
  name: "symbols",
  initialState: symbolsInitialState,
  reducers: {
    resetAudioStatus: (state) => {
      state.audioStatus = "idle";
      state.audio = "";
    },

    resetSymbolCategories: (state) => {
      state.symbolsStatus = "idle";
      state.categories = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCategories.pending, (state) => {
      state.symbolsStatus = "waiting";
    });

    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.symbolsStatus = "success";
      state.categories = action.payload;
    });

    builder.addCase(getAllCategories.rejected, (state, action) => {
      state.symbolsStatus = "error";
      state.error = action.payload as ErrorResponse;
    });

    builder.addCase(getSymbolsByCategory.pending, (state) => {
      state.symbolsStatus = "waiting";
    });

    builder.addCase(getSymbolsByCategory.fulfilled, (state, action) => {
      const categoryId = action.meta.arg;
      const objIndex = state.categories.findIndex((category) => category.id == categoryId);
      state.symbolsStatus = "success";
      state.categories[objIndex].symbols = action.payload;
    });

    builder.addCase(getSymbolsByCategory.rejected, (state, action) => {
      state.symbolsStatus = "error";
      state.error = action.payload as ErrorResponse;
    });

    builder.addCase(getSymbolAudio.pending, (state) => {
      state.audioStatus = "waiting";
    });

    builder.addCase(getSymbolAudio.fulfilled, (state, action) => {
      state.audioStatus = "success";
      state.audio = action.payload.audio || "";
    });

    builder.addCase(getSymbolAudio.rejected, (state, action) => {
      state.audioStatus = "error";
      state.error = action.payload as ErrorResponse;
    });

    builder.addCase(filterSymbols.pending, (state) => {
      state.symbolsStatus = "waiting";
    });

    builder.addCase(filterSymbols.fulfilled, (state, action) => {
      state.symbolsStatus = "success";
      state.categories = [];
      action.payload.forEach((elem) => {
        state.categories.push(elem);
      });
    });
  },
});

export const symbols = symbolsSlice.reducer;
export const symbolsAction = {
  getAllCategories,
  getSymbolsByCategory,
  getSymbolAudio,
  filterSymbols,
  ...symbolsSlice.actions,
};
