import React, { SVGProps } from "react";
export default function DownloadIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="64" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M24 23L22.586 21.586L20 24.172V16H18V24.172L15.414 21.586L14 23L19 28L24 23Z" fill="currentColor" />
      <path
        d="M12 25.9999H2V1.99985H10V7.99985C10.0016 8.5298 10.2128 9.03759 10.5875 9.41232C10.9623 9.78705 11.4701 9.99827 12 9.99985H18V12.9999H20V7.99985C20.0035 7.86843 19.9785 7.7378 19.9268 7.61696C19.875 7.49612 19.7976 7.38793 19.7 7.29985L12.7 0.299852C12.612 0.202196 12.5038 0.124802 12.3829 0.0730088C12.2621 0.0212155 12.1314 -0.00374411 12 -0.000148241H2C1.47005 0.00143449 0.962265 0.212656 0.587535 0.587387C0.212805 0.962117 0.00158273 1.46991 0 1.99985V25.9999C0.00158273 26.5298 0.212805 27.0376 0.587535 27.4123C0.962265 27.787 1.47005 27.9983 2 27.9999H12V25.9999ZM12 2.39985L17.6 7.99985H12V2.39985Z"
        fill="currentColor"
      />
    </svg>
  );
}
