import cx from "classnames";
import React from "react";

import Button from "./Button";
import Modal, { ModalHeader, UseModal } from "./Modal";
import InfoIcon from "./icons/InfoIcon";

type YesNoModalProps = {
  message: string;
  onYes: () => void;
  modal: UseModal;
  className?: string;
};

export default function YesNoModal({ message, onYes, modal, className }: YesNoModalProps) {
  const yesNoModalClassName = cx(className, "font-semibold items-center");

  return (
    <Modal {...modal} className={yesNoModalClassName}>
      <ModalHeader
        onClose={modal.onClose}
        variant="primary"
        title={"Upozorenje"}
        titleIcon={<InfoIcon width={20} height={20} />}
      />
      <div className="px-3 text-center py-1">
        <p>{message}</p>
        <div className="flex flex-row justify-center px-2 mt-2 mb-2 items-center">
          <Button variant="primary" title="Da" onClick={onYes} className="mr-4">
            Da
          </Button>
          <Button variant="primary" title="Ne" onClick={() => modal.onClose()}>
            Ne
          </Button>
        </div>
      </div>
    </Modal>
  );
}
