import cx from "classnames";
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import Button from "../Button";

type IconButtonProps = {
  variant?: "primary" | "secondary";
  icon: React.ReactNode;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export default function IconButton({ icon, variant, className, ...props }: IconButtonProps) {
  const iconButtonClassName = cx(className, "h-10 pl-2 pr-2");
  return (
    <Button {...props} variant={variant} className={iconButtonClassName}>
      {icon}
    </Button>
  );
}
