import cx from "classnames";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";

import { useModal } from "./Modal";
import YesNoModal from "./YesNoModal";
import GroupArrowsIcon from "./icons/GroupArrowsIcon";
import HeartIcon from "./icons/HearIcon";
import PencilIcon from "./icons/PencilIcon";
import PlusIcon from "./icons/PlusIcon";
import TrashIcon from "./icons/TrashIcon";

type CardGameComponentProps = {
  variant: "image" | "add";
  title?: string;
  image?: string;
  rounded?: boolean;
  activeEdit?: boolean;
  deleteFunction?: () => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function CardGameComponent({
  title,
  image,
  className,
  variant,
  onClick,
  activeEdit = false,
  deleteFunction,
  ...props
}: CardGameComponentProps) {
  const yesNoModal = useModal();

  const cardClassName = cx(
    className,
    "relative w-40 h-48 inline-block  rounded cursor-pointer transform transition duration-500 ease-in-out hover:-translate-y-1 hover:shadow-primary"
  );

  const activeEditCardClassName = cx(className, "bg-darkGray w-40 h-48 inline-block rounded transform ");

  const titleClassName = cx(" absolute text-white font-semibold p-2", {
    "bottom-2.5": variant === "image",
    "top-24 left-6": variant === "add",
    " relative mt-5 text-center": activeEdit && variant === "image",
  });
  return (
    <div
      className={activeEdit && variant === "image" ? activeEditCardClassName : cardClassName}
      onClick={activeEdit && variant === "image" ? undefined : onClick}
      {...props}
    >
      {variant === "image" ? (
        activeEdit ? (
          <div className="flex flex-col justify-start items-center">
            <div className="flex flex-row justify-between m-3 gap-x-9">
              {yesNoModal.isOpen && (
                <YesNoModal
                  message="Jeste li sigurni da želite obrisati ovu priču? Jednom obrisanu priču nije moguće vratiti."
                  onYes={() => deleteFunction?.()}
                  modal={yesNoModal}
                />
              )}
              <TrashIcon
                width={20}
                height={22.5}
                className="text-white cursor-pointer"
                onClick={() => {
                  yesNoModal.onOpen();
                }}
              />
              <PencilIcon width={20} height={22.5} className="text-white cursor-pointer ml-0.5" />
              <HeartIcon width={20} height={22.5} className="text-white cursor-pointer" />
            </div>

            <GroupArrowsIcon className="mt-4 items-center cursor-pointer" width={30} height={30} />
            <div className="text-white text-center text-sm">
              Promijeni <br /> redoslijed
            </div>
          </div>
        ) : (
          <>
            <img className="absolute object-cover h-full w-full rounded" src={image} alt="Card image" />
            <div className="absolute gradientDiv rounded" />
          </>
        )
      ) : (
        <PlusIcon className="text-white absolute top-12 right-14" width={48} height={48} />
      )}

      <div className={titleClassName}>{title ? title : "Dodaj priču"}</div>
    </div>
  );
}
